import React, { useState } from 'react';
import './CustomToast.css';

const CustomToast = ({ message, onClose }) => {
  return (
    <div className="custom-toast">
      <div className="toast-message">{message}</div>
      <button className="toast-close-button" onClick={onClose}>
        &times;
      </button>
    </div>
  );
};

export default CustomToast;