
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/Button/button";
import attributes from "../../../utils/attributes";
import kundali from "src/assets/images/kundali.svg";
import { UPDATE_PROFILE } from "../../../axios/POST_API";
import leader from "src/assets/images/attributeIcons/leader.svg";
import BreadEarner from "src/assets/images/attributeIcons/BreadEarner.svg";
import caregiver from "src/assets/images/attributeIcons/caregiver.svg";
import funny from "src/assets/images/attributeIcons/funny.svg";
import GenderFluid from "src/assets/images/attributeIcons/GenderFluid.svg";
import GoodParent from "src/assets/images/attributeIcons/GoodParent.svg";
import HomeMaker from "src/assets/images/attributeIcons/HomeMaker.svg";
import Homosexual from "src/assets/images/attributeIcons/Homosexual.svg";
import nomad from "src/assets/images/attributeIcons/nomad.svg";
import passionatelover from "src/assets/images/attributeIcons/passionatelover.svg";
import Straight from "src/assets/images/attributeIcons/Straight.svg";
import Thinker from "src/assets/images/attributeIcons/Thinker.svg";
import leftarrow from "src/assets/images/leftarrow.svg"
import logo from "src/assets/images/dropdsmall.svg"
import deleteIcon from "src/assets/images/deleteattr.svg"
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import Userdetailsnav from "../../../components/UserDetailsNav/userdetailsnav";
import "./partnerattributes.css";
import { useNavigate } from "react-router-dom";
import Money from "src/assets/images/money.svg";
import notification from "src/assets/images/notification.svg";
import search from "src/assets/images/search.svg";
import TimelineNav from "../../../components/timelinenav/timelineNav"


const PartnerAttributes = {
  Leader: leader,
  "Bread Earner": BreadEarner,
  "Care Giver": caregiver,
  Funny: funny,
  "Gender Fluid": GenderFluid,
  "Good Parent": GoodParent,
  "Homemaker": HomeMaker,
  Homosexual: Homosexual,
  Nomad: nomad,
  "Passionate Lover": passionatelover,
  Thinker: Thinker,
  Straight,
};

const exclusiveAttributes = new Set(["Homosexual", "Gender Fluid", "Straight"]);

function Partnerattributes({ setSliderPage, profileData }) {
  const history = useNavigate()
  const spinner = useSpinner()
  const [partnerkundliattributes, setPartnerkundliattributes] = useState([]);
  const [attributePreview, setAttributePreview] = useState([]);
  const [attrError,setAttrError] = useState(false)
  const { t, i18n } = useTranslation();

  let items = [];

  useEffect(() => {
    if (profileData?.partner_kundli_attributes) {
      setPartnerkundliattributes(profileData.partner_kundli_attributes);
    }
  }, [profileData]);

  const handleChange = (e) => {
    items.push(e.target.value);
  };
  
  // let length = partnerkundliattributes.length

  const handlePartnerAttributes = async () => {
    try {
      spinner.setLoadingState(true)
      await UPDATE_PROFILE(
        "partner_kundli_attributes",
        partnerkundliattributes
      );
      spinner.setLoadingState(false)
      setSliderPage("index");
    } catch (error) {
      setAttrError(error.response.data.message)
      spinner.setLoadingState(false)
      console.log(
        error,
        "this is the error in partner attributes updation updation"
      );
    }
  };
  const updatePreview = () => {
    const _preview = partnerkundliattributes.map((item) => {
      return (
        <div className="attribute-icons">
          <img src={PartnerAttributes[item]} />
        </div>
      );
    });
    setAttributePreview(_preview);
  };
  // useEffect(() => {
  //   updatePreview();
  // }, [partnerkundliattributes]);

  const handleBack =() => {
    setSliderPage("attributes")
  }

  const handleNotifications = () => {
    history("/notifications")
  }

  const handleWallet = () => {
    history("/connectwallet")
  }
  const handleClick = () => {
    setSliderPage("index")
  }

  const toTitleCase = (str) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  };

  const scaleCoordinates = (coords, svgSize, imageSize) => {
    const scale = svgSize / imageSize;
    return coords
      .split(',')
      .map((value, index) => (index % 2 === 0 ? value * scale : value * scale))
      .join(',');
  };

  const handleAttributeClick = (item) => {
    const _attributesArray = [...partnerkundliattributes];
    
    // Check if the item is one of the exclusive attributes
    if (exclusiveAttributes.has(item.text)) {
      // Check if any exclusive attribute is already selected
      const hasExclusiveAttribute = _attributesArray.some(attr => exclusiveAttributes.has(attr));

      if (hasExclusiveAttribute) {
        alert("You can only select one attribute from Homosexual, Gender Fluid, and Straight.");
        return;
      }
    }

    if (_attributesArray.includes(item.text.toLowerCase())) return;

    if (_attributesArray.length < 5) {
      _attributesArray.push(item.text.toLowerCase());
      setPartnerkundliattributes(_attributesArray);
      updatePreview();
    }
  };

  return (
    <div className='page-page-wrapper edit-user-details-page'>
      {/* <TimelineNav /> */}
      <div
        style={{ width: "100vw", height: "65px", backgroundColor: "white" }}
        className="navbar-wrapper"
      >
        <div style={{ display: "flex", width: "60vw" }}>
          <div onClick={handleClick} style={{ position: "relative", top: "20px", left: "15px" }}>
            <img src={leftarrow} alt="back" />
          </div>
          <div style={{ width: "auto" }}>
            <img
              style={{ position: "relative", left: "30px", top: "12px" }}
              id="dropd-logo"
              src={logo}
              alt="dropd-logo"
            />
          </div>
        </div>
        <div style={{ width: "40vw" }}>
          <div
            style={{
              display: "flex",
              float: "right",
              width: "100px",
              justifyContent: "space-evenly",
              position: "relative",
              top: "20px",
            }}
          >
            <div>
              <img onClick={handleNotifications} src={notification} alt="money" />
            </div>
            <div>
              <img onClick={handleWallet} src={Money} alt="money" />
            </div>
            <div>
              <img src={search} alt="money" />
            </div>
          </div>
        </div>
      </div>
        
      <div className="attributes-page-container">
      <div className="inner-pages-container">
        <div className="inner-pages-container-wrap">
          {/*<div className="main-wrap">*/}
            <div className="entry-wrap">
              <h1 className="page-title">Edit Attributes Of Partner</h1>
              <div className="attribute-description">
                  Select 5 attributes you are looking in your partners kundali
              </div>
            </div>

            <div className="content-wrap">
              <div className="attribute-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340" style={{ width: '100%', height: 'auto' }}>
                  <image href={kundali} alt="kundali" width="340" height="340" />
                  {attributes.map((item, index) => (
                    <polygon
                      key={index}
                      points={scaleCoordinates(item.coordinates, 340, 306)}
                      fill="transparent"
                      stroke="transparent"
                      strokeWidth="1"
                      data-value={item.text}
                      onClick={() => handleAttributeClick(item)}
                    />
                  ))}
                </svg>

                <div style={{color:"red",fontSize:"10px",paddingTop:"10px"}}>{attrError}</div>
              </div>
              {/* todo map these according to the items clicked */}
              <div className="attributeicons-position">
                {partnerkundliattributes.map((item) => {
                  return (
                    <div className="attribute-icons"
                      onClick={() => {
                        if (partnerkundliattributes.includes(item)) {
                          let updatedAttributes = partnerkundliattributes.filter((element) => {
                            if (element != item) return element
                          })
                          setPartnerkundliattributes(updatedAttributes)
                        } 
                      }}
                    >
                      <img src={PartnerAttributes[toTitleCase(item)]} />
                      <div className="delete-attr-icon"><img src={deleteIcon} /></div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="action-button-wrap">
              <CustomButton onClick={()=>{setSliderPage("index")}}>Cancel</CustomButton>
              <CustomButton onClick={handlePartnerAttributes}>Update</CustomButton>
            </div>
          {/*</div>*/}

        </div>
      </div>
      </div>
    </div> 

  );
}

export default Partnerattributes;