import axios from "axios";
const {
  REACT_APP_MORALIS_API_KEY,
  REACT_APP_SignUpPoolAddress,
  REACT_APP_CHAIN_ID_HEX,
  REACT_APP_FlicsPoolAddress,
  REACT_APP_INTERACTIONSAddress,
} = process.env;

export const CLAIM_HISTORY = async (userAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
      accept: "application/json",
    };

    const body = {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          name: "user",
          type: "address",
          internal_type: "address",
        },
        {
          indexed: false,
          name: "amount",
          type: "uint256",
          internal_type: "uint256",
        },
        {
          indexed: false,
          name: "timestamp",
          type: "uint256",
          internal_type: "uint256",
        },
      ],
      name: "Claim",
      type: "event",
    };

    const params = {
      chain: REACT_APP_CHAIN_ID_HEX,
      topic:
        "0x34fcbac0073d7c3d388e51312faf357774904998eeb8fca628b9e6f65ee1cbf7",
      order: "DESC",
    };

    const response = await axios.post(
      `https://deep-index.moralis.io/api/v2.2/${REACT_APP_SignUpPoolAddress}/events`,
      body,
      { headers, params }
    );

    const userClaimHistory = response.data.result.filter(
      (event) => event.data.user === userAddress.toLowerCase()
    );
    console.log(userClaimHistory);
    return userClaimHistory;
  } catch (err) {
    console.error("Error fetching claim history:", err);
    throw err;
  }
};

export const FLICS_INITIATED_HISTORY = async (userAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
      accept: "application/json",
    };

    const body = {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_FlicsAddress",
          type: "address",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_initiator",
          type: "address",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_acceptor",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_initiatedAt",
          type: "uint256",
        },
      ],
      name: "initiated",
      type: "event",
    };

    const params = {
      chain: REACT_APP_CHAIN_ID_HEX,
      topic:
        "0x7ba637c341d391e59b09ca57650a560b969a157eab5d6326e7ce7e55a1190333",
      order: "DESC",
    };

    const response = await axios.post(
      `https://deep-index.moralis.io/api/v2.2/${REACT_APP_FlicsPoolAddress}/events`,
      body,
      { headers, params }
    );

    const userFlicsInitiatedHistory = response.data.result.filter(
      (event) => event.data._initiator === userAddress.toLowerCase()
    );

    return userFlicsInitiatedHistory;
  } catch (err) {
    console.error("Error fetching flics initiated history:", err);
    throw err;
  }
};

export const FLICS_ACTIVATED_HISTORY = async (userAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
      accept: "application/json",
    };

    const body = {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_initiator",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_amount",
          type: "uint256",
        },
      ],
      name: "FLICSActivated",
      type: "event",
    };

    const params = {
      chain: REACT_APP_CHAIN_ID_HEX,
      topic:
        "0x325a3d001f475b9f1c53bc7200d600e4305e783be10d55ebd2caddede2109efe",
      order: "DESC",
    };

    const response = await axios.post(
      `https://deep-index.moralis.io/api/v2.2/${REACT_APP_FlicsPoolAddress}/events`,
      body,
      { headers, params }
    );

    const userFlicsInitiatedHistory = response.data.result.filter(
      (event) => event.data._initiator === userAddress.toLowerCase()
    );

    return userFlicsInitiatedHistory;
  } catch (err) {
    console.error("Error fetching flics activated history:", err);
    throw err;
  }
};

export const ADD_FRIEND_HISTORY = async (userAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
      accept: "application/json",
    };

    const body = {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          indexed: false,
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "timestamp",
          type: "uint256",
        },
      ],
      name: "SentFriendRequest",
      type: "event",
    };

    const params = {
      chain: REACT_APP_CHAIN_ID_HEX,
      topic:
        "0x5625521fcdcebb05ed51ced71966b9583bb8d53e9639a3ca79d718c3bb458488",
      order: "DESC",
    };

    const response = await axios.post(
      `https://deep-index.moralis.io/api/v2.2/${REACT_APP_INTERACTIONSAddress}/events`,
      body,
      { headers, params }
    );

    const addFriendHistory = response.data.result.filter(
      (event) => event.data.from === userAddress.toLowerCase()
    );

    return addFriendHistory;
  } catch (err) {
    console.error("Error fetching add friend history:", err);
    throw err;
  }
};

export const ACCEPT_FRIEND_HISTORY = async (userAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
      accept: "application/json",
    };

    const body = {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          indexed: false,
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "timestamp",
          type: "uint256",
        },
      ],
      name: "AcceptFriendRequest",
      type: "event",
    };

    const params = {
      chain: REACT_APP_CHAIN_ID_HEX,
      topic:
        "0x297f7255a43f63414dacb7a0944400ad707841472ffc2701e2bc18d69064e2d0",
      order: "DESC",
    };

    const response = await axios.post(
      `https://deep-index.moralis.io/api/v2.2/${REACT_APP_INTERACTIONSAddress}/events`,
      body,
      { headers, params }
    );

    const acceptFriendHistory = response.data.result.filter(
      (event) => event.data.from === userAddress.toLowerCase()
    );

    return acceptFriendHistory;
  } catch (err) {
    console.error("Error fetching add friend history:", err);
    throw err;
  }
};

export const CANCEL_FRIEND_HISTORY = async (userAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
      accept: "application/json",
    };

    const body = {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          indexed: false,
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "timestamp",
          type: "uint256",
        },
      ],
      name: "CancelFriendRequest",
      type: "event",
    };

    const params = {
      chain: REACT_APP_CHAIN_ID_HEX,
      topic:
        "0x3eac349d1898c5bdd32256d9586d8734e6981a166439a414aae64c04c85520ed",
      order: "DESC",
    };

    const response = await axios.post(
      `https://deep-index.moralis.io/api/v2.2/${REACT_APP_INTERACTIONSAddress}/events`,
      body,
      { headers, params }
    );

    const cancelFriendHistory = response.data.result.filter(
      (event) => event.data.from === userAddress.toLowerCase()
    );

    return cancelFriendHistory;
  } catch (err) {
    console.error("Error fetching cancel friend history:", err);
    throw err;
  }
};

export const REJECT_FRIEND_HISTORY = async (userAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
      accept: "application/json",
    };

    const body = {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          indexed: false,
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "timestamp",
          type: "uint256",
        },
      ],
      name: "RejectFriendRequest",
      type: "event",
    };

    const params = {
      chain: REACT_APP_CHAIN_ID_HEX,
      topic:
        "0x0122c0254de41cda2a8e77dfd894719704c28c2f8ee8fbb9ded449e6e5f899ab",
      order: "DESC",
    };

    const response = await axios.post(
      `https://deep-index.moralis.io/api/v2.2/${REACT_APP_INTERACTIONSAddress}/events`,
      body,
      { headers, params }
    );

    const rejectFriendHistory = response.data.result.filter(
      (event) => event.data.from === userAddress.toLowerCase()
    );

    return rejectFriendHistory;
  } catch (err) {
    console.error("Error fetching reject friend history:", err);
    throw err;
  }
};

export const CREATE_CLUB_HISTORY = async (userAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
      accept: "application/json",
    };

    const body = {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_creator",
          type: "address",
        },
        {
          indexed: false,
          internalType: "string",
          name: "ClubID",
          type: "string",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_creationFee",
          type: "uint256",
        },
      ],
      name: "ClubCreated",
      type: "event",
    };

    const params = {
      chain: REACT_APP_CHAIN_ID_HEX,
      topic:
        "0x73b1c23c6b35372f82a2eadfa732d81dc9b9e378a3933101ee9d64070346c6b2",
      order: "DESC",
    };

    const response = await axios.post(
      `https://deep-index.moralis.io/api/v2.2/${REACT_APP_INTERACTIONSAddress}/events`,
      body,
      { headers, params }
    );

    const createClubHistory = response.data.result.filter(
      (event) => event.data._creator === userAddress.toLowerCase()
    );

    return createClubHistory;
  } catch (err) {
    console.error("Error fetching create club history:", err);
    throw err;
  }
};

export const JOIN_CLUB_HISTORY = async (userAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
      accept: "application/json",
    };

    const body = {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_joiner",
          type: "address",
        },
        {
          indexed: false,
          internalType: "address",
          name: "_creator",
          type: "address",
        },
        {
          indexed: false,
          internalType: "string",
          name: "ClubID",
          type: "string",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_joiningFee",
          type: "uint256",
        },
      ],
      name: "ClubJoined",
      type: "event",
    };

    const params = {
      chain: REACT_APP_CHAIN_ID_HEX,
      topic:
        "0x1dd705a740a42069f16ac741bbe15af1b9b4e4ad53d20e4922ce8cbf6a4e7304",
      order: "DESC",
    };

    const response = await axios.post(
      `https://deep-index.moralis.io/api/v2.2/${REACT_APP_INTERACTIONSAddress}/events`,
      body,
      { headers, params }
    );

    const joinClubHistory = response.data.result.filter(
      (event) => event.data._joiner === userAddress.toLowerCase()
    );

    return joinClubHistory;
  } catch (err) {
    console.error("Error fetching join club history:", err);
    throw err;
  }
};

export const CREATE_PAGE_HISTORY = async (userAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
      accept: "application/json",
    };

    const body = {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "_creator",
          type: "address",
        },
        {
          indexed: false,
          internalType: "string",
          name: "PageID",
          type: "string",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_creationFee",
          type: "uint256",
        },
      ],
      name: "PageCreated",
      type: "event",
    };

    const params = {
      chain: REACT_APP_CHAIN_ID_HEX,
      topic:
        "0x3eac349d1898c5bdd32256d9586d8734e6981a166439a414aae64c04c85520ed",
      order: "DESC",
    };

    const response = await axios.post(
      `https://deep-index.moralis.io/api/v2.2/${REACT_APP_INTERACTIONSAddress}/events`,
      body,
      { headers, params }
    );

    const createPageHistory = response.data.result.filter(
      (event) => event.data._creator === userAddress.toLowerCase()
    );

    return createPageHistory;
  } catch (err) {
    console.error("Error fetching create page history:", err);
    throw err;
  }
};

export const VIEWMATCH_FEES_HISTORY = async (userAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
      accept: "application/json",
    };

    const body = {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "noOfMatch",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "timestamp",
          type: "uint256",
        },
      ],
      name: "ViewMatchFees",
      type: "event",
    };

    const params = {
      chain: REACT_APP_CHAIN_ID_HEX,
      topic:
        "0x6924af37174ea8b3042981e062cf00630bc3191af863ec8d3ea59dea2cbfb591",
      order: "DESC",
    };

    const response = await axios.post(
      `https://deep-index.moralis.io/api/v2.2/${REACT_APP_INTERACTIONSAddress}/events`,
      body,
      { headers, params }
    );

    const viewmatchFeesHistory = response.data.result.filter(
      (event) => event.data.from === userAddress.toLowerCase()
    );

    return viewmatchFeesHistory;
  } catch (err) {
    console.error("Error fetching viewmatch fees history:", err);
    throw err;
  }
};

export const SUBSCRIPTION_HISTORY = async (userAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
      accept: "application/json",
    };

    const body = {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "account",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "noOfMonths",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "timestamp",
          type: "uint256",
        },
      ],
      name: "PaidSubscription",
      type: "event",
    };

    const params = {
      chain: REACT_APP_CHAIN_ID_HEX,
      topic:
        "0x7e84ad85c2561a71fddf43f75aa99d3392aa9513bb10d637565d70e446d04bdd",
      order: "DESC",
    };

    const response = await axios.post(
      `https://deep-index.moralis.io/api/v2.2/${REACT_APP_INTERACTIONSAddress}/events`,
      body,
      { headers, params }
    );

    const subscriptionHistory = response.data.result.filter(
      (event) => event.data.account === userAddress.toLowerCase()
    );

    return subscriptionHistory;
  } catch (err) {
    console.error("Error fetching subscription history:", err);
    throw err;
  }
};
