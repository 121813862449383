import React from "react";

const NotificationPermissionPopup = ({ onRequestPermission }) => {
  return (
    <div style={popupStyles.overlay}>
      <div style={popupStyles.popup}>
        <h3 style={popupStyles.title}>Stay Connected!</h3>
        <p style={popupStyles.message}>
          Don't miss out on new matches and messages. Enable notifications to stay in the loop!
        </p>
        <div style={popupStyles.buttonContainer}>
          <button
            style={{ ...popupStyles.button, ...popupStyles.allowButton }}
            onClick={() => onRequestPermission(true)}
          >
            Enable Notifications
          </button>
          <button
            style={{ ...popupStyles.button, ...popupStyles.denyButton }}
            onClick={() => onRequestPermission(false)}
          >
            No, Thanks
          </button>
        </div>
      </div>
    </div>
  );
};

// Styling for the popup
const popupStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  popup: {
    backgroundColor: "#fff",
    padding: "25px",
    borderRadius: "18px",
    width: "350px",
    textAlign: "center",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
  },
  title: {
    fontSize: "20px",
    marginBottom: "15px",
  },
  message: {
    fontSize: "16px",
    marginBottom: "20px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    flex: "1",
    padding: "10px 10px",
    borderRadius: "18px",
    border: "none",
    cursor: "pointer",
    fontSize: "12px",
  },
  allowButton: {
    backgroundColor: "#EC1C80",
    color: "#fff",
    marginRight: "10px", // Added spacing between buttons
  },
  denyButton: {
    backgroundColor: "#EC1C80",
    color: "#fff",
  },
};

export default NotificationPermissionPopup;