import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types';

import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg"
import moneyIcon from "src/assets/images/money-white.svg";

import { GET_FLICS_LIST } from "src/axios/GET_API";



const FlicsDisplayBoard = ({
    amount,
    flicsAmount,
    flicsRequestDeployed,
    flicList
}) => {

    // const [flicList, setFlicsList] = useState([]);

    // useEffect(() => {
    //   const fetchData = async () => {
    //     try {
    //       const flicsListAccepted = await GET_FLICS_LIST();
    //       setFlicsList(flicsListAccepted);
    //       console.log("flicsListAccepted:", flicsListAccepted);
    //     } catch (error) {
    //       console.error('Error fetching flics request list:', error);
    //     }
    //   };

    //   fetchData(); 
    // }, []);

    return (
      <div className="flics-popup-conainer">
        <div className="flics-tabs-container">
          <div className="flics-options">
            {flicList
                    .filter(flics => flics.status === "active") // Filter items with "active" status
                    .map((flics, index) => {
              // Calculate remaining days
                const activationDate = new Date(`${flics.activated_date}Z`);
                const currentDate = new Date();
                const differenceInTime = currentDate.getTime() - activationDate.getTime();
                const remainingDays = Math.floor((180 * 24 * 60 * 60 * 1000 - differenceInTime) / (24 * 60 * 60 * 1000));
              
              return (

                <button onClick={() => flicsRequestDeployed(flics)} style={{ backgroundColor: '#EC1C80', color: '#ffffff' }}>
                  <span>Deployed <span style={{ marginLeft: '10px', fontWeight: 'bold' }}><img src={moneyIcon} /> {flics.amount / 1e18}</span></span>
                  <span>{remainingDays}/{flics.duration}</span>
                </button>
              );
              })
            }
          </div>
        </div>

        <div className="balance mt-2">
          <span><img src={dropdTokanIcon} /></span>
          <span>{flicsAmount ?? amount ?? 0}</span>
        </div>
        <div className="lock-in">Lock in 00/90 days</div>
      </div>
    );
};

FlicsDisplayBoard.prototype = {
    amount: PropTypes.string,
}

export default FlicsDisplayBoard;