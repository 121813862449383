import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { getBalance } from "src/axios/POST_API";

import { GET_WALLET_BALANCE } from "src/axios/GET_API";

import { getWalletBalance } from "src/web3/getWalletBalance";

const Balance = () => {
  
  const [walletBalance, setWalletBalance] = useState('Loading...');

  useEffect(() => {
      async function fetchBalance() {
          try {
              // const balanceResult = await GET_WALLET_BALANCE(); 

              const balanceResult = await getWalletBalance();

              setWalletBalance(balanceResult);
          } catch (error) {
              console.error('Error fetching balance:', error);
          }
      }
      fetchBalance();
  }, []);

  return walletBalance;
};

export default Balance;
