import React, { useState, useEffect } from "react";
import Lists from "./components/lists";
import Received from "./components/received";
import Sent from "./components/sent";
import { GET_FRIEND_LIST, GET_FRIEND_REQUEST_LIST, VIEW_SENT_REQUEST } from "../../axios/GET_API";

function Friends() {
  const page = new URLSearchParams(window.location.search).get('page') || '';
  const [sliderPage, setSliderPage] = useState();
  const [friendListData, setFriendListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [friendRequestData, setFriendRequestData] = useState();
  const [sentList, setSentList] = useState();
  const [friendListError, setFriendListError] = useState();
  const [friendRequestError, setFriendRequestError] = useState();
  const [sentError, setSentError] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (page) {
      setSliderPage("requestreceived");
    } else {
      setSliderPage("friendlist");
    }
  }, []);

  const getAllFriends = async (pageNum = 1) => {
    if (!hasMore || loading) return;

    try {
      setLoading(true);
      let response = await GET_FRIEND_LIST(pageNum);
      
      if (!response?.data?.friend_list?.length) {
        if (pageNum === 1) {
          setFriendListError("No Friends to show, make some new friends!!");
        }
        setHasMore(false);
      } else {
        setFriendListData(prevData => [...(prevData || []), ...response.data.friend_list]);
        setCurrentPage(pageNum);
      }
    } catch (error) {
      console.error(error);
      setFriendListError(error.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const getFriendRequestList = async () => {
    try {
      let response = await GET_FRIEND_REQUEST_LIST();
      if (!response?.data?.friend_requests?.length) {
        setFriendRequestError("No friend requests to show!!");
      }
      setFriendRequestData(response.data);
    } catch (error) {
      console.error(error);
      setFriendRequestError(error.response?.data?.message);
    }
  };

  const sentRequestList = async () => {
    try {
      let response = await VIEW_SENT_REQUEST();
      if (!response?.data?.friend_requests_sent?.length) {
        setSentError("No friend requests sent!!");
      }
      setSentList(response.data);
    } catch (error) {
      console.error(error);
      setSentError(error.response?.data?.message);
    }
  };

  useEffect(() => {
    getAllFriends();
    getFriendRequestList();
    sentRequestList();
  }, []);

  const renderSlider = () => {
    switch (sliderPage) {
      case "friendlist":
        return (
          <Lists
            setSliderPage={setSliderPage}
            friendListData={friendListData}
            friendListError={friendListError}
            loading={loading}
            hasMore={hasMore}
            onLoadMore={() => getAllFriends(currentPage + 1)}
          />
        );
      case "requestreceived":
        return (
          <Received
            setSliderPage={setSliderPage}
            friendRequestData={friendRequestData}
            friendRequestError={friendRequestError}
          />
        );
      case "requestsent":
        return (
          <Sent
            setSliderPage={setSliderPage}
            sentList={sentList}
            sentError={sentError}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {renderSlider()}
    </div>
  );
}

export default Friends;