import {
  createSmartAccountClient,
  BiconomySmartAccountV2,
  PaymasterMode,
} from "@biconomy/account";
import { ethers } from "ethers";
import { contractABI } from "../contract/contractABI";
import { getWalletBalance } from "./getWalletBalance";
// 

import { chains } from "../chain";
import { interactionABI } from "../contract/interactionABI";

export const subscribe = async (authProvider, callback = () => { }) => {

  const walletBalance = await getWalletBalance();
    if (walletBalance < 50) {
      alert('Insufficient Wallet Balance');
      return;
    }

  const contractAddress = chains.dropdContractAdd;
  const interactionAddress = chains.interactionContractAdd;
  const provider = new ethers.providers.JsonRpcProvider(
    chains.providerUrl
  );
  const contractInstance = new ethers.Contract(
    contractAddress,
    contractABI,
    provider
  );
  const interactionInstance = new ethers.Contract(
    interactionAddress,
    interactionABI,
    provider
  )
  const walletAddress = localStorage.getItem("wallet_address");
  const approvedAmount = ethers.utils.parseEther("50");

  try {
    const minTx1 = await contractInstance.populateTransaction.approve(interactionAddress, approvedAmount);
    const tx1 = {
      to: contractAddress,
      data: minTx1.data
    };
    const minTx2 = await interactionInstance.populateTransaction.paySubscriptionFee(1, walletAddress);
    const tx2 = {
      to: interactionAddress,
      data: minTx2.data
    };

    //@ts-ignore
    let i = 0;
    const userOpResponse = await authProvider?.sendTransaction([tx1, tx2], {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
      nonceOptions: { nonceKey: i++ }
    });

    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();
    
    if (transactionHash) {
      return callback.call();
    }

  } catch (error) {
    console.log(error);
  }
};