import React, { useState, useEffect } from "react";
import Money from "src/assets/images/money.svg";
import notification from "src/assets/images/notification.svg";
import notificationActive from "src/assets/images/Notification-active.svg";
import search from "src/assets/images/search.svg";
import logo from "src/assets/images/dropdsmall.svg";
import leftarrow from "src/assets/images/leftarrow.svg";
import walletConnected from "src/assets/images/wallet-connected.svg";
import walletDisconnected from "src/assets/images/wallet-disconnected.svg";


import {useNavigate, useLocation} from "react-router-dom";
import { useAuth } from 'src/context/web3authProvider/AuthContext';
import useWeb3Auth from "src/web3/useWeb3Auth";
import { GET_NOTIFICATIONS } from "../../axios/GET_API";

function TimelineNav() {
  const { authProvider, setAuthProvider } = useAuth();
  const {initWallet } = useWeb3Auth();
  
  const history = useNavigate();
  const location = useLocation();
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  useEffect(() => {
    fetchNotificationData();
  }, []);

  const fetchNotificationData = async () => {
    if(!localStorage.getItem("unread_notifications_count")){
      try {
        const response = await GET_NOTIFICATIONS();
        
        if (response?.data?.unread_notifications_count > 0) {
          setUnreadNotificationsCount(response.data.unread_notifications_count);
          localStorage.setItem("unread_notifications_count", response.data.unread_notifications_count);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    }
  };

  const unread_notifications_count = localStorage.getItem("unread_notifications_count");

  const handleClick = () => {
    if(location.pathname === "/wallet"){
      history("/timeline");
      return
    }
    history(-1)
  }

  const handleNotifications = () => {
    history("/notifications")
  }

  const handleWallet = () => {
    history("/connectwallet")
  }

  const handleLogoClick = () => {
    history("/timeline");
    return
  }

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{ height: "65px", backgroundColor: "white" }}
        className="navbar-wrapper"
      >
        <div style={{ display: "flex", gap: "15px", paddingLeft: "15px", alignItems: "center" }}>
          <div onClick={handleClick} style={{ position: "relative" }}>
            <img src={leftarrow} alt="back" />
          </div>
          <div onClick={ handleLogoClick } style={{ width: "auto" }}>
            <img
              style={{ position: "relative"}}
              id="dropd-logo"
              src={logo}
              alt="dropd-logo"
            />
          </div>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <div
            style={{
              display: "flex",
              float: "right",
              justifyContent: "space-evenly",
              position: "relative",
              top: "20px",
            }}
          >
            <div className="notification-icon">
              <img 
                style={{
                  width: "20px",
                  height: "20px",
                }}
                onClick={handleNotifications}
                src={unread_notifications_count > 0 ? notificationActive : notification}
                alt="notification"
              />
              
              {unread_notifications_count > 0 && (
                <span className="notification-count">{unread_notifications_count}</span>
              )}
            </div>

            <div>
              <img onClick={handleWallet} src={Money} alt="money" />
            </div>
            <div>
              <img src={search} alt="money" />
            </div>
            <div>
              {authProvider ? (
                  <span className="wallet-connection-label wallet-connected"><img  src={walletConnected} alt="Wallet Connected" /></span>
                ) : (
                  <button className="wallet-connection-label" onClick={initWallet}><img  src={walletDisconnected} alt="Wallet Disconnected" /></button>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TimelineNav;
