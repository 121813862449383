import SocialLogin from "@biconomy/web3-auth";

export default class SocialLoginFactory {
    static socialLogin = null;
    static getInstance() {
        if (SocialLoginFactory.socialLogin == null) {
            SocialLoginFactory.socialLogin = new SocialLogin();
        }
        return this.socialLogin;
    }
}