import React, { useState, useEffect } from 'react';
import './LoaderViewmatch.css'; // Import the CSS for styling the loader

const LoaderViewmatch = () => {
  const [typedText, setTypedText] = useState('P'); // Start with "P"
  const fullText = 'reparing your Match Pool'; // The remaining text
  const [dotCount, setDotCount] = useState(0); // Track how many dots to show

  useEffect(() => {
    let i = 0;
    const speed = 100; // Typing speed in milliseconds

    const typeWriter = () => {
      if (i < fullText.length) {
        setTypedText((prev) => prev + fullText.charAt(i));
        i++;
        setTimeout(typeWriter, speed);
      } else {
        // Start the dot animation after the text is typed out
        const dotsTimer = setInterval(() => {
          setDotCount((prev) => (prev < 3 ? prev + 1 : 0)); // Cycle through 1 to 3 dots
        }, 500);
        
        // Clear dot animation when component is unmounted
        return () => clearInterval(dotsTimer);
      }
    };

    // Start the typing animation
    const typingTimeout = setTimeout(typeWriter, speed);

    // Clean up the interval on unmount
    return () => {
      clearTimeout(typingTimeout);
    };
  }, []); // Only run once when component mounts

  return (
    <div className="custom-dots-spinner-wrap">
      <div className="spinner">
        <div className="dot" style={{ backgroundColor: '#fcc2d3' }}></div>
        <div className="dot" style={{ backgroundColor: '#efd3e1' }}></div>
        <div className="dot" style={{ backgroundColor: '#dfb4e1' }}></div>
        <div className="dot" style={{ backgroundColor: '#cad5ef' }}></div>
        <div className="dot" style={{ backgroundColor: '#dc99df' }}></div>
        <div className="dot" style={{ backgroundColor: '#fcf2e6' }}></div>
        <div className="dot" style={{ backgroundColor: '#fcf1e7' }}></div>
        <div className="dot" style={{ backgroundColor: '#fed9d9' }}></div>
      </div>

      <p className="typed-text">
        {typedText}
        <span className="dots">
          {'.'.repeat(dotCount)} {/* Show dots based on dotCount */}
        </span>
      </p>
    </div>
  );
};

export default LoaderViewmatch;
