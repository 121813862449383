import {
  createSmartAccountClient,
  BiconomySmartAccountV2,
  PaymasterMode,
} from "@biconomy/account";
import { ethers } from "ethers";
import { interactionABI } from "../contract/interactionABI"; 
import { chains } from "../chain";
import { toast } from "react-toastify";

export const rejectFriend = async (authProvider, senderAddress) => {
    debugger
    toast.info("Sending Reject Friend Transaction. Please Wait.")
    const interactionAddress = chains.interactionContractAdd;
    const provider = new ethers.providers.JsonRpcProvider(
        chains.providerUrl
    );
    const interactionInstance = new ethers.Contract(
        interactionAddress,
        interactionABI,
        provider
    )

  try {
    const nonce = await authProvider.getNonce();
    const minTx = await interactionInstance.populateTransaction.rejectFriendRequest(senderAddress);
   
    const tx1 = {
      to: interactionAddress,
      data: minTx.data,
    };

    //@ts-ignore
    const userOpResponse = await authProvider?.sendTransaction(tx1, {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
    });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();

    if (transactionHash) {
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};