import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSpinner } from "../../context/loaderContext/globalSpinnerContext";
import TimelineNav from "../../components/timelinenav/timelineNav";
import ChatHeader from './ChatHeader/ChatHeader';
import ChatMessages from './ChatMessages/ChatMessages';
import ChatMessagesHistory from './ChatMessagesHistory/ChatMessagesHistory';
import ChatInput from './ChatInput/ChatInput';
import { v4 as uuidv4 } from 'uuid';

import { GET_JSON_WEB_TOKEN, GET_CHAT_ROOM_HISTORY } from "../../axios/GET_API";

import './chat.css';

const { REACT_APP_API_HOST_CHAT } = process.env;

function Chat() {
  const senderDid = new URLSearchParams(window.location.search).get('to_id') || '';
  
  const spinner = useSpinner();
  const location = useLocation();
  const { did } = location.state || {};


  const [nickName, setNickName] = useState();
  const [profileImage, setProfileImage] = useState();
  const [loading, setLoading] = useState(true);

  const [messages, setMessages] = useState([]);
  const [messageQueue, setMessageQueue] = useState([]);
  const [messagesHistory, setMessagesHistory] = useState([]);

  const [input, setInput] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState('Connecting...');

  const chatSocket = useRef(null);
  const reconnectTimeout = useRef(null);
  const code = new URLSearchParams(location.search).get('token') || '';
  const to_id = new URLSearchParams(location.search).get('to_id') || '';

  // Function to fetch chat history
  const fetchChatHistory = useCallback(async () => {
    if (!code) return;

    try {
      spinner.setLoadingState(true);      
      const historyResponse = await GET_CHAT_ROOM_HISTORY(code);
      
      if (historyResponse) {
        setProfileImage(historyResponse?.data?.room_member_profile_pics)
        setNickName(historyResponse?.data?.room_member_nick_name);
      }

      console.log("historyResponse", historyResponse);
      setLoading(false);
      spinner.setLoadingState(false);
      
      // Check if data and 'chat-room-history' key exist
      const chatHistory = historyResponse?.data?.["chat-room-history"] || [];
     
  
     
      // Assuming chatHistory is an array of message objects
      setMessagesHistory(chatHistory);

    } catch (error) {
      spinner.setLoadingState(false);
      console.error('Error fetching chat history:', error);
      setConnectionStatus('Error loading chat history');
    }
  }, [code]);

  const initializeSocket = useCallback(async () => {
    if (!code) return;

    try {
      // API generating token
      const tokenResponse = await GET_JSON_WEB_TOKEN();
      const token = tokenResponse.data.access_token;

      const url = `${REACT_APP_API_HOST_CHAT}/ws/chat/${code}/?token=${token}`;
     

      chatSocket.current = new WebSocket(url);

      chatSocket.current.onopen = () => {
        setConnectionStatus('Connected');
        clearTimeout(reconnectTimeout.current);
      };

      chatSocket.current.onmessage = (e) => {
        try {
          const data = JSON.parse(e.data);

          // Check if the message ID already exists to avoid duplication
          setMessages(prevMessages => {
            if (!prevMessages.some(msg => msg.id === data.message.id)) {
              return [...prevMessages, {
                id: data.message.id, // Use the received message ID
                content: data.message.content,
                time: data.message.time,
                sender: data.sender,
              }];
            }
            return prevMessages;
          });
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

      chatSocket.current.onerror = (e) => {
        console.error('WebSocket error:', e);
        setConnectionStatus('Connection error');
      };

      chatSocket.current.onclose = (e) => {
        console.error('WebSocket connection closed:', e.code, e.reason);
        setConnectionStatus('Connection lost. Retrying...');
        attemptReconnect();
      };
    } catch (error) {
      console.error('Error initializing WebSocket connection:', error);
      setConnectionStatus('Connection failed. Retrying...');
      attemptReconnect();
    }
  }, [code]);

  const attemptReconnect = useCallback(() => {
    if (reconnectTimeout.current) {
      clearTimeout(reconnectTimeout.current);
    }
    reconnectTimeout.current = setTimeout(() => {
      initializeSocket();
    }, 5000); // Retry after 5 seconds
  }, [initializeSocket]);

  useEffect(() => {
    // Fetch chat history on component mount
    fetchChatHistory();

    // Initialize WebSocket connection
    initializeSocket();

    return () => {
      if (chatSocket.current) {
        chatSocket.current.close();
      }
      if (reconnectTimeout.current) {
        clearTimeout(reconnectTimeout.current);
      }
    };
  }, [initializeSocket, fetchChatHistory]);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleEmojiClick = (emoji) => {
    setInput(prevInput => prevInput + emoji.emoji);
  };

  const handleSendMessage = () => {
    if (input.trim() === '') return;

    const messageId = uuidv4(); // Generate a unique ID for the message
    const message = {
      id: messageId, // Add the unique ID to the message
      content: input,
      time: new Date().toLocaleTimeString(),
      sender: 'me',
      status: 'sending', // Temporary status
    };

    // Optimistically update the UI
    setMessages(prevMessages => [...prevMessages, message]);
    setInput('');

    // Add message to the queue
    setMessageQueue(prevQueue => [...prevQueue, message]);

    // Attempt to send the message through WebSocket
    sendMessageToSocket(message);
  };

  // Function to send a message through WebSocket
  const sendMessageToSocket = async (message) => {
    if (!chatSocket.current || chatSocket.current.readyState !== WebSocket.OPEN) {
      console.error('WebSocket is not open');
      attemptReconnect();
      return;
    }

    try {
      chatSocket.current.send(JSON.stringify({
        'message': {
          id: message.id, // Send the unique ID along with the message
          content: message.content,
          time: message.time,
          sender: message.sender,
        },
        'room_code': code,
        'to_id': to_id,
      }));

      // On successful send, update the message status
      setMessages(prevMessages =>
        prevMessages.map(msg =>
          msg.id === message.id ? { ...msg, status: 'sent' } : msg
        )
      );
    } catch (error) {
      console.error('Error sending message:', error);

      // On failure, update the message status to 'failed'
      setMessages(prevMessages =>
        prevMessages.map(msg =>
          msg.id === message.id ? { ...msg, status: 'failed' } : msg
        )
      );

      // Optionally retry sending the message
      attemptReconnect();
    }
  };
  
  // Retry sending queued messages after reconnection
  useEffect(() => {
    if (connectionStatus === 'Connected' && messageQueue.length > 0) {
      const unsentMessage = messageQueue[0];
      sendMessageToSocket(unsentMessage);

      // Remove the message from the queue after sending
      setMessageQueue(prevQueue => prevQueue.slice(1));
    }
  }, [connectionStatus]);

  return (
    <div style={{ backgroundColor: "white", height: "100vh" }}>
      <TimelineNav />

      <div className="chat-page">
        <div className="connection-status">
          {connectionStatus}
        </div>
        
        {loading ? (
          <div></div> // You can replace this with a spinner or any loading component
        ) : (
          <ChatHeader profileImage={profileImage} userNickName={nickName} senderDid={senderDid} />
        )}

        <div className="chat-messages">
          <ChatMessagesHistory messages={messagesHistory} senderDid={senderDid} />

          <ChatMessages messages={messages} senderDid={senderDid} />
        </div>
        <ChatInput
          input={input}
          onInputChange={handleInputChange}
          onSendMessage={handleSendMessage}
          showEmojiPicker={showEmojiPicker}
          setShowEmojiPicker={setShowEmojiPicker}
          onEmojiClick={handleEmojiClick}
        />
      </div>
    </div>
  );
}

export default Chat;