import React, { useState, useEffect } from "react";
import { get } from 'lodash';
import TimelineNav from "../../components/timelinenav/timelineNav"
import { GET_FRIEND_LIST, USER_VIEW_PROFILE } from "src/axios/GET_API";
import { getFlics } from "src/axios/POST_API";

import { useTranslation } from "react-i18next";
import avatar from "src/assets/images/avatar.jpeg"
import addIconWhite from "src/assets/images/addiconwhite.svg"
import activateIcon from "src/assets/images/activateicon.svg"
import swapIcon from "src/assets/images/swapicon.svg"
import upIcon from "src/assets/images/upicon.svg"
import infoIcon from "src/assets/images/informationcircle.svg"
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg"
import leftarrow from "src/assets/images/leftarrow.svg"
import logo from "src/assets/images/dropdsmall.svg";

// import components
import { useSpinner } from "../../context/loaderContext/globalSpinnerContext";

import Userdetailsnav from "src/components/UserDetailsNav/userdetailsnav"
import FlicsDetails from "src/pages/flics/components/FlicsDetails";
import ActivateFlics from "./components/activateflics"
import BuyDrpd from "./components/buydrpd"
import ConnectWallet from "./components/connectwallet"
import DeleteFlics from "./components/deleteflics"
import DeployedFlics from "./components/deployedflics"
import FlicsMain from "./components/Flics"
import FlicsHistory from "./components/flicshistory"
import SharedFlics from "./components/sharedflics"
import ShareFlics from "./components/shareflics"

import FlicsRequestDetails from "src/pages/flics/components/FlicsRequestDetails";
import FlicsDeployedDetails from "src/pages/flics/components/FlicsDeployedDetails";


import { getFlicsAmount } from "src/web3/GetFlicsAmount";
import "./flics.css"

function Flics() {
  const spinner = useSpinner();
  const [sliderPage, setSliderPage] = useState("flicsmain")
  
  const [flicsAmount, setFlicsAmount] = useState("Loading...");
  const [flicsAmountHours, setFlicsAmountHours] = useState();

  const [amount, setAmount] = useState();
  const [flicsRecipient, setFlicsRecipient] = useState({});
  const [selfProfile, setSelfProfile] = useState({});
  const [friendsList, setFriendsList] = useState([]);

  const [flicsRequestSender, setFlicsRequestSender] = useState();

  const [flicsDeployed, setFlicsDeployed] = useState();

  useEffect(() => {
      const fetchData = async () => {
        spinner.setLoadingState(true);

        try {
          // Web3 call
          const _flicsAmount = await getFlicsAmount();
          setFlicsAmount(_flicsAmount.smallAmount);
          setFlicsAmountHours(_flicsAmount.timeDifferenceInHours);

          // Web2 calls
          const [flicsList, friendResponse, selfDetails] = await Promise.all([
            getFlics(),
            GET_FRIEND_LIST(),
            USER_VIEW_PROFILE()
          ]);

          setFriendsList(get(friendResponse, 'data.friend_list', []));
          setSelfProfile(selfDetails?.data);
        } catch (err) {
          console.error("Error fetching data:", err);
        } finally {
          spinner.setLoadingState(false);
        }
      };

      fetchData();
    }, []);



  const renderSlider = () => {
    switch (sliderPage) {

      case "activateflics":
        return (
          <ActivateFlics
            amount={amount}
            setAmount={setAmount}
            setSliderPage={setSliderPage}
          />
        );
      case "buydrpd":
        return (
          <BuyDrpd
            setSliderPage={setSliderPage}
          />
        );
      case "connectwallet":
        return (
          <ConnectWallet
            setSliderPage={setSliderPage}
          />
        );
      case "deleteflics":
        return (
          <DeleteFlics
            setSliderPage={setSliderPage}
          />
        );
      case "flicsmain":
        return (
          <FlicsMain
            setSliderPage={setSliderPage}
            amount={amount}
            flicsAmount={flicsAmount}
            flicsAmountHours={flicsAmountHours}
            setFlicsRequestSender={setFlicsRequestSender}
            setFlicsDeployed={setFlicsDeployed}
          />
        );
      case "flicshistory":
        return (
          <FlicsHistory
            setSliderPage={setSliderPage}
          />
        );
      case "sharedflics":
        return (
          <SharedFlics
            setSliderPage={setSliderPage}
            amount={amount}
            flicsRecipient={flicsRecipient}
          />
        );
      case "flicsDetails":
        return (
          <FlicsDetails
            setSliderPage={setSliderPage}
            amount={amount}
            flicsRecipient={flicsRecipient}
            selfProfile={selfProfile}
          />
        );
      case "shareflics":
        return (
          <ShareFlics
            setSliderPage={setSliderPage}
            amount={amount}
            flicsAmount={flicsAmount}
            setFlicsAmount={setFlicsAmount}
            friendsList={friendsList}
            setFlicsRecipient={setFlicsRecipient}
            flicsRecipient={flicsRecipient}
            // sendFlicsDetails={sendFlicsDetails}
          />
        );

      case "flicsRequestDetails":
        return (
          <FlicsRequestDetails
            setSliderPage={setSliderPage}
            amount={amount}
            flicsRecipient={flicsRequestSender}
            selfProfile={selfProfile}
          />
        );

      case "flicsDeployedDeployed":
        return (
          <FlicsDeployedDetails
            setSliderPage={setSliderPage}
            amount={amount}
            flicsRecipient={flicsDeployed}
            selfProfile={selfProfile}
          />
        );
      default:
        return "foo";
    }
  }

  return (
    <div
      key={sliderPage}
      className="ml-3"
      style={{ display: "flex", flexDirection: "column", border: "1px strong black" }}>

      <div className='page-page-wrapper flics-page-container'>
        <div className="pnkbg">
          <TimelineNav />
        </div>
        {renderSlider(sliderPage)}
      </div>
    </div>
  )
}

export default Flics;
