import axios from "axios";
import { flicsContractABI } from "../../contract/flicsContractABI";
import { flicsWhitelistedMethods } from "../../contract/flicsWhitelistedMethods";
const { REACT_APP_DAPP_API_KEY, REACT_APP_DAPP_AUTH_TOKEN } = process.env;

export const WHITELIST = async (contractName, contractAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "authToken": REACT_APP_DAPP_AUTH_TOKEN,
      "apiKey": REACT_APP_DAPP_API_KEY
    };

    const body = {
      name: contractName,
      address: contractAddress,
      abi: JSON.stringify(flicsContractABI),
      whitelistedMethods: flicsWhitelistedMethods
    };

    const response = await axios.post(
      "https://paymaster-dashboard-backend.prod.biconomy.io/api/v2/public/sdk/smart-contract",
      body,
      { headers }
    );
    return response;
  } catch (err) {
    console.error("Error while whitelisting flics contract:", err);
    alert("Flics contract not whitelisted");
    throw err;
  }
};
