import React from 'react';
import SendIcon from '@mui/icons-material/Send';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import './ChatInput.css';

const ChatInput = ({
  input,
  onInputChange,
  onSendMessage,
  showEmojiPicker,
  setShowEmojiPicker,
  onEmojiClick
}) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSendMessage();
    }
  };

  return (
    <div className="chat-input">
      <input
        type="text"
        placeholder="Type here..."
        className="input-field"
        value={input}
        onChange={onInputChange}
        onKeyDown={handleKeyDown}
      />
      
      <SendIcon className="icon" onClick={onSendMessage} />
    </div>
  );
};

export default ChatInput;