import React, { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import TimelineNav from "../../components/timelinenav/timelineNav"
import plusIcon from "src/assets/images/plus.svg"
import CardData from "../../utils/cardData"
import { useNavigate } from "react-router-dom"
import "./clubs.css"
import FloatingToolbar from "../../components/FloatingToolbar"
import { VIEW_ALL_CLUBS } from "../../axios/GET_API"
import { VIEW_MY_CLUBS } from "../../axios/GET_API"
import ClubLoader from "../../components/SkeletonLoader/Clubskeleton/Clubskeleton"

import { sendTokensJoinClub } from "src/web3/JoinClub";
import { useAuth } from 'src/context/web3authProvider/AuthContext';
import useWeb3Auth from "src/web3/useWeb3Auth";

import { useSpinner } from "../../context/loaderContext/globalSpinnerContext";

const { REACT_APP_CDN_HOST } = process.env;


function Clubs() {
  var { authProvider } = useAuth();
  const { initWallet } = useWeb3Auth();

  const history = useNavigate()
  const [allClubsData, setAllClubsData] = useState()
  const [myClub, setMyClub] = useState(false)
  const [myClubData, setMyClubData] = useState()
  const [allClubsError, setAllClubsError] = useState()
  const [myClubsError, setMyClubsError] = useState()
  const [clubNav, setClubNav] = useState()
  const [skeletonLoader, setSkeletonLoader] = useState(false)

  const spinner = useSpinner();

  const handleCreate = () => {
    history("/createclub")
  }

  const getAllClubs = async () => {
    try {
      setSkeletonLoader(true)
      let response = await VIEW_ALL_CLUBS()
      setSkeletonLoader(false)
      setAllClubsData(response)
      localStorage.setItem("clubs.response", JSON.stringify(response));
    } catch (error) {
      console.log(error)
      setSkeletonLoader(false)
      setAllClubsError(error.response.data.message)
    }
  }


  const getMyClubs = async () => {
    try {
      // setSkeletonLoader(true)
      let response = await VIEW_MY_CLUBS()
      setMyClubData(response)
      // setSkeletonLoader(false)
      if (response.data.club_list.length == "0") {
        setMyClubsError("Seems empty here! join some clubs")
      }
      localStorage.setItem("myClubs.response", JSON.stringify(response));
    } catch (error) {
      setSkeletonLoader(false)
      setMyClubsError(error.response.data.message)
      console.log(error)
    }
  }


  useEffect(() => {
    setClubNav(true)
    setSkeletonLoader(true)
    getAllClubs()
    let _response = localStorage.getItem("myClubs.response");
    if (_response) {
      setMyClubData(JSON.parse(_response));
    }
  }, [])


  useEffect(() => {
    setSkeletonLoader(true)
    let _response = localStorage.getItem("clubs.response");
    if (_response) {
      setAllClubsData(JSON.parse(_response));
      setSkeletonLoader(false)
    }
  }, [])

  const handleClubSwitch = () => {
    setMyClub(!myClub)
  }



  useEffect(() => {
    setClubNav(true)
    getMyClubs()
  }, [])

  // const handleClubPage = () => {
  //   history
  // }


  const handleJoinClubWeb3 = async (authProvider, joiningFee, clubId, clubAdminAddress) => {
    spinner.setLoadingState(true);
    try {

      if (!authProvider) {
        const smartWallet = await initWallet();
        authProvider = smartWallet;
      }

      const txRecipt = await sendTokensJoinClub(authProvider, joiningFee, clubId, clubAdminAddress);

      if(txRecipt){
        history(`/club/${clubId}`);
      }

    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      spinner.setLoadingState(false);
    }
  };

  return (
  

    <div className='page-page-wrapper'>
      {skeletonLoader ? <ClubLoader /> : <div style={{width: "100%"}}> <TimelineNav />
        <div className="inner-pages-container">

          <div className="inner-pages-container-wrap">

            <h1 className="page-title">Clubs</h1>

            <div style={{}} className="create-view-pages-tab-wrap">
              <div>
                <button onClick={handleCreate}> <span className="plus-icon"><img src={plusIcon} /></span>Create Club ..</button>
                {myClub ? <button onClick={handleClubSwitch}>All Clubs</button> : <button onClick={handleClubSwitch}>My Clubs</button>}
              </div>
            </div>
            <div className="pages-container">
              {myClub ?
                myClubData?.data?.club_list.map((item, index) => {
                  
                  let profileImage = "";
                  if (item?.profile_image != null) {
                    profileImage = item?.profile_image;
                    profileImage = `${REACT_APP_CDN_HOST}` + profileImage.replace("//", "/");
                  }

                  let coverImage = "";
                  if (item?.cover_image != null) {
                    coverImage = item?.profile_image;
                    coverImage = `${REACT_APP_CDN_HOST}` + coverImage
                  }
                  return (

                    <div onClick={() => { history(`/club/${item.club_id}`) }} className="page-col">
                      <div className="round-icon">
                        <img src={profileImage} />
                      </div>
                      <h3>{item.club_name}</h3>
                      <div className="page-likes">Likes <span className="likesCount">70K</span></div>
                      <div className="page-feature-img">
                        <img src={coverImage} />
                      </div>
                    </div>
                  )
                })
                :
                allClubsData?.data?.club_list.map((item, index) => {
                  let AllProfileImage = "";
                  if (item?.profile_image != null) {
                    AllProfileImage = item?.profile_image;
                    AllProfileImage = `${REACT_APP_CDN_HOST}` + AllProfileImage.replace("//", "/");
                  }

                  let allCoverImage = "";
                  if (item?.cover_image != null) {
                    allCoverImage = item?.profile_image;
                    allCoverImage = `${REACT_APP_CDN_HOST}` + allCoverImage
                  }
                  return (
                    <div className="page-col">
                      <div className="round-icon">
                        <img src={AllProfileImage} />
                      </div>
                      <h3>{item.club_name}</h3>
                      <div className="page-likes">Likes <span className="likesCount">70K</span></div>
                      <div className="page-feature-img">
                        <img src={allCoverImage} />

                        <p>{item?.owner.did}</p>

                       {item.is_member ?  <button className="like-page-button" onClick={() => { history(`/club/${item.club_id}`) }} >View Club</button> : <button className="like-page-button" onClick={() => handleJoinClubWeb3(authProvider, item.joining_fees, item.club_id, item.owner.wallet_address)} >Join Club</button>}
                      </div>
                    </div>
                  )
                })
              }
            </div>
            {myClub ? <div className='error'>{myClubsError}</div> : ""}
            {!myClub ? <div className='error'>{allClubsError}</div> : ""}


          </div>
        </div></div>}
      <FloatingToolbar clubNav={clubNav} />
    </div>
  );
}

export default Clubs;
