import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../header/header";
import CustomButton from "../../../components/Button/button";
import attributes from "../../../utils/attributes";
import kundali from "src/assets/images/kundali.svg";
import { UPDATE_PROFILE } from "../../../axios/POST_API";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import leader from "src/assets/images/attributeIcons/leader.svg";
import BreadEarner from "src/assets/images/attributeIcons/BreadEarner.svg";
import caregiver from "src/assets/images/attributeIcons/caregiver.svg";
import funny from "src/assets/images/attributeIcons/funny.svg";
import GenderFluid from "src/assets/images/attributeIcons/GenderFluid.svg";
import GoodParent from "src/assets/images/attributeIcons/GoodParent.svg";
import HomeMaker from "src/assets/images/attributeIcons/HomeMaker.svg";
import Homosexual from "src/assets/images/attributeIcons/Homosexual.svg";
import nomad from "src/assets/images/attributeIcons/nomad.svg";
import passionatelover from "src/assets/images/attributeIcons/passionatelover.svg";
import Straight from "src/assets/images/attributeIcons/Straight.svg";
import Thinker from "src/assets/images/attributeIcons/Thinker.svg";
import leftarrow from "src/assets/images/leftarrow.svg";
import logo from "src/assets/images/dropdsmall.svg";
import deleteIcon from "src/assets/images/deleteattr.svg";
import Userdetailsnav from "../../../components/UserDetailsNav/userdetailsnav";
import "./attributes.css";

const PersonAttributes = {
  Leader: leader,
  "Bread Earner": BreadEarner,
  "Care Giver": caregiver,
  Funny: funny,
  "Gender Fluid": GenderFluid,
  "Good Parent": GoodParent,
  "Homemaker": HomeMaker,
  Homosexual: Homosexual,
  Nomad: nomad,
  "Passionate Lover": passionatelover,
  Thinker: Thinker,
  Straight,
};

const exclusiveAttributes = new Set(["Homosexual", "Gender Fluid", "Straight"]);

function Attributes({ setSliderPage }) {
  const spinner = useSpinner();
  const { t, i18n } = useTranslation();
  const [personkundliattributes, setPersonkundliattributes] = useState([]);
  const [attributePreview, setAttributePreview] = useState([]);
  const [attributeError, setAttributeError] = useState();

  const handlePersonAttributes = async () => {
    try {
      spinner.setLoadingState(true);
      await UPDATE_PROFILE("person_kundli_attributes", personkundliattributes);
      spinner.setLoadingState(false);
      setSliderPage("partner_kundli_attributes");
    } catch (error) {
      spinner.setLoadingState(false);
      setAttributeError(error.response.data.message);
      console.log(error, "this is the error in person attributes updation");
    }
  };

  const updatePreview = () => {
    console.log("Starting update", personkundliattributes);
    const _preview = personkundliattributes.map((item) => {
      return (
        <div id="cursor" className="attribute-icons">
          <img src={PersonAttributes[item]} />
        </div>
      );
    });
    console.log("Apna preview", _preview);
    setAttributePreview(_preview);
  };

  const handleAttributeClick = (item) => {
    const _attributesArray = [...personkundliattributes];

    // Check if the item is one of the exclusive attributes
    if (exclusiveAttributes.has(item.text)) {
      // Check if any exclusive attribute is already selected
      const hasExclusiveAttribute = _attributesArray.some(attr => exclusiveAttributes.has(attr));

      if (hasExclusiveAttribute) {
        alert("You can only select one attribute from Homosexual, Gender Fluid, and Straight.");
        return;
      }
    }

    if (_attributesArray.includes(item.text)) return;

    if (_attributesArray.length < 5) {
      _attributesArray.push(item.text);
      setPersonkundliattributes(_attributesArray);
      updatePreview();
    }
  };

  const handleBack = () => {
    setSliderPage("gender_preference");
  };

  const scaleCoordinates = (coords, svgSize, imageSize) => {
    const scale = svgSize / imageSize;
    return coords
      .split(',')
      .map((value, index) => (index % 2 === 0 ? value * scale : value * scale))
      .join(',');
  };

  return (
    <div className="signup-screen-wrapper">
      <Header />

      <div className="entry-wrap">
        <div className="attribute-text-container">
          <div className="attribute-text">Attributes That Carve You</div>
        </div>
        <div className="attribute-description">
          Select 5 attributes you are looking in your partners kundali
        </div>
      </div>

      <div className="content-wrap">
        <div className="attributes-content-wrap">  
          <div className="attribute-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340" style={{ width: '100%', height: 'auto' }}>
              <image href={kundali} alt="kundali" width="340" height="340" />
              {attributes.map((item, index) => (
                <polygon
                  key={index}
                  points={scaleCoordinates(item.coordinates, 340, 306)}
                  fill="transparent"
                  stroke="transparent"
                  strokeWidth="1"
                  data-value={item.text}
                  onClick={() => handleAttributeClick(item)}
                />
              ))}
            </svg>

            <div style={{color:"red",fontSize:"10px",paddingLeft:"20px"}}>{attributeError}</div>
          </div>

          <div className="attributeicons-position">
            {personkundliattributes.map((item) => (
              <div
                key={item}
                className="attribute-icons"
                onClick={() => {
                  if (personkundliattributes.includes(item)) {
                    const updatedAttributes = personkundliattributes.filter((element) => element !== item);
                    setPersonkundliattributes(updatedAttributes);
                  }
                }}
              >
                <img id="cursor" src={PersonAttributes[item]} />
                <div className="delete-attr-icon"><img id="cursor" src={deleteIcon} /></div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="button-wrap">
        <CustomButton onClick={handlePersonAttributes}>{t("proceed.1")}</CustomButton>
      </div>
    </div>
  );
}

export default Attributes;