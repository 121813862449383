import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { messaging, getToken, onMessage } from "./firebase";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import theme from "./context/themecontext/Themecontext"
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Splash from "./pages/splash/splash";
import Login from "./pages/login/login";
import Home from "./pages/signup/signup";
import UserDetails from "./pages/userdetails/userDetails";
import EditUserDetails from "./pages/edituserdetails/edituserdetails.jsx";
import CreateProfile from "./pages/create/createProfile";
import Timeline from "./pages/timeline/timeline";
import Profilepage from "./pages/profilepage/profilepage";
import Socialpage from "./pages/socialpage/socialpage";
import Viewmatch from "./pages/viewmatch/viewmatch";
import Clubs from "./pages/clubs/clubs";
import CreateClub from "./pages/createclub/createclub";
import Notifications from "./pages/notifications/notifications"
import ConnectWallet from "./pages/connectwallet/connectwallet"
import CreateWallet from "./pages/connectwallet/Create"
import WalletApprovals from "./pages/connectwallet/Approvals"
import WalletTransactions from "./pages/connectwallet/WalletTransactions"
import Createpost from "./pages/createpost/createpost";
import CreatePage from "./pages/pages/parentPage/pagesParentPage";
import Pages from "./pages/pages/pages";
import Friends from "./pages/friends/friends";
import Menupage from "./pages/menupage/Menupage";
import Flics from "./pages/flics";
import MyWallet from "./pages/mywallet/mywallet";
import Transactions from "./pages/transactions/transactions";
import i18n from "./i18n/i18n";
import Clubpage from "./pages/clubpage/clubpage";
import CircularIndeterminate from "./components/Loader/loader";
import Comments from "./pages/comments/comments";
import SkeletonLoader from "./components/SkeletonLoader/skeletonLoader";
import { I18nextProvider } from "react-i18next";
import ConnectWalletPage from "./pages/flics/components/connectwallet";
import FloatingToolbar from "./components/FloatingToolbar";
import Chatlist from "./pages/chat/chatlist/chatlist";
import Chat from "./pages/chat/chat";
import NotificationList from "./components/NotificationList/NotificationList";
import "./App.css";

import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


function App({location}) {
  const [notifications, setNotifications] = useState([]);

  const handleNotificationClose = (id) => {
    setNotifications((prevNotifications) => prevNotifications.filter(notification => notification.id !== id));
  };

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {

      if (Notification.permission === "granted") {
        const newNotification = {
          id: Date.now(), // Unique ID for each notification
          title: payload.notification.title,
          body: payload.notification.body,
          route: payload.data.route,
          receiver_id: payload.data.receiver_id,
          sender_id: payload.data.sender_id,
          room_id: payload.data.misc,
          link: payload.data.link
        };

        setNotifications((prevNotifications) => {
          const updatedNotifications = [...prevNotifications, newNotification];
          return updatedNotifications;
        });

        // Optionally, display a system notification
        new Notification(payload.notification.title, {
          body: payload.notification.body,
          icon: payload.notification.icon
        });
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <ToastContainer/>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <Routes>
            {/* <Route exact pathpartner_kundli_attributes="/" component={Splash} /> */}
            <Route exact path="/" element={<Home />} />
            {/* <Route exact path="/login" component={Login} /> */}
            <Route exact path="/create" element={<CreateProfile />} />
            <Route exact path="/userdetails" element={<UserDetails />} />
            <Route exact path="/userdetails/:page" element={<UserDetails />} />
            <Route exact path="/edituserdetails" element={<EditUserDetails />} />
            <Route exact path="/timeline" element={<Timeline />} />
            <Route exact path="/comments/:pid" element={<Comments />} />
            <Route exact path="/createpost" element={<Createpost />} />
            <Route exact path="/profile/:did" element={<Profilepage />} />
            <Route exact path="/profile" element={<Profilepage />} />
            <Route exact path="/page/:pageId" element={<Socialpage />} />
            <Route exact path="/page/:pageId/createpost" element={<Createpost />} />
            <Route exact path="/viewmatch" element={<Viewmatch />} />
            <Route exact path="/skeleton" element={<SkeletonLoader />} />
            <Route exact path="/clublist" element={<Clubs />} />
            <Route exact path="/club/:cid" element={<Clubpage />} />
            <Route exact path="/club/:cid/createpost" element={<Createpost />} />
            <Route exact path="/createclub" element={<CreateClub />} />
            <Route exact path="/createpage" element={<CreatePage />} />
            <Route exact path="/pagelist" element={<Pages />} />
            <Route exact path="/notifications" element={<Notifications />} />
            <Route exact path="/subscription" element={<ConnectWallet />} />
            {/* <Route exact path="/connectwallet" component={ConnectWalletPage} /> */}
            <Route exact path="/connectwallet" element={<ConnectWallet />} />
            <Route exact path="/wallet" element={<MyWallet />} />
            <Route exact path="/transactions" element={<Transactions />} />

            // <Route exact path="/wallet/transactions" element={<WalletTransactions />} />
            <Route exact path="/wallet/create" element={<CreateWallet />} />
            <Route exact path="/wallet/approvals" element={<WalletApprovals />} />
            <Route exact path="/friends" element={<Friends />} />
            <Route exact path="/menu" element={<Menupage />} />
            <Route exact path="/flics" element={<Flics />} />
            // <Route exact path="/mywallet" element={<MyWallet />} />
            <Route exact path="/chatlist" element={<Chatlist />} />
            <Route exact path="/chat" element={<Chat />} />
          </Routes>

          
          {/* Render the NotificationList if there are notifications */}
          {notifications.length > 0 && (
            <NotificationList
              key={notifications.length}
              notifications={notifications}
              onClose={handleNotificationClose}
            />
          )}


          <div style={{ position: "fixed", transform: "translate(-50%,-50%)", left: "50%", top: "50%", zIndex: "9999" }}>
            <CircularIndeterminate />
          </div>
          {/* <FloatingToolbar/> */}
        </ThemeProvider>
      </I18nextProvider>
    </Router>
  );
}


export default App;