import SocialLoginFactory from "src/biconomyLogin/SocialLoginFactory";
import { verifyUserAfterBiconomyLogin } from "src/axios/POST_API";
import { ethers } from "ethers";
import { createSmartAccountClient } from "@biconomy/account";


import {
  getProvider,
  getSmartAccount,
} from "src/contract/utils";
const { getIdToken } = require("src/utils/HelperFunctions/RetrieveToken");

export const launchNextPage = async (history, page = "/timeline", saAddress = false) => {
  const email = localStorage.getItem("email");
  let wallet_address = saAddress;
  if(!wallet_address) {
     wallet_address = localStorage.getItem("wallet_address");
  }
  const { data } = await verifyUserAfterBiconomyLogin({email, wallet_address});

  if (data && data?.status === "success") {
    const { profile_completed, incomplete_profile_label, token, did } = data;

    localStorage.setItem("token", token);
    localStorage.setItem("wallet_address", wallet_address);
    localStorage.setItem("did", did);

    if (profile_completed !== true) {
      page = `/userdetails/${incomplete_profile_label}`;
      if (incomplete_profile_label?.toLowerCase() === "phone") {
        page = "/create";
      }else if (incomplete_profile_label?.toLowerCase() === "claim_amount"){
        page = `/userdetails/claim`;
      }
    } 
  }

  // Redirect user to subscription page if found subscription_status
  if (data && data?.status === "success") {
    const {incomplete_profile_label } = data;

    if (incomplete_profile_label?.toLowerCase() === "subscription_status") {
      page = `/userdetails/subscription`;
    }
  }

  history(page);
  return Promise.resolve();
};

