import React from 'react';
import { useNavigate } from 'react-router-dom';
import handleNotificationNavigate from './handleNotificationNavigate';

const NotificationCard = ({ title, body, route, sender_id, room_id, onClose }) => {
  const history = useNavigate();

  return (
    <div style={styles.card} onClick={() => handleNotificationNavigate(history, route, sender_id, room_id)}>
      <div style={styles.header}>
        <h4 style={styles.title}>{title}</h4>
        <button style={styles.closeButton} onClick={(e) => { e.stopPropagation(); onClose(); }}>×</button>
      </div>
      <p style={styles.body}>{body}</p>
    </div>
  );
};

// Styling for the notification card
const styles = {
  card: {
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '10px',
    position: 'relative',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '14px',
    color: '#6A7587',
    fontWeight: '700',
    margin: 0,
  },
  closeButton: {
    background: 'none',
    border: 'none',
    fontSize: '18px',
    cursor: 'pointer',
    color: '#f44336',
  },
  body: {
    fontSize: '12px',
    color: '#6A7587',
    fontWeight: '300',
    marginBottom: '0px'
  }
};

export default NotificationCard;