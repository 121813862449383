import React, { useEffect, useRef } from 'react';
import './ChatMessages.css';

const ChatMessages = ({ messages, senderDid }) => {
  const messagesEndRef = useRef(null);
  
   useEffect(() => {
    // Scroll to the bottom when messages change
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }

  }, [messages]);

  
  return (
    <div>
      {messages.map((msg, index) => (
        <div
          key={index}
          className={`message ${msg.sender != senderDid ? 'sent' : 'received'}`}
        >
          <div className="message-content">{msg.content}</div>
          <div className="message-time">{msg.time}</div>
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default ChatMessages;