import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/Button/button"
import TextField from "@mui/material/TextField";
import leftarrow from "src/assets/images/leftarrow.svg"
import logo from "src/assets/images/dropdsmall.svg"
import { UPDATE_PERSONAL_PROFILE } from "../../../axios/POST_API";
import { Button } from "@mui/material";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import Userdetailsnav from "../../../components/UserDetailsNav/userdetailsnav";
import TimelineNav from "../../../components/timelinenav/timelineNav"
import Money from "src/assets/images/money.svg";
import notification from "src/assets/images/notification.svg";
import search from "src/assets/images/search.svg";
import {languageListPrimary, languageListOther} from "../../../utils/Languages/Languages";
import { Country, State, City } from "country-state-city";
import { Select } from "@mui/material";
import "./knowbetter.css"
import { useNavigate } from "react-router-dom";

function Knowbetter({ setSliderPage, handleProfileUpdate, profileData }) {
  const history = useNavigate
  const spinner = useSpinner()
  const { t, i18n } = useTranslation();
  const [year, setYear] = useState();
  const [fullName, setFullName] = useState()
  const [city, setCity] = useState(null)
  const [state, setState] = useState(null)
  const [country, setCountry] = useState(null)
  const [maritalStatus, setMaritalStatus] = useState()
  const [statesList, setStatesList] = useState()
  const [cityList, setCityList] = useState()
  const [states, setStates] = useState()
  const [bio, setBio] = useState()

  const [languagePreference1, setLanguagePreference1] = useState("Hindi")
  const [languagePreference2, setLanguagePreference2] = useState()
  const [languagePreference3, setLanguagePreference3] = useState()

  // Filter options dynamically based on current selections
  const getFilteredLanguages = (excludeLanguages) => {
    return languageListOther.filter(lang => !excludeLanguages.includes(lang.name));
  };

  const handleLanguagePreference1Change = (e) => {
    const selectedLanguage = e.target.value;
    setLanguagePreference1(selectedLanguage);
    setLanguagePreference2(""); // Reset second preference
    setLanguagePreference3(""); // Reset third preference
  };

  const handleLanguagePreference2Change = (e) => {
    const selectedLanguage = e.target.value;
    setLanguagePreference2(selectedLanguage);
    setLanguagePreference3(""); // Reset third preference
  };

  const handleLanguagePreference3Change = (e) => {
    const selectedLanguage = e.target.value;
    setLanguagePreference3(selectedLanguage);
  };


  const allowedCountryCodes = [
    "BD",
    "KH",
    "IN",
    "ID",
    "IR",
    "LA",
    "MY",
    "MM",
    "PK",
    "PH",
    "RU",
    "SG",
    "TH",
    "AE",
    "VN"
  ];

  //country and city related code
  const countries = Country.getAllCountries()

  // Filter the list of countries to include only the allowed countries
  const filteredCountries = Country.getAllCountries().filter(country => allowedCountryCodes.includes(country.isoCode));

  // Sort the filtered countries alphabetically
  filteredCountries.sort((a, b) => a.name.localeCompare(b.name));
  

  useEffect(() => {
    setStatesList(State.getStatesOfCountry(country))
  }, [country])

  
  useEffect(() => {
    if (state) {
      const citiesOfState = City.getCitiesOfState(country, state);
      setCityList(citiesOfState);
    }
  }, [state, country]);


  const handleYOBChange = (event) => {
    setYear(event.target.value);
  };


  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  useEffect(() => {
    if (profileData) {
      setFullName(profileData.full_name)
      setYear(profileData.year_of_birth)
      setMaritalStatus(profileData.marital_status)
      setCountry(profileData.country.toUpperCase())
      setState(profileData.state.toUpperCase())
      setCity(toTitleCase(profileData.city))
      setLanguagePreference1(profileData.language_preference1)
      setLanguagePreference2(profileData.language_preference2)
      setLanguagePreference3(profileData.language_preference3)
      setBio(profileData.bio)
    }
  }, [])

  let age

  if (!Number.isNaN(parseInt(year)) && year > 1900) {
    age = new Date().getFullYear() - year
  } else {
    age = ""
  }


  const handleKnowBetter = async () => {
    try {
      spinner.setLoadingState(true)
      await UPDATE_PERSONAL_PROFILE({ "country": country, "state": state, "city": city, "marital_status": maritalStatus, "language_preference1": languagePreference1, "language_preference2": languagePreference2, "language_preference3": languagePreference3, "bio": bio })
      spinner.setLoadingState(false)
      setSliderPage("index")
    } catch (error) {
      console.log(error, "this is the error in zone updation")
    }
  }

  const handleNotifications = () => {
    history("/notifications")
  }

  const handleWallet = () => {
    history("/connectwallet")
  }
  const handleClick = () => {
    setSliderPage("index")
  }

  // const userDetails

  return (
    <div className='page-page-wrapper edit-user-details-page'>
      {/* <TimelineNav /> */}
      <div
        style={{ width: "100vw", height: "65px", backgroundColor: "white" }}
        className="navbar-wrapper"
      >
        <div style={{ display: "flex", width: "60vw" }}>
          <div onClick={handleClick} style={{ position: "relative", top: "20px", left: "15px" }}>
            <img src={leftarrow} alt="back" />
          </div>
          <div style={{ width: "auto" }}>
            <img
              style={{ position: "relative", left: "30px", top: "12px" }}
              id="dropd-logo"
              src={logo}
              alt="dropd-logo"
            />
          </div>
        </div>
        <div style={{ width: "40vw" }}>
          <div
            style={{
              display: "flex",
              float: "right",
              width: "100px",
              justifyContent: "space-evenly",
              position: "relative",
              top: "20px",
            }}
          >
            <div>
              <img onClick={handleNotifications} src={notification} alt="money" />
            </div>
            <div>
              <img onClick={handleWallet} src={Money} alt="money" />
            </div>
            <div>
              <img src={search} alt="money" />
            </div>
          </div>
        </div>
      </div>

      <div className="inner-pages-container">

        <div className="inner-pages-container-wrap">

          <div className="main-wrap">
            <div className="entry-wrap">
              <h1 className="page-title">Edit Profile</h1>
              <div className='knowbetter-description'>Personal Information</div>
            </div>

            <div className="content-wrap">
              <div className="edit-personal-info-wrap">
                <div className="field-wrap">
                  <div className='input-support-text'>Full Name</div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={fullName}
                    // onChange={(e) => { setFullName(e.target.value) }}
                    size="small"
                    disabled
                    sx={{
                      width: "340px", "& .MuiOutlinedInput-root": {
                        "& > fieldset": {
                          border: "none"
                        }
                      }
                    }}
                  />
                </div>
                <div className="col-2-row">
                  <div className="field-wrap">
                    <div className='input-support-text'>Year of Birth</div>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size='small'
                      // onChange={handleYOBChange}
                      value={year}
                      disabled
                      sx={{
                        width: "140px", "& .MuiOutlinedInput-root": {
                          "& > fieldset": {
                            border: "none"
                          }
                        }
                      }}
                    />
                  </div>
                  <div className="field-wrap">
                    <div className='input-support-text'>Age</div>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size='small'
                      value={!Number.isNaN(age) && age}
                      disabled
                      sx={{
                        width: "140px", "& .MuiOutlinedInput-root": {
                          "& > fieldset": {
                            border: "none"
                          }
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="field-wrap">
                  <div className='input-support-text'>Marital status</div>

                  <select value={maritalStatus} onChange={(e) => { setMaritalStatus(e.target.value) }} className='marital-status' name="cars" id="cars">
                    <option value=""></option>
                    <option value="Married">Married</option>
                    <option value="Single">Single</option>
                    <option value="Open Marriage">Open Marriage</option>
                  </select>
                </div>

                <div className='input-support-text'>
                                Country
                </div>

                <div>
                  <select value={country} onChange={(e) => { setCountry(e.target.value) }} className='marital-status' name="cars" id="cars">
                    <option value=""></option>
                    {filteredCountries.map((item, index) => {
                      return (<option value={item.isoCode}>{item.name}</option>)
                    })}
                  </select>
                </div>
                <div className='input-support-text'>
                                State/Province
                </div>
                <div>
                  <select value={state} onChange={(e) => { setState(e.target.value) }} className='marital-status' name="cars" id="cars">
                    <option value=""></option>
                    {statesList?.map((item, index) => {
                      return (<option value={item.isoCode}>{item.name}</option>)
                    })}
                  </select>
                </div>


                <div className='input-support-text'>
                  City
                </div>
                <div>
                  <select value={city} onChange={(e) => { setCity(e.target.value) }} className='marital-status' name="city" id="city">
                    <option value=""></option>
                    {cityList?.map((item, index) => (
                      <option key={index} value={item.name}>{item.name}</option>
                    ))}
                  </select>
                </div>

               {/* <div className="field-wrap">
                  <div className='input-support-text'>Country</div>
                  <select value={country} onChange={(e) => { setCountry(e.target.value) }} className='marital-status' name="cars" id="cars">
                    {countries.map((item, index) => {
                      return (<option value={item.isoCode}>{item.name.toLowerCase()}</option>)
                    })}
                                    countries</select>
                </div>

                <div className="field-wrap">
                  <div className='input-support-text'>Place of residence</div>
                  <select value={city} onChange={(e) => { setCity(e.target.value) }} className='marital-status' name="cars" id="cars">
                    {states?.map((item, index) => {
                      return (<option value={item.name}>{item.name}</option>)
                    })}
                  </select>
                </div>*/}

                <div className='input-support-text'>Language Preferences</div>
                <div>
                  <div>
                    <select value={languagePreference1} onChange={handleLanguagePreference1Change} className='marital-status' value={languagePreference1}>
                      <option value="">Select Language 1</option>
                      {languageListPrimary.map((item, index) => (
                        <option key={index} value={item.name}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <div>
                    <select value={languagePreference2} onChange={handleLanguagePreference2Change} className='marital-status' value={languagePreference2}>
                      <option value="">Select Language 2</option>
                      {getFilteredLanguages([languagePreference1]).map((item, index) => (
                        <option key={index} value={item.name}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <div>
                    <select value={languagePreference3} onChange={handleLanguagePreference3Change} className='marital-status' value={languagePreference3}>
                      <option value="">Select Language 3</option>
                      {getFilteredLanguages([languagePreference1, languagePreference2]).map((item, index) => (
                        <option key={index} value={item.name}>{item.name}</option>
                      ))}
                    </select>
                  </div>
                </div>


                <div className="field-wrap">
                  <div className='input-support-text'>Your bio</div>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size='small'
                    value={bio}
                    onChange={(e) => { setBio(e.target.value) }}
                    sx={{
                      width: "340", "& .MuiOutlinedInput-root": {
                        "& > fieldset": {
                          border: "none"
                        }
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="action-button-wrap">
              <CustomButton onClick={() => { setSliderPage("index") }}>Cancel</CustomButton>
              <CustomButton onClick={handleKnowBetter}>Update</CustomButton>
            </div>
          </div>
        </div>

      </div>
    </div>

  )
}

export default Knowbetter