import React, { useState } from 'react';
import NotificationCard from '../NotificationCard/NotificationCard';

const NotificationList = ({ notifications, onClose }) => {
  const [showAll, setShowAll] = useState(false);
  const notificationsToShow = showAll ? notifications : notifications.slice(0, 3); // Adjust the number as needed

  const handleShowMore = () => {
    setShowAll(true);
  };

  const handleShowLess = () => {
    setShowAll(false);
  };

  return (
    <div style={styles.container}>
      {notificationsToShow.map((notification) => (
        <NotificationCard
          key={notification.id} 
          title={notification.title}
          body={notification.body}
          route={notification.route}
          sender_id={notification.sender_id}
          room_id={notification.room_id}
          onClose={() => onClose(notification.id)}
        />
      ))}
      {notifications.length > 3 && (
        <button style={styles.toggleButton} onClick={showAll ? handleShowLess : handleShowMore}>
          {showAll ? 'Show Less' : 'Show More'}
        </button>
      )}
    </div>
    );
  };

// Styling for the notification list
const styles = {
  container: {
    position: 'fixed',
    bottom: '70px',
    right: '20px',
    maxHeight: '400px',
    overflowY: 'scroll',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '10px',
    padding: '15px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
  },
  toggleButton: {
    fontSize: '12px',
    lientHeight: '16px',
    marginTop: '10px',
    backgroundColor: '#EC1C80',
    color: '#ffffff',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
    cursor: 'pointer',
    borderRadius: '12px'
  }
};

export default NotificationList;