import React, { useState, useEffect } from 'react';
import iconSkip from "src/assets/images/skip.svg";
import iconUp from "src/assets/images/swipe-up.svg";
import iconDown from "src/assets/images/swipe-down.svg";
import iconLeft from "src/assets/images/swipe-left.svg";
import iconRight from "src/assets/images/swipe-right.svg";

import './GuideViewmatch.css'; 

const GuideViewmatch = () => {
  const [isGuideVisible, setIsGuideVisible] = useState(true);

  useEffect(() => {
    const isSkipped = localStorage.getItem('viewMatchGuideSkipped');
    if (isSkipped) {
      setIsGuideVisible(false);
    }
  }, []);

 
  const handleSkip = () => {
    localStorage.setItem('viewMatchGuideSkipped', 'true');
    setIsGuideVisible(false);
  };

  if (!isGuideVisible) {
    return null; 
  }

  return (
    <div className="view-match-guide-wrap">
      <img
        className="guide-skip"
        src={iconSkip}
        alt="skip"
        onClick={handleSkip} // Add onClick event for skip
      />
      <img className="guide-icon-up" src={iconUp} alt="up" />
      <img className="guide-icon-down" src={iconDown} alt="down" />
      <img className="guide-icon-left" src={iconLeft} alt="left" />
      <img className="guide-icon-right" src={iconRight} alt="right" />
    </div>
  );
};

export default GuideViewmatch;
