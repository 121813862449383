import axios from "axios";
const {
  REACT_APP_MORALIS_API_KEY,
  REACT_APP_MORALIS_ACCEPT_STREAM,
  REACT_APP_MORALIS_REJECT_STREAM,
  REACT_APP_MORALIS_TERMINATE_STREAM,
} = process.env;

export const FLICS_ACCEPT_STREAM = async (contractAddress) => {

  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
    };

    const body = {
      address: contractAddress,
    };

    const response = await axios.post(
      REACT_APP_MORALIS_ACCEPT_STREAM,
      body,
      { headers }
    );
    return response;
  } catch (err) {
    console.error("Error adding flics contract to stream:", err);
    alert("Flics contract not added to stream");
    throw err;
  }
};

export const FLICS_TERMINATE_STREAM = async (contractAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
    };

    const body = {
      address: contractAddress,
    };

    const response = await axios.post(
      REACT_APP_MORALIS_TERMINATE_STREAM,
      body,
      { headers }
    );
    return response;
  } catch (err) {
    console.error("Error adding flics contract to stream:", err);
    alert("Flics contract not added to stream");
    throw err;
  }
};

export const FLICS_REJECT_STREAM = async (contractAddress) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      "X-API-Key": REACT_APP_MORALIS_API_KEY,
    };
    const body = {
      address: contractAddress,
    };
    const response = await axios.post(
      REACT_APP_MORALIS_REJECT_STREAM,
      body,
      { headers }
    );
    return response;
  } catch (err) {
    console.error("Error adding flics contract to stream:", err);
    alert("Flics contract not added to stream");
    throw err;
  }
};
