import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TimelineNav from "../../components/timelinenav/timelineNav";
import avatar from "src/assets/images/avatar.jpeg";
import { useSpinner } from "../../context/loaderContext/globalSpinnerContext";
import { GET_NOTIFICATIONS } from "../../axios/GET_API";
import { NOTIFICATION_READ } from "../../axios/POST_API";
import handleNotificationNavigate from '../../components/NotificationCard/handleNotificationNavigate';
import "./notifications.css";

function Notifications() {
  const history = useNavigate();
  const spinner = useSpinner();
  const [notificationData, setNotificationData] = useState([]);
  const [notificationError, setNotificationError] = useState("");

  useEffect(() => {
    fetchNotificationData();
  }, []);

  const fetchNotificationData = async () => {
    try {
      spinner.setLoadingState(true);
      const response = await GET_NOTIFICATIONS();

      if (response?.data?.notifications?.length > 0) {
        setNotificationData(response.data.notifications);
      } else {
        setNotificationError("No notifications to show");
      }
      
      localStorage.setItem("unread_notifications_count", response.data.unread_notifications_count);

      spinner.setLoadingState(false);
    } catch (error) {
      spinner.setLoadingState(false);
      setNotificationError("Failed to fetch notifications");
    }
  };

  const handleNotificationClick = async (notification) => {
    try {
      // Only mark the notification as read if it's unread
      if (!notification.is_read) {
        const status = await NOTIFICATION_READ(notification.data.notification_id);

        if (status === 200) {
          // Update the notification's `is_read` status in the state
          setNotificationData((prevNotifications) => 
            prevNotifications.map((item) => 
              item.data.notification_id === notification.data.notification_id
                ? { ...item, is_read: true }
                : item
            )
          );

          // Decrease the unread notifications count in localStorage
          let unreadCount = localStorage.getItem("unread_notifications_count");
          unreadCount = unreadCount ? parseInt(unreadCount) : 0;

          if (unreadCount > 0) {
            localStorage.setItem("unread_notifications_count", unreadCount - 1);
          }
        }
      }

      // Navigate to the required route after marking the notification as read
      handleNotificationNavigate(
        history,
        notification.data.route,
        notification.data.sender_id,
        notification.data.misc
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  return (
    <div className="notification-page-wrapper">
      <TimelineNav />
      <div className="inner-pages-container">
        <div className="inner-pages-container-wrap">
          <h1 className="page-title">Notifications</h1>

          <div className="notifications-container">
            {notificationError && (
              <div className="notification-error">{notificationError}</div>
            )}

            {!notificationError && notificationData.length === 0 && (
              <div className="no-notifications">{notificationError}</div>
            )}

            {/* Notification List */}
            {notificationData.length > 0 &&
              notificationData.map((notification) => (
                <div
                  className={`notification-row ${
                    notification.is_read ? "read" : "unread"
                  }`}
                  key={notification.data.notification_id}
                  onClick={() => handleNotificationClick(notification)}
                >
                  {/*<div className="noti-thumb-wrap">
                    <img src={avatar} alt="Avatar" />
                  </div>*/}
                  <div className="noti-details-wrap">
                    <div className="noti-title">
                      <span>{notification.notification.title}</span>{" "}
                      {notification.notification.body}
                    </div>
                    <div className="noti-date">
                      {new Date(notification.created_at).toLocaleDateString()} at{" "}
                      {new Date(notification.created_at).toLocaleTimeString()}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notifications;