import React from 'react';
import ReactDOM from 'react-dom';
import { Col, Row } from "react-bootstrap";
import search from "src/assets/images/search.svg";

import TimelineNav from "src/components/timelinenav/timelineNav";
import avatar from "src/assets/images/avatar.jpeg";
import './FriendsList.css';
// import "src/pages/flics/flics.css"
//    
const data = [
    { name: 'Queen', image: 'https://via.placeholder.com/100x100.png?text=Queen' },
    { name: 'John', image: 'https://via.placeholder.com/100x100.png?text=John' },
    { name: 'Jane', image: 'https://via.placeholder.com/100x100.png?text=Jane' },
];

function Card({ name, image }) {
    return (
        <div className="card">
            <img src={image} alt={name} />
            <i className="fas fa-heart" />
        </div>
    );
}

const FriendsList = () => {
    return (
        <div style={{ 'backgroundColor': '#f2f2f2' }}>
            <Row className="mx-3 px-2 py-2">
                {/* <Col md={4}> */}
                <span style={{ 'width': '120px' }}>
                    <img
                        // style={{ width: '100%', height: '100%' }}
                        src={avatar}
                        className="profile-circle"
                    />
                </span>
                <span style={{ 'width': '300px', 'paddingTop': '20px' }}>
                    <span>Humming bird</span>
                </span>
            </Row>
            <Row className="mx-3 px-2 py-2">
                {/* <Col md={4}> */}
                <span style={{ 'width': '120px' }}>
                    <img
                        // style={{ width: '100%', height: '100%' }}
                        src={avatar}
                        className="profile-circle"
                    />
                </span>
                <span style={{ 'width': '300px', 'paddingTop': '20px' }}>
                    <span>TheQueen</span>
                </span>
            </Row>
        </div>

    );
}
export default FriendsList;
