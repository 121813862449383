import * as React from "react";
import { useCallback, useContext, useMemo, useState } from "react";

// Create the Skeleton Context
const SkeletonContext = React.createContext(null);

function useSkeleton() {
  const context = useContext(SkeletonContext);

  // Throw an error if useSkeleton is used outside of the provider
  if (context === null) {
    throw new Error(
      "useSkeleton() must be used inside of <UseSkeletonProvider />. Please declare it at a higher level."
    );
  }

  // Destructure skeleton object from the context
  const { skeleton } = context;

  // Use useMemo for optimization to avoid unnecessary recalculations
  return useMemo(() => ({ ...skeleton }), [skeleton]);
}

function UseSkeletonProvider({ children }) {
  // Check if context is already used, avoid redeclaring
  const context = useContext(SkeletonContext);

  if (context !== null) {
    throw new Error("<UseSkeletonProvider /> has already been declared.");
  }

  // useState to manage the loading state
  const [isLoading, setIsLoading] = useState(false);

  // Function to update the loading state
  const setLoadingState = useCallback((loading) => {
    setIsLoading(loading);
  }, []);

  // useMemo for optimization, avoid re-creating the object unless dependencies change
  const skeleton = useMemo(
    () => ({
      isLoading,
      setLoadingState,
    }),
    [isLoading, setLoadingState]
  );

  return (
    <SkeletonContext.Provider value={{ skeleton }}>
      {children}
    </SkeletonContext.Provider>
  );
}

// A wrapper to use in a more convenient way
function UseSkeletonProviderWrapper(props) {
  return <UseSkeletonProvider {...props} />;
}

// Higher-Order Component (HOC) to pass the skeleton context as props
export const withSkeleton = (Component) => {
  return function WrappedComponent(props) {
    const skeleton = useSkeleton();
    return <Component skeleton={skeleton} {...props} />;
  };
};

export { UseSkeletonProviderWrapper as UseSkeletonProvider, useSkeleton };