const PostData = [
  {
    id:1,
    name:"AsianBeauties",
    name2:"Crypto",
    total:"300k",
    total2:"120k",
    type:"Club",
    type2:"Pages",
    imgsource:require("../assets/images/avatar.jpeg")
  },
  {
    id:2,
    name:"Crypto",
    name2:"AsianBeauties",
    total:"258k",
    total2:"170k",
    type:"Club",
    type2:"Pages",
    imgsource:require("../assets/images/avatar2.jpeg")
  },
  {
    id:3,
    name:"Nightlife",
    name2:"Crypto India",
    total:"180k",
    total2:"550k",
    type:"Club",
    type2:"Pages",
    imgsource:require("../assets/images/avatar3.jpg")
  },
  {
    id:4,
    name:"Crypto India",
    total:"100k",
    total2:"172k",
    type:"Club",
    type2:"Pages",
    imgsource:require("../assets/images/avatar4.jpg")
  },
]

export default PostData