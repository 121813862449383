const {
  REACT_APP_PROVIDER_URL,
  REACT_APP_DROPDAddress,
  REACT_APP_DAPP_API_KEY,
  REACT_APP_FlicsPoolAddress,
  REACT_APP_INTERACTIONSAddress,
  REACT_APP_SignUpPoolAddress,
  REACT_APP_RevenuePoolAddress,
  REACT_APP_UserRewardPoolAddress,
  REACT_APP_CHAIN_ID,
  REACT_APP_CHAIN_ID_HEX,
  REACT_APP_CHAIN_NAME,
  REACT_APP_EXPLORER_URL,
  REACT_APP_WEB3AUTH_CLIENT_ID,
  REACT_APP_BICONOMY_BUNDLER_URL,
  REACT_APP_SUBSCRIPTION_ADDRESS
} = process.env;

export const chains = {
  chainId: REACT_APP_CHAIN_ID,
  chainIdHex: REACT_APP_CHAIN_ID_HEX,
  name: REACT_APP_CHAIN_NAME,
  providerUrl: REACT_APP_PROVIDER_URL,
  dropdContractAdd: REACT_APP_DROPDAddress,
  flicsPoolContractAdd: REACT_APP_FlicsPoolAddress,
  interactionContractAdd: REACT_APP_INTERACTIONSAddress,
  signUpPoolContractAdd: REACT_APP_SignUpPoolAddress,
  revenuePoolContractAdd: REACT_APP_RevenuePoolAddress,
  userRewardPoolAdd: REACT_APP_UserRewardPoolAddress,
  biconomyPaymasterApiKey: REACT_APP_DAPP_API_KEY,
  explorerUrl: REACT_APP_EXPLORER_URL,
  web3authClientId: REACT_APP_WEB3AUTH_CLIENT_ID,
  biconomyBundlerUrl: REACT_APP_BICONOMY_BUNDLER_URL,
  subscriptionAdd: REACT_APP_SUBSCRIPTION_ADDRESS
};
