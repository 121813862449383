import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../header/header";
import CustomButton from "../../../components/Button/button";
import { useWallet } from "../../../context/wallet/WalletContext";
import { USER_VIEW_PROFILE, SIGNED_KEY } from "../../../axios/GET_API";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { claimDRPD } from "src/web3/claim";
import { subscribe } from "src/web3/subscribe";
import { ethers, Wallet } from "ethers";


// import "./claim.css";

// Free tokens for males is: 250 DRPD
// Free tokens for females is: 250 DRPD
import { useAuth } from 'src/context/web3authProvider/AuthContext';
import useWeb3Auth from "src/web3/useWeb3Auth";


function Subscription({ setSliderPage }) {
  var { authProvider } = useAuth();
  const { initWallet } = useWeb3Auth();

  const { t, i18n } = useTranslation();
  const [popupVisiblity, setPopupVisiblity] = useState(true);
  const history = useNavigate();
  const spinner = useSpinner();
  const { active, account, activate, deactivate, connector, library } = useWeb3React();
  const wallet = useWallet();


  const handleBack = () => {
    setSliderPage("success");
  };

  const handleSubscribeDRPD = async () => {
    spinner.setLoadingState(true);
    try {
      if (!authProvider) {
        const smartWallet = await initWallet();
        authProvider = smartWallet;
      }

      // check 1 st time subscription
      const txReciept = await subscribe(authProvider, () => { history("/timeline") });
      console.log("txReciept ==>", txReciept);
    } catch (error) {
      console.log("error", error);
      alert(1);
    }
    finally {
    }
    spinner.setLoadingState(false);
  };

  return (
    <div className="signup-screen-wrapper">
      <Header />

      <div className="entry-wrap" style={{ marginTop: "0px" }}>
        <h1 className="page-title-big">Subscription</h1>
        <div className="page-desc">
          <p>
            50 DRPD coins per month subscription fee will be deducted from your
            wallet to access selected features
          </p>
          <p>5 DRPD coins per swipe will be deducted</p>
        </div>
      </div>

      <div className="content-wrap">
        <div className="text-info-popup">
        	<span
        	  className="lbl"
        	  style={{
        	    fontSize: "18px",
        	    lineHeight: "20px",
        	    fontWeight: 600,
        	    color: "#4E3292",
        	  }}
        	>Pay Monthly Subscription</span>
          <span>
            50 DRPD coins for 1 month subscription.
          </span>

          <span
            style={{
              fontSize: "12px",
              lineHight: "16px",
              fontWeight: "400",
              color: "#C4C4C4",
            }}
          >
            Unlock multiple features
          </span>
        </div>
      </div>

      <div className="button-wrap">
        <CustomButton onClick={handleSubscribeDRPD}>Proceed</CustomButton>
      </div>
    </div>
  );
}

export default Subscription;
