import React,{useState} from "react"
import Header from "../header/header";
import CustomButton from "../../../components/Button/button"
import { useTranslation } from "react-i18next";
import Genders from "../../../utils/genderpreferences";
import { UPDATE_PROFILE } from "../../../axios/POST_API";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import leftarrow from "src/assets/images/leftarrow.svg"
import logo from "src/assets/images/dropdsmall.svg"
import Userdetailsnav from "../../../components/UserDetailsNav/userdetailsnav";
import tickcircle from "src/assets/images/tickcircle.svg"
import "./genderpreferences.css"

function Genderpreferences({ selectedGender, setSliderPage,setGenderPreference}) {
  const spinner = useSpinner()
  const [selected,setSelected] = useState()
  const [partnerGenderError,setPartnerGenderError] = useState()
  const { t, i18n } = useTranslation();

  const filteredPreferences = Genders.filter((preference) => {
      if (selectedGender === "Male") {
        return preference.gendertext !== "Female with Female" && preference.gendertext !== "Genderqueer with Genderqueer";
      } else if (selectedGender === "Female") {
        return preference.gendertext !== "Male with Male" && preference.gendertext !== "Genderqueer with Genderqueer";
      }
      return true; 
  });

  const handlePreferences = async() => {
    if(!selected){
      setPartnerGenderError("Please select atleast one option")
    }
    try {
      spinner.setLoadingState(true)
      await UPDATE_PROFILE("gender_preference", selected )
      spinner.setLoadingState(false)
      setSliderPage("person_kundli_attributes")
    } catch (error) {
      setPartnerGenderError(error.response.data.message)
      console.log(error, "this is the error in interests updation")
      spinner.setLoadingState(false)
    }
  }
  const handleBack=()=>{
    setSliderPage("gender")
  }

  return (
    <div className="signup-screen-wrapper">
      <Header />

      <div className="entry-wrap">
        <div className='genderpreferences-text-container'>
          <div className='genderpreferences-text'>
            {t("your-gender-preferences.1")}
          </div>
        </div>
        <div className='genderpreferences-description'>{t("we-welcome-everyone.1")}</div>
      </div>

      <div className="content-wrap">
        <div className='gender-container'>
          {
            filteredPreferences.map((item, index) => {
              return (
                <div onClick={() => {setSelected(item.gendertext)}} className={selected===item.gendertext ? "selectedgender" : "gender"}>
                  <span id="cursor" className='gender-text-style'>{item.gendertext}</span>
                  <div id="cursor" style={{float:"right",position:"relative",top:"10px",right:"10px"}}>{selected === item.gendertext?<img src={tickcircle}/>:""}</div></div>
              )
            })
          }
        </div>
        <div style={{color:"red",fontSize:"10px",paddingTop:"10px",paddingLeft:"20px"}}>{partnerGenderError}</div>
      </div>
      
      <div className="button-wrap">
        <CustomButton onClick={handlePreferences}>{t("proceed.1")}</CustomButton>
      </div>
    </div>
  )
}

export default Genderpreferences