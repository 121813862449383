import React, { useState } from 'react';
import { toLower } from 'lodash';
import { Col, Row, Form, InputGroup, Modal, Card } from "react-bootstrap";
import search from "src/assets/images/search.svg";
import TimelineNav from "src/components/timelinenav/timelineNav";
import avatar from "src/assets/images/avatar.jpeg";
import FriendsList from './FriendsList';

const { REACT_APP_CDN_HOST } = process.env;

const data = [
    { name: 'Queen', image: 'https://via.placeholder.com/100x100.png?text=Queen' },
    { name: 'John', image: 'https://via.placeholder.com/100x100.png?text=John' },
    { name: 'Jane', image: 'https://via.placeholder.com/100x100.png?text=Jane' },
];


const FriendsListModal = ({
    showFriendsListModal,
    setShowFriendsListModal,
    selectedCard,
    setSelectedCard,
    friendsList,
}) => {
    const [fl, setFl] = useState(friendsList);

    return (
        <Modal
            show={showFriendsListModal}
            onHide={() => {
                setShowFriendsListModal(false)
            }}
            // style={{ 'marginTop': '150px' }}
            className="my-5 py-5"
        >
            <div className="container py-5">
                <InputGroup className="mb-3">
                    <Form.Control
                        placeholder="Search Friend"
                        style={{ 'borderRight': 'none' }}
                        onChange={(event) => {
                            if (event?.target?.value !== "") {
                                const _fl = friendsList.filter(
                                    ({ nick_name: name }) => toLower(name).includes(toLower(event.target.value))
                                );
                                setFl(_fl);
                            } else {
                                setFl(friendsList);
                            }
                        }}
                    />
                    <InputGroup.Text
                        style={{ 'backgroundColor': 'white' }}
                    >
                        <img src={search} alt="money" />
                    </InputGroup.Text>
                </InputGroup>
                {
                    fl.map(({ nick_name, profile_pics, did, wallet_address }, i) => {
                        
                        const friendPic = `${REACT_APP_CDN_HOST}` + profile_pics[0].replace("//", "/");
                        return (
                            <Card style={{
                                height: '90%',
                                alignItems: 'normal',
                                padding: '10px',
                            }}
                                className='mb-1'
                                key={i}
                                onClick={() => {
                                    setSelectedCard({ nick_name, profile_pics, did, wallet_address});
                                    setShowFriendsListModal(false);
                                }}
                            >
                                <Row>
                                    <Col lg={3} md={3} sm={3} >
                                        <img
                                            src={friendPic}
                                            className="profile-circle"
                                        />
                                    </Col>
                                    <Col lg={9} md={9} sm={9} className='my-auto'>
                                        <div>{nick_name}</div>
                                    </Col>
                                </Row>
                            </Card>
                        );
                    })
                }
            </div>
        </Modal>
    );
};

FriendsListModal.defaultProps = {
    showFriendsListModal: false,
    setShowFriendsListModal: () => { },
    selectedCard: {},
    setSelectedCard: () => { },
    friendsList: [],
}
export default FriendsListModal;


