import React, { useState, useEffect } from "react"
import logo from "src/assets/images/dropdsmall.svg"
import IndexPage from "./index/index"
import { useSpinner } from "../../context/loaderContext/globalSpinnerContext";
import SkeletonLoader from "../../components/SkeletonLoader/skeletonLoader";
import { useSkeleton } from "../../context/skeletoncontext/skeletoncontext";
import Selectinterest from "./interestSelect/selectinterest"
import EditDatingProfile from "./editdatingprofile/editdatingprofile"
import Gender from "./gender/gender"
import Genderpreferences from "./genderpreferences/genderpreferences"
import leftarrow from "src/assets/images/leftarrow.svg"
import Attributes from "./attributes/attributes"
import Partnerattributes from "./partnerattributes/partnerattributes"
import Zone from "./zone/zone"
import Knowbetter from "./knowbetter/knowbetter"
import CreateProfile from "../create/createProfile"
import { UPDATE_PROFILE } from "../../axios/POST_API"
import { useNavigate } from "react-router-dom"
import { GET_INTERESTS } from "../../axios/GET_API"
import { USER_VIEW_PROFILE } from "../../axios/GET_API"
import Userdetailsnav from "../../components/UserDetailsNav/userdetailsnav"
import { wait } from "@testing-library/user-event/dist/utils"
import "./edituserdetails.css";

function EditUserDetails() {
  const history = useNavigate()
  const spinner = useSpinner();
  const skeleton = useSkeleton();
  const [interests, setInterests] = useState([])
  const [imageFiles, setImagefiles] = useState()
  const [age, setAge] = useState()
  const [gender, setGender] = useState()
  const [profileImages,setProfileImages] = useState()
  const [profileData,setProfileData] = useState()
  const [genderpreference, setGenderPreference] = useState()
  const [personkundliattributes, setPersonkundliAttributes] = useState([])
  const [partnerkundliattributes, setPartnerkundliattributes] = useState([])
  const [zone, setZone] = useState()
  const [sliderPage, setSliderPage] = useState("index")

  const getInterests = async () => {
    const response = await GET_INTERESTS()
    setInterests(response)

  }

  // If there is not token navigate user to login
  useEffect(() => {
    let item = localStorage.getItem("token")
    if (!item) {
      history("/")
    }
  }, [])

  

  const viewUserProfile = async() => {
    try{
      if(sliderPage == "index"){
        skeleton.setLoadingState(true); 
      }
      const response = await USER_VIEW_PROFILE()
      setProfileData(response?.data)
      skeleton.setLoadingState(false);
    }catch(error){
      skeleton.setLoadingState(false);
      console.log(error,"error in profile  data in edit user details")
    }
  }


  useEffect(() => {
    viewUserProfile()
  }, [sliderPage])

  const handleProfileUpdate = () => {
    UPDATE_PROFILE({ age: "25", genderpreference: genderpreference, gender: gender, personkundliattributes: personkundliattributes, partnerkundliattributes: partnerkundliattributes, zone: zone, interests: interests })
  }
  useEffect(() => {
    getInterests()
  }, [])


  useEffect(() => {
    setSliderPage("index");
  }, []);

  const renderSlider = () => {
    switch (sliderPage) {
    case "index":
      return (
        <IndexPage
          profileData={profileData}
          setSliderPage={setSliderPage}
        />
      );
    case "selectinterest":
      return (
        <Selectinterest
          profileData={profileData}
          setSliderPage={setSliderPage}
          setInterests={setInterests}
          interests={interests}
        />
      );
    case "editdatingprofile":
      return (
        <EditDatingProfile
          profileData={profileData}
          setSliderPage={setSliderPage}
          setInterests={setInterests}
          interests={interests}
        />
      );
    case "gender":
      return (
        <Gender
          profileData={profileData}
          setSliderPage={setSliderPage}
          setGender={setGender}
        />
      )
    case "genderpreferences":
      return (
        <Genderpreferences
          profileData={profileData}
          setSliderPage={setSliderPage}
          setGenderPreference={setGenderPreference}
        />
      );
    case "attributes":
      return (
        <Attributes
          profileData={profileData}
          setSliderPage={setSliderPage}
          setPersonkundliAttributes={setPersonkundliAttributes}
          personkundliattributes={personkundliattributes}
        />
      )
    case "partnerattributes":
      return (
        <Partnerattributes
          profileData={profileData}
          setSliderPage={setSliderPage}
          setPartnerkundliattributes={setPartnerkundliattributes}
          partnerkundliattributes={partnerkundliattributes}
        />
      );
    case "zone":
      return (
        <Zone
          profileData={profileData}
          setSliderPage={setSliderPage}
          setZone={setZone}
        />
      );
    case "knowbetter":
      return (
        <Knowbetter
          profileData={profileData}
          setSliderPage={setSliderPage}
          handleProfileUpdate={handleProfileUpdate}
        />
      );
    default:
      return "foo";
    }
  }
  return (
    <div style={{display: "flex", flexDirection: "column",}}>
      {skeleton.isLoading ? (
        <SkeletonLoader />
      ) : (
        renderSlider()
       )}

      {/*{renderSlider()}*/}
    </div>
  )
}

export default EditUserDetails