import React, { useState, useEffect } from 'react';
import './InAppBrowserPopup.css';

const getBrowserInfo = () => {
  if (typeof navigator === 'undefined') {
    return {
      userAgent: 'unknown',
      isInAppBrowser: false,
      vendor: 'unknown',
    };
  }

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isInAppBrowser = /Instagram|Facebook|FBAN|FBAV|Twitter|TikTok/i.test(userAgent);

  return {
    userAgent,
    isInAppBrowser,
    vendor: navigator.vendor,
  };
};

const InAppBrowserPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [debugInfo, setDebugInfo] = useState({});

  useEffect(() => {
    const browserInfo = getBrowserInfo();
    setDebugInfo(browserInfo);
    
    if (browserInfo.isInAppBrowser) {
      setShowPopup(true);
    }
  }, []);

  if (!showPopup) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2 className="popup-heading">Hi there!</h2>
        <p className="popup-message">
          It looks like you have opened this app within a social media platform.
          For the full functionality of the app, please open it in an external browser (e.g., Chrome, Safari).
        </p>
        <button 
          onClick={() => setShowPopup(false)}
          className="popup-button"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default InAppBrowserPopup;