import { chains } from "../chain";
import {
  createSmartAccountClient,
  BiconomySmartAccountV2,
  PaymasterMode,
} from "@biconomy/account";
import { ethers } from "ethers";
import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { useAuth } from 'src/context/web3authProvider/AuthContext';

 const useWeb3Auth = () => {
  const { authProvider, setAuthProvider } = useAuth();

  const chainConfig = {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: chains.chainIdHex,
    rpcTarget: chains.providerUrl,
    displayName: chains.name,
    blockExplorer: chains.explorerUrl,
    ticker: "MATIC",
    tickerName: chains.name,
  };

  //Creating web3auth instance
  const web3auth = new Web3Auth({
    authMode: "WALLET",
    // to do (Client Id should be change to our)
    clientId: chains.web3authClientId, // Get your Client ID from the Web3Auth Dashboard https://dashboard.web3auth.io/
    web3AuthNetwork: "sapphire_devnet", // Web3Auth Network
    chainConfig,
    uiConfig: {
      appName: "Web3",
      mode: "dark", // light, dark or auto
      loginMethodsOrder: ["google"],
      logoLight: "https://web3auth.io/images/web3auth-logo.svg",
      logoDark: "https://web3auth.io/images/web3auth-logo---Dark.svg",
      defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl
      loginGridCol: 3,
      primaryButton: "emailLogin", // "externalLogin" | "socialLogin" | "emailLogin"
    },
  });

  const openloginAdapter = new OpenloginAdapter({
    loginSettings: {
      mfaLevel: "optional",
    },
    adapterSettings: {
      uxMode: "redirect", // "redirect" | "popup"
      whiteLabel: {
        name: "Web3",
        logoLight: "https://web3auth.io/images/w3a-L-Favicon-1.svg",
        logoDark: "https://web3auth.io/images/w3a-D-Favicon-1.svg",
        defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl
        dark: true, // whether to enable dark mode. defaultValue: false
      },
      mfaSettings: {
        deviceShareFactor: {
          enable: true,
          priority: 1,
          mandatory: true,
        },
        backUpShareFactor: {
          enable: true,
          priority: 2,
          mandatory: false,
        },
        socialBackupFactor: {
          enable: true,
          priority: 3,
          mandatory: false,
        },
        passwordFactor: {
          enable: true,
          priority: 4,
          mandatory: false,
        },
      },
    },
  });
  web3auth.configureAdapter(openloginAdapter);

const initWallet = async()=>{
  
    await web3auth.initModal({
      modalConfig: {
        [WALLET_ADAPTERS.OPENLOGIN]: {
          label: "openlogin",
          loginMethods: {
            // Disable options
            facebook: {
              name: "facebook",
              showOnModal: false,
            },
            reddit: {
              name: "reddit",
              showOnModal: false,
            },
            twitter: {
              name: "twitter",
              showOnModal: false,
            },
            discord: {
              name: "discord",
              showOnModal: false,
            },
            twitch: {
              name: "twitch",
              showOnModal: false,
            },
            apple: {
              name: "apple",
              showOnModal: false,
            },
            github: {
              name: "github",
              showOnModal: false,
            },
            line: {
              name: "line",
              showOnModal: false,
            },
            linkedin: {
              name: "linkedin",
              showOnModal: false,
            },
            kakao: {
              name: "kakao",
              showOnModal: false,
            },
            weibo: {
              name: "weibo",
              showOnModal: false,
            },
            wechat: {
              name: "wechat",
              showOnModal: false,
            },
            sms_passwordless: {
              name: "sms_passwordless",
              showOnModal: false,
            },
          },
        },
      },
    });
    const web3authProvider = await web3auth.connect();

    const userInfo = await web3auth.getUserInfo();
    const userEmail = userInfo.email;
    localStorage.setItem("email", userEmail);
  
    const ethersProvider = new ethers.providers.Web3Provider(web3authProvider);
    const web3AuthSigner = ethersProvider.getSigner();
  
    const config = {
      biconomyPaymasterApiKey: chains.biconomyPaymasterApiKey,
      bundlerUrl: chains.biconomyBundlerUrl, // <-- Read about this at https://docs.biconomy.io/dashboard#bundler-url
    };
    const smartWallet = await createSmartAccountClient({
      signer: web3AuthSigner,
      biconomyPaymasterApiKey: config.biconomyPaymasterApiKey,
      bundlerUrl: config.bundlerUrl,
      rpcUrl: chains.providerUrl,
      chainId: Number(chains.chainId),
    });
  
    // localStorage.setItem("smart_wallet", smartWallet);
    setAuthProvider(smartWallet);
    return smartWallet;
}

  return {
    initWallet
  }
}

export default useWeb3Auth