import {
    createSmartAccountClient,
    BiconomySmartAccountV2,
    PaymasterMode,
  } from "@biconomy/account";
  import { ethers } from "ethers";
  import { flicsABI } from "../contract/flicsABI";
  
  import { chains } from "../chain";
  import { toast } from "react-toastify";
  
  export const sendTokensToRefundFlics = async (authProvider, initiatorAddress) => {
      toast.info("Sending Flics Refund Transaction. Please Wait.")
      const flicsAddress = chains.flicsPoolContractAdd;
      const provider = new ethers.providers.JsonRpcProvider(
          chains.providerUrl
      );
      const flicsInstance = new ethers.Contract(
          flicsAddress,
          flicsABI,
          provider
      )
  
    try {
  
      const minTx = await flicsInstance.populateTransaction.refundTokens(initiatorAddress);
      
      const tx1 = {
        to: flicsAddress,
        data: minTx.data,
      };

      //@ts-ignore
      const userOpResponse = await authProvider?.sendTransaction(tx1, {
        paymasterServiceData: { mode: PaymasterMode.SPONSORED },
      });
      //@ts-ignore
      const { transactionHash } = await userOpResponse.waitForTxHash();
  
      if (transactionHash) {
        return true;
      } 
      
  
    } catch (error) {
      console.log(error);
      return false;
    }
  };