import { ethers } from "ethers";
import { ChainId } from "@biconomy/core-types";
import SmartAccount from "@biconomy/smart-account";

const { REACT_APP_DAPP_API_KEY, REACT_APP_PROVIDER_URL } = process.env;

export const EVENTS = {
  TX_HASH_GENERATED: "txHashGenerated",
  ON_HASH_CHANGED: "onHashChanged",
  TX_MINED: "txMined",
  TX_ERROR: "txError",
};

const recipientAddress = "0x00Bd141C24Bd3C36dbA3644Edd1E09d52C544872";
const amount = 10000;
const options = {
  activeNetworkId: ChainId.BSC_TESTNET,// add to config // env variable
  supportedNetworksIds: [ChainId.BSC_TESTNET],// add to config // env variable
  networkConfig: [
    {
      chainId: ChainId.BSC_TESTNET,// add to config // env variable
      // Dapp API Key you will get from new Biconomy dashboard that will be live soon
      // Meanwhile you can use the test dapp api key mentioned above
      dappAPIKey: REACT_APP_DAPP_API_KEY, // add to config // env variable
      providerUrl: REACT_APP_PROVIDER_URL // add to config // env variable
    }
  ]
};
export const isProviderAvailable = (socialLogin) => {
  return !!socialLogin.getProvider() || !!socialLogin.provider;
}
export const getProvider = (socialLogin) => {
  return new ethers.providers.Web3Provider(
    socialLogin.provider,
  );
}

export const getSmartAccount = async (provider, events = {}) => {
  const smartAccount = new SmartAccount(provider, options);
  await smartAccount.init();
  return smartAccount;
};


export const sendTransaction = async (smartAccount, tx) => {

  // Sending gasless transaction
  const txResponse = await smartAccount.sendTransaction({ transaction: tx });

  // If you do not subscribe to listener, one can also get the receipt like shown below 
  const txReciept = await txResponse.wait();

  return txReciept;
};

