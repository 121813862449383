import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../header/header";
import CustomButton from "../../../components/Button/button";
import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import zoneData from "../../../utils/zoneData";
import { UPDATE_PROFILE } from "../../../axios/POST_API";
import leftarrow from "src/assets/images/leftarrow.svg";
import logo from "src/assets/images/dropdsmall.svg";
import coupleImageIcon from "src/assets/images/coupleimage.svg";
import openMarriageIcon from "src/assets/images/openmarriage.svg";
import seniorsIcon from "src/assets/images/seniors.svg";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import Userdetailsnav from "../../../components/UserDetailsNav/userdetailsnav";
import "./zone.css";
import { Slider } from "@mui/material";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[600],
    "&:hover": {
      backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[600],
  },
}));

const SeekSlider = styled(Slider)(({ theme }) => ({
  color: "#E1D7F0",
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 47,
    width: 47,
    backgroundColor: "#fff",
    boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      "@media (hover: none)": {
        boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
      },
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#E1D7F0",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#E1D7F0",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "#E1D7F0",
    },
  },
}));

function Zone({ setSliderPage }) {
  const spinner = useSpinner();
  const [zone, setZone] = useState();
  const [loveGrounds, setLoveGrounds] = useState(0);
  const [openMarriage, setOpenMarriage] = useState(0);
  const [seniorsLove, setSeniorsLove] = useState(0);
  const [zoneError, setZoneError] = useState();
  const { t } = useTranslation();

  const handleChange = (e) => {
    setZone(e.target.value);
    console.log("line 87", e.target.value);
  };

  const slideLoveGrounds = (event, value) => {
    if (value === 100) {
      setLoveGrounds(100);
      setOpenMarriage(0);
      setSeniorsLove(0);
      setZone("Love Grounds");
    } else {
      setLoveGrounds(0);
      setZone("");
    }
  };

  const slideOpenMarriage = (event, value) => {
    if (value === 100) {
      setOpenMarriage(100);
      setLoveGrounds(0);
      setSeniorsLove(0);
      setZone("Open Marriage Commune");
    } else {
      setOpenMarriage(0);
      setZone("");
    }
  };

  const slideSeniorsLove = (event, value) => {
    if (value === 100) {
      setSeniorsLove(100);
      setOpenMarriage(0);
      setLoveGrounds(0);
      setZone("Seniors In Love Again");
    } else {
      setSeniorsLove(0);
      setZone("");
    }
  };

  const handleZone = async () => {
    if (!zone) {
      setZoneError("Please select at least one option");
      return;
    }
    try {
      spinner.setLoadingState(true);
      await UPDATE_PROFILE("zone", zone);
      spinner.setLoadingState(false);
      setSliderPage("knowbetter");
    } catch (error) {
      setZoneError(error.response.data.message);
      spinner.setLoadingState(false);
      console.log(error, "this is the error in zone updation");
    }
  };

  const handleBack = () => {
    setSliderPage("partner_kundli_attributes");
  };

  return (
    <div className="signup-screen-wrapper">
      <Header />
      
      <div className="entry-wrap">
        <div className='zone-text-container'>
          <div className='zone-text'>
            Mark Your Zone
          </div>
        </div>
        {/*<div className='zone-description'>Some text here dummy text right now</div>*/}
      </div>
      
      <div className="content-wrap">
        <div className='zone-container'>
          <div className="zoneOptionLabel">Love Grounds</div>
          <div className={(zone === "Love Grounds") ? "selectedSlide" : ""}>
            <div className="zoneOptionWrap">
              <div className="zoneIconWrap">
                <img src={coupleImageIcon} alt="Love Grounds" />
              </div>
              <div className="sliderWrap">
                <SeekSlider
                  min={0}
                  max={100}
                  step={100}
                  value={loveGrounds}
                  onChange={slideLoveGrounds}
                />
              </div>
            </div>
          </div>
          <div className={(zone === "Open Marriage Commune") ? "selectedSlide" : ""}>
            <div className="zoneOptionLabel">Open Marriage Communes</div>
            <div className="zoneOptionWrap">
              <div className="zoneIconWrap">
                <img src={openMarriageIcon} alt="Open Marriage Communes" />
              </div>
              <div className="sliderWrap">
                <SeekSlider
                  min={0}
                  max={100}
                  step={100}
                  value={openMarriage}
                  onChange={slideOpenMarriage}
                />
              </div>
            </div>
          </div>
          <div className={(zone === "Seniors In Love Again") ? "selectedSlide" : ""}>
            <div className="zoneOptionLabel">Seniors In Love Again</div>
            <div className="zoneOptionWrap">
              <div className="zoneIconWrap">
                <img src={seniorsIcon} alt="Seniors In Love Again" />
              </div>
              <div className="sliderWrap">
                <SeekSlider
                  min={0}
                  max={100}
                  step={100}
                  value={seniorsLove}
                  onChange={slideSeniorsLove}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ color: "red", fontSize: "10px", paddingTop: "10px", paddingLeft: "20px" }}>
          {zoneError}
        </div>
      </div>

      <div className="button-wrap">
        <CustomButton onClick={handleZone}>{t("proceed.1")}</CustomButton>
      </div>
    </div>
  );
}

export default Zone;