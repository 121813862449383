import React, { useState, useEffect } from 'react';
import TimelineNav from "../../../components/timelinenav/timelineNav";
import { CLAIM_HISTORY, FLICS_INITIATED_HISTORY, FLICS_ACTIVATED_HISTORY, ADD_FRIEND_HISTORY, ACCEPT_FRIEND_HISTORY, CANCEL_FRIEND_HISTORY, REJECT_FRIEND_HISTORY, CREATE_CLUB_HISTORY, JOIN_CLUB_HISTORY, VIEWMATCH_FEES_HISTORY, CREATE_PAGE_HISTORY, SUBSCRIPTION_HISTORY} from '../../../axios/web3/transactionHistory';
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg";
import transOutIcon from "src/assets/images/transouticon.svg";
import transInIcon from "src/assets/images/transinicon.svg";
import "../mywallet.css";

function MyWallet({ setSliderPage }) {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const userAddress = localStorage.getItem("wallet_address");
        const [claimHistory, addFriendHistory, acceptFriendHistory, rejectFriendHistory, cancelFriendHistory, flicsActivatedHistory, flicsInitiatedHistory, createClubHistory, joinClubHistory, viewmatchFeesHistory, subscriptionHistory] = await Promise.all([
          CLAIM_HISTORY(userAddress),
          ADD_FRIEND_HISTORY(userAddress),
          ACCEPT_FRIEND_HISTORY(userAddress),
          REJECT_FRIEND_HISTORY(userAddress),
          CANCEL_FRIEND_HISTORY(userAddress),
          FLICS_ACTIVATED_HISTORY(userAddress),
          FLICS_INITIATED_HISTORY(userAddress),
          CREATE_CLUB_HISTORY(userAddress),
          JOIN_CLUB_HISTORY(userAddress),
          VIEWMATCH_FEES_HISTORY(userAddress),
          SUBSCRIPTION_HISTORY(userAddress),
        ]);

        const allTransactions = [
          ...claimHistory.map(t => ({ ...t, type: 'Claim', direction: 'in' })),
          ...addFriendHistory.map(t => ({ ...t, type: 'Add Friend', direction: 'out' })),
          ...acceptFriendHistory.map(t => ({ ...t, type: 'Accept Friend', direction: 'in' })),
          ...rejectFriendHistory.map(t => ({ ...t, type: 'Reject Friend', direction: 'out' })),
          ...cancelFriendHistory.map(t => ({ ...t, type: 'Cancel Friend', direction: 'in' })),
          ...flicsActivatedHistory.map(t => ({ ...t, type: 'Flics Activate', direction: 'out' })),
          ...flicsInitiatedHistory.map(t => ({ ...t, type: 'Flics Initiate', direction: 'out' })),
          ...createClubHistory.map(t => ({ ...t, type: 'Create Club', direction: 'out' })),
          ...joinClubHistory.map(t => ({ ...t, type: 'Join Club', direction: 'out' })),
          ...viewmatchFeesHistory.map(t => ({ ...t, type: 'Viewmatch Fees', direction: 'out' })),
          ...subscriptionHistory.map(t => ({ ...t, type: 'Subscription', direction: 'out' })),
        ];

        allTransactions.sort((a, b) => Number(convertToUnix(b.block_timestamp)) - Number(convertToUnix(a.block_timestamp)));

        setTransactions(allTransactions);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching transactions:', err);
        setError('Failed to load transactions. Please try again later.');
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);
  
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
  };

  function convertToUnix(timestamp) {
    return Math.floor(new Date(timestamp).getTime() / 1000);
  }

  const getAmount = (transaction) => {
    switch (transaction.type) {
      case 'Claim':
      case 'Add Friend':
      case 'Accept Friend':
      case 'Reject Friend':
      case 'Cancel Friend':
      case 'Viewmatch Fees':
      case 'Subscription':
        return transaction.data.amount;
      case 'Flics Activate':
      case 'Flics Initiate':
        return transaction.data._amount;
      case 'Create Club':
        return transaction.data._creationFee;
      case 'Join Club':
        return transaction.data._joiningFee;
      default:
        return '0';
    }
  };

  return (
    <div className='page-page-wrapper flics-page-container'>
      <TimelineNav />
      <div className="inner-pages-container">
        <div className="inner-pages-container-wrap">
          <h1 className="page-title">Transactions</h1>
          <div className="transaction-container">
            {loading ? (
              <p>Loading transactions...</p>
            ) : error ? (
              <p>{error}</p>
            ) : transactions.length === 0 ? (
              <p>No transactions found.</p>
            ) : (
              transactions.map((transaction, index) => (
                <div className="transaction-row" key={index}>
                  <div className="trans-details-wrap">
                    <div className="trans-title"><span>{transaction.type}</span></div>
                    <div className="trans-date">{formatDate(convertToUnix(transaction.block_timestamp))}</div>
                  </div>
                  <div className="trans-bal">
                    <span><img src={dropdTokanIcon} alt="Token" /></span>
                    <span>{getAmount(transaction) / 1e18}</span>
                    <span>
                      <img 
                        src={transaction.direction === 'in' ? transInIcon : transOutIcon} 
                        alt={transaction.direction === 'in' ? "In" : "Out"} 
                      />
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyWallet;