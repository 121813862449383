const attributes = [
  { id: 1, text: "Thinker", coordinates: "5,8,74,74,3,146" },
  { id: 2, text: "Leader", coordinates: "6,1,150,1,78,71" },
  { id: 3, text: "Bread Earner", coordinates: "153,2,83,75,155,145,227,76" },
  { id: 4, text: "Homemaker", coordinates: "159,3,303,0,232,73" },
  { id: 5, text: "Care Giver", coordinates: "303,7,235,77,306,147" },
  { id: 6, text: "Good Parent", coordinates: "78,78,3,153,76,225,149,151" },
  { id: 7, text: "Passionate Lover", coordinates: "230,78,154,150,230,226,305,150" },
  { id: 8, text: "Funny", coordinates: "2,157,3,300,74,228" },
  { id: 9, text: "Gender Fluid", coordinates: "76,232,7,302,149,302" },
  { id: 10, text: "Straight", coordinates: "155,155,80,227,154,302,228,228" },
  { id: 11, text: "Homosexual", coordinates: "160,302,230,233,300,301" },
  { id: 12, text: "Nomad", coordinates: "235,232,306,154,304,299" },
];

export default attributes; 