import { chains } from "../chain";
import {
  createSmartAccountClient,
  BiconomySmartAccountV2,
  PaymasterMode,
} from "@biconomy/account";
import { ethers } from "ethers";
import { signUpABI } from "../contract/signUpContractABI";
import { contractABI } from "../contract/contractABI";
import { interactionABI } from "../contract/interactionABI";
import { toast } from "react-toastify";

export const claimDRPD = async (authProvider, hash, sign, callback = () => {}) => {
  toast.info("Sending Claim Transaction. Please Wait.")
  try {
    const signupAddress = chains.signUpPoolContractAdd;
    const contractAddress = chains.dropdContractAdd;
    const interactionAddress = chains.interactionContractAdd;
    const subscriptionAddress = chains.subscriptionAdd;
    const approvedSubscriptionAmount = ethers.utils.parseEther("1000000000");
    const approvedFirstSubscriptionAmount = ethers.utils.parseEther("500");
    const provider = new ethers.providers.JsonRpcProvider(
      chains.providerUrl
    );
    const signupInstance = new ethers.Contract(
      signupAddress,
      signUpABI,
      provider
    );
    const contractInstance = new ethers.Contract(
      contractAddress,
      contractABI,
      provider
    );
    const interactionInstance = new ethers.Contract(
      interactionAddress,
      interactionABI,
      provider
    );
    const minTx1 = await signupInstance.populateTransaction.claimToken(hash, sign);
    const tx1 = {
      to: signupAddress,
      data: minTx1.data
    };
    const minTx2 = await contractInstance.populateTransaction.approve(subscriptionAddress, approvedSubscriptionAmount);
    const tx2 = {
      to: contractAddress,
      data: minTx2.data
    };
    const minTx3 = await contractInstance.populateTransaction.approve(interactionAddress, approvedFirstSubscriptionAmount);
    const tx3 = {
      to: contractAddress,
      data: minTx3.data
    };
    const minTx4 = await interactionInstance.populateTransaction.FirstSubscription();
    const tx4 = {
      to: interactionAddress,
      data: minTx4.data
    };
    //@ts-ignore
    let i = 0;
    const userOpResponse = await authProvider?.sendTransaction([tx1,tx2,tx3,tx4], {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
      nonceOptions: {nonceKey: i++ }
    });
    //@ts-ignore
    const transactionReceipt = await userOpResponse.wait();
    if (transactionReceipt.success == "true") {
      return callback.call();
      // setTxnHash(transactionHash);
    }
  } catch (error) {
    console.log(error);
  }
};