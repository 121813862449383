import React, { useState, useEffect } from "react";
import TimelineNav from "../../components/timelinenav/timelineNav";
import avatar from "src/assets/images/avatar.jpeg";
import Commentcard from "./commentcard/commentcard";
import { TextField, CircularProgress, Snackbar, Alert } from "@mui/material";
import { InputAdornment } from "@mui/material";
import line from "src/assets/images/line.svg";
import { COMMENT_POST } from "../../axios/POST_API";
import { GET_SINGLE_POST } from "../../axios/GET_API";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useSpinner } from "../../context/loaderContext/globalSpinnerContext";
import SendIcon from '@mui/icons-material/Send';
import "./comments.css";

const { REACT_APP_CDN_HOST } = process.env;

function Comments() {
  const spinner = useSpinner();
  const { pid } = useParams();
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const getPostData = async () => {
    try {
      spinner.setLoadingState(true);
      let result = await GET_SINGLE_POST(pid);
      spinner.setLoadingState(false);
      setComments(result.post.comments);
    } catch (error) {
      console.log(error, "this is the timeline error");
    }
  };

  useEffect(() => {
    getPostData();
  }, [pid]);

  const handleCommentClick = async () => {
    if (!comment.trim()) return; // Don't submit empty comments

    // Create a new comment object
    const newComment = {
      comment_id: Date.now(), // Unique ID for the new comment
      comment_from: { 
        nick_name: "Posting...", // Placeholder for user's name
        profile_pics: ["https://via.placeholder.com/100"] // Placeholder for user's profile picture
      },
      comment,
      comment_date: new Date(),
    };

    // Optimistically update the UI
    setComments([newComment, ...comments]); // Add the new comment to the beginning of the list
    setComment(""); // Clear the input field
    setLoading(true);
    spinner.setLoadingState(true); // Start spinner context loading

    try {
      const commentResponse = await COMMENT_POST(pid, comment);
      if (commentResponse === true) {
        setSnackbarMessage("Comment posted successfully!");
        setSnackbarOpen(true); // Show success message
        setLoading(false);
        // Fetch updated comments from the API after posting
        await getPostData();
      } else {
        alert(commentResponse);
      }
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false); // Stop loading indicator
      spinner.setLoadingState(false); // Stop spinner context loading
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div style={{ backgroundColor: "white", height: "100vh" }}>
      <TimelineNav />

      <div className="comments-wrapper">
        <div className="comment-header-text">Comments</div>
        <div className="comment-card-box">
          {comments.map((item) => {
            // Check if the profile picture URL is a placeholder
            const isPlaceholder = item.comment_from.profile_pics[0] === "https://via.placeholder.com/100";
            const profilePicUrl = isPlaceholder
                ? item.comment_from.profile_pics[0] // Use as-is
                : `${REACT_APP_CDN_HOST}${item.comment_from.profile_pics[0]}`; // Prepend CDN host

            return (
              <Commentcard
                key={item.comment_id}
                comment_id={item.comment_id}
                comment_user={item.comment_from.nick_name}
                comment={item.comment}
                comment_date={moment(item.comment_date).fromNow()}
                comment_user_photo={profilePicUrl}
              />
            );
          })}
        </div>
        <div className="comment-textfield-position">
          <TextField
            style={{ backgroundColor: "white", borderRadius: "10px" }}
            id="outlined-basic"
            variant="outlined"
            multiline
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            size="small"
            sx={{
              width: "330px",
              "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                  border: "none",
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment style={{ paddingLeft: "2px" }} position="start">
                  <img style={{ paddingRight: "8px" }} src={line} alt="separator" />
                  <span
                    onClick={handleCommentClick}
                    style={{ position: "relative", left: "4px", color: "#525252", fontSize: "12px", fontWeight: "400" }}
                  >
                    {loading ? <CircularProgress size={24} /> : <SendIcon className="icon" />}
                  </span>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1500}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Comments;
