import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";  // Use standard quotes

const firebaseConfig = {
  apiKey: "AIzaSyAZbN8hciRiVk6Dwv1Bs1_kzWGnlY2kf2A",
  authDomain: "dropd-alpha.firebaseapp.com",
  projectId: "dropd-alpha",
  storageBucket: "dropd-alpha.appspot.com",
  messagingSenderId: "614922166214",
  appId: "1:614922166214:web:6a8833d3bea9a102e35cc7",
  measurementId: "G-XC74EH43PX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const analytics = getAnalytics(app); 

export { messaging, getToken, onMessage, analytics };