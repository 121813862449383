import React, { useEffect, useRef } from 'react';
import '../ChatMessages/ChatMessages.css';

const ChatMessagesHistory = ({ messages, senderDid }) => {
  const messagesEndRef = useRef(null);
  useEffect(() => {
    // Scroll to the bottom when messages change
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }

  }, [messages]);


  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);

    // Options for formatting the time and date
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };
    const optionsDate = { month: 'long', day: 'numeric', year: 'numeric' };

    // Format the time and date separately
    const time = date.toLocaleTimeString([], optionsTime);
    const formattedDate = date.toLocaleDateString([], optionsDate);

    return `${time}, ${formattedDate}`;
  };


  return (
    <div>
      {messages.map((msg, index) => {
        return (
          <div
            key={index}
            className={`message ${msg.message_from != senderDid ? 'sent' : 'received'}`}
          >
            <div className="message-content">{msg.message_text}</div>
            <div className="message-time">{formatDate(msg.created_at)}</div>
          </div>
        );
      })}

      <div ref={messagesEndRef} />
    </div>
  );
};

export default ChatMessagesHistory;