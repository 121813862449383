const interest = [
  { id: 1, text: "Acting" },
  { id: 2, text:  "Badminton"},
  { id: 3, text: "Baking" },
  { id: 4, text: "BDSM" },
  { id: 5, text: "Cats"},
  { id: 6, text: "Comedy"},
  { id: 7, text: "Cooking" },
  { id: 8, text:  "Cricket"},
  { id: 9, text: "Crypto" },
  { id: 10, text: "Cycling" },
  { id: 11, text: "Dance"},
  { id: 12, text: "Dogs"},
  { id: 13, text: "DTF" },
  { id: 14, text:  "Fashion"},
  { id: 15, text: "Fasting" },
  { id: 16, text: "Fiction" },
  { id: 17, text: "Film-making"},
  { id: 18, text: "FWB"},
  { id: 19, text: "Gaming" },
  { id: 20, text:  "Gardening"},
  { id: 21, text: "Golf" },
  { id: 22, text: "Group Sex" },
  { id: 23, text: "LTR"},
  { id: 24, text: "Luxuries"},
  { id: 25, text: "Marijuana" },
  { id: 26, text:  "Meditation"},
  { id: 27, text: "Music" },
  { id: 28, text: "Netflix" },
  { id: 29, text: "Non-Fiction"},
  { id: 30, text: "Nudism"},
  { id: 31, text: "Outdoors" },
  { id: 32, text:  "Painting"},
  { id: 33, text: "Parenting" },
  { id: 34, text: "Philosophy" },
  { id: 35, text: "Photography"},
  { id: 36, text: "Poetry"},
  { id: 37, text: "Politics"},
  { id: 38, text: "Pottery" },
  { id: 39, text:  "Religion"},
  { id: 40, text: "Running" },
  { id: 41, text: "Sex Toys" },
  { id: 42, text: "Simple Living"},
  { id: 43, text: "Startups"},
  { id: 44, text: "Swimming" },
  { id: 45, text:  "Tantra"},
  { id: 46, text: "Tennis" },
  { id: 47, text: "Yoga" },
];

export default interest; 