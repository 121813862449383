import React from "react"
import likeicon from "src/assets/images/likeicon.svg"
import message from "src/assets/images/message.svg"
import share from "src/assets/images/share.svg"
import "./postcard.css";

function Postcard({date,likes,shares,comments,image,welcometext}) {
  return (
    <div className="postcard">
      {welcometext && (
        <div className="postcard-text-container">
          <span>{welcometext}</span>
        </div>
      )}

      {image && (
        <div className="postcard-image-container">
          <img src={image} alt='avatarimage' />
        </div>
      )}

      <div className='postcard-footer-container'>
        <div style={{ paddingLeft: "10px", paddingTop: "3px" }} className='user-time-details'>{date}</div>
        <div><img src={likeicon} alt='like' /><span className='card-footer-details'>{likes}</span></div>
        <div><img src={message} alt='like' /><span className='card-footer-details'>{comments}</span></div>
        <div style={{ paddingRight: "20px" }}><img src={share} alt='like' /><span className='card-footer-details'>10</span></div>
      </div>
    </div>
  )
}

export default Postcard