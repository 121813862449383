import React, { useState } from "react";
import { Dialog, DialogContent, List, ListItem, ListItemText, TextField } from "@mui/material";

function ClubNamePopup({ open, onClose, onSelectOption }) {
  const [inputValue, setInputValue] = useState(""); // State to track input field value

  const predefinedOptions = [
    "Cook, Eat, Clean together",
    "Picnic for Singles",
    "Poets, Singers & Comics",
    "Post-30 Matrimony",
    "Fit for Love",
    "Single Parents' Dating",
    "50+ Singles Dating",
    "Fat but Fit Dating",
    "Swipe, Hug, Kiss & Talk",
    "Big Foodies' Dating",
  ];

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSelectOption(inputValue);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type a name"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          style={{ marginBottom: "20px" }}
        />

        <span>Or Pick any</span>
        <List>
          {predefinedOptions.map((option, index) => (
            <ListItem
              button
              key={index}
              onClick={() => {
                onSelectOption(option);
                onClose();
              }}
            >
              <ListItemText primary={option} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

export default ClubNamePopup;