import React from 'react';

const handleNotificationNavigate = (history, route, sender_id, room_id, club_id) => {

  switch (route) {
    case 'friend-add-request':
      history('/friends?page=add');
      break;
    case 'friend-accept-request':
      history('/friends');
      break;
    case 'friend-reject-request':
      history('/notifications');
      break;
    case 'flics-received-request':
      history('/flics?page=recived');
      break;
    case 'flics-accepted-request':
      history('/flics?page=accept');
      break;
    case 'flics-rejected-request':
      history('/flics?page=reject');
      break;
    case 'flics-delete-request':
      history('/flics');
      break;
    case 'chat-message-received':
      history(`/chat?to_id=${sender_id}&token=${room_id}`); 
      break;
    case 'club-joined':
      history(`/club${club_id}`);
      break;
    case 'post-liked':
      history('/notifications'); 
      break;
    case 'post-comment':
      history('/notifications'); 
      break;
    case 'profile-liked':
      history('/profile');
      break;
    default:
      history('/notifications');
      break;
  }
};

export default handleNotificationNavigate;