const Genders = [
  { id: 1, gendertext: "Male with Female" },
  { id: 2, gendertext: "Female with Female" },
  { id: 3, gendertext: "Male with Male" },
  { id: 4, gendertext: "Male/Female with Female/Male" },
  { id: 5, gendertext: "Genderqueer with Genderqueer" },
]
export default Genders;





