const CardData = [
  {
    id:1,
    name:"Mr Cupid",
    duration:"Member since 3 years",
    total:"30k",
    thisMonth:"1089",
    date:"Dec 25, 11:30 pm",
    likes:"467",
    comments:"324",
    shares:"123",
    welcome:"Hi How u Doing!",
    imgsource:require("../assets/images/avatar.jpeg")
  },
  {
    id:2,
    name:"Butterfly",
    duration:"Member since 5 years",
    total:"20k",
    thisMonth:"1089",
    date:"Dec 22, 10:30 pm",
    likes:"467",
    comments:"324",
    shares:"123",
    welcome:"Wassup!",
    imgsource:require("../assets/images/avatar2.jpeg")
  },
  {
    id:3,
    name:"Mr Penguin",
    duration:"Member since 1 years",
    total:"10k",
    thisMonth:"1059",
    date:"Jan 5, 11:30 pm",
    likes:"467",
    comments:"324",
    shares:"123",
    welcome:"What a day",
    imgsource:require("../assets/images/avatar3.jpg")
  },
  {
    id:4,
    name:"Mr Penguin",
    duration:"Member since 1 years",
    total:"10k",
    thisMonth:"1059",
    likes:"467",
    date:"Dec 25, 11:30 pm",
    comments:"324",
    shares:"123",
    welcome:"Wanna hangout",
    imgsource:require("../assets/images/avatar4.jpg")
  },
]

export default CardData