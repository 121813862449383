import React, { useState } from "react"
import CustomButton from "../../../components/Button/button"
import { Grid } from "@mui/material"
import TimelineNav from "../../../components/timelinenav/timelineNav"
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import avatar from "src/assets/images/avatar.jpeg";
import verifiedIcon from "src/assets/images/verified.svg"
import menukebabIcon from "src/assets/images/menukebab.svg"
import search from "src/assets/images/search.svg";
import CardData from "../../../utils/cardData"
import Postcard from "../../profilepage/components/postcard/postcard"
import Friendbutton from "../../../components/Friendbutton/Friendbutton";
import FriendsLoader from "../../../components/SkeletonLoader/Friendskeleton/Friendskeleton";
import { useNavigate } from "react-router-dom";
import "./style.css"
// import FloatingToolbar from '../../components/FloatingToolbar'

const { REACT_APP_CDN_HOST } = process.env;


function CreateClubDetails({ setSliderPage, friendRequestData, friendRequestError }) {
  const history = useNavigate()
  const { t, i18n } = useTranslation();

  const changeToFriends = () => {
    setSliderPage("friendlist");
  }
  const changeToReceived = () => {
    setSliderPage("requestreceived");
  }
  const changeToSent = () => {
    setSliderPage("requestsent");
  }
  
  return (
    <div className='page-page-wrapper'>
      {friendRequestData ? <div>
        <TimelineNav />
        <div className="inner-pages-container">
          <div className="inner-pages-container-wrap">

            <div className="friends-tabs-wrap">
              <button id="cursor" onClick={changeToFriends}>Friends</button>
              <button id="cursor" className="active" onClick={changeToReceived}>Requests Received</button>
              <button id="cursor" onClick={changeToSent}>Sent</button>
            
            </div>
            <div className="friend-list-container">
              <div className='error'>{friendRequestError}</div>
              {friendRequestData?.friend_requests.map((item, index) => {

                let profileImage=""
                if (item.profile_pics != null) {
                  profileImage = item.profile_pics[0];
                  profileImage = `${REACT_APP_CDN_HOST}` + profileImage;
                }
                return (
                  <div className="user-row">
                    <div className="u-thumb">
                      <img onClick={()=>{history(`/profile/${item.did}`)}} src={profileImage} alt="img" />
                    </div>
                    <div className="u-details">
                      <div onClick={()=>{history(`/profile/${item.did}`)}} className="u-name">{item.nickname} <span className="verified-icon"><img src={verifiedIcon} /></span></div>
                      <div className="a-r-btn-wrap">
                        <Friendbutton walletAddress={item.wallet_address}  did={item.did} is_friend={false} is_friend_requested_to={false} is_friend_requested_from={true}/>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>:<FriendsLoader/>}

    </div>
  );
}

export default CreateClubDetails;
