import React, { useState, useEffect } from "react"
import TimelineNav from "../../components/timelinenav/timelineNav"
import { useNavigate } from "react-router-dom"
import FloatingToolbar from "../../components/FloatingToolbar"
import avatar from "src/assets/images/avatar.jpeg"
import flickslogo from "src/assets/images/flickslogo.svg"
import viewmatchicon from "src/assets/images/viewmatchicon.svg"
import dropdmenulogo from "src/assets/images/dropdmenulogo.svg"
import friendslogo from "src/assets/images/friendslogo.svg"
import "./Menupage.css"
import { USER_VIEW_PROFILE } from "../../axios/GET_API"
import { Grid } from "@mui/material"
const { REACT_APP_CDN_HOST } = process.env;

function Menupage() {
  const [menuNav, setMenuNav] = useState()
  const [profileData, setProfileData] = useState()
  const history = useNavigate()

  const handleLogout = () => {
    localStorage.clear();
    history("/")
  }

  const getProfile = async () => {
    let response = await USER_VIEW_PROFILE()
    setProfileData(response?.data)
  }

  useEffect(() => {
    setMenuNav(true)
    getProfile()
  }, [])

  let userProfileImage = "";
  if (profileData?.profile_pics != null) {
    userProfileImage = profileData?.profile_pics[0];
    userProfileImage =
      `${REACT_APP_CDN_HOST}` + userProfileImage.replace("//", "/");
  }


  return (
    <div className='menu-page-wrapper'>
      <TimelineNav />
      <div className='menu-profile-container'>
        <Grid container>
          <Grid item xs={3}><div style={{ width: "60px", height: "60px", borderRadius: "50%", backgroundColor: "pink", position: "relative", top: "20px", left: "15px" }}><img style={{ width: "60px", height: "60px", borderRadius: "50%", objectFit: "cover" }} src={userProfileImage} /></div></Grid>
          <Grid item xs={9}>
            <div className='menu-profiledetail-box'>
              <div style={{ color: "#484848", fontSize: "16px", fontWeight: "600" }}>{profileData?.full_name}</div>
              <div style={{ color: "#6A7587", fontSize: "12px", fontWeight: "400" }}>Profile</div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid style={{ marginTop: "20px" }} container>
          <Grid className='menu-item-box' item xs={6}>
            <div onClick={() => { history("/friends") }} className='menu-item-box-inner'><div className='menu-item-text'><span className='menu-icon-alignment'><img src={friendslogo} /></span>Friends</div></div>
          </Grid>
          <Grid className='menu-item-box' item xs={6}>
            <div onClick={() => { history("/wallet") }} className='menu-item-box-inner'><div className='menu-item-text'><span className='menu-icon-alignment'><img src={dropdmenulogo} /></span>Wallet</div></div>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid style={{ marginTop: "20px" }} container>
          <Grid className='menu-item-box' item xs={6}>
            <div className='menu-item-box-inner'><div className='menu-item-text'><span className='menu-icon-alignment'><img src={viewmatchicon} /></span>Viewmatch</div></div>
          </Grid>
          <Grid className='menu-item-box' item xs={6}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => { history("/menu") }}
              className='menu-item-box-inner'

            >
              <div className='menu-item-text'>
                <span className='menu-icon-alignment'>
                  <img src={flickslogo} />
                </span>Dashboard <span style={{fontSize: "10px", lineHeight: "10px" }}>coming soon</span></div></div>
          </Grid>
        </Grid>
      </div>
      <>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className='menu-line'></div>
        </div>
        <div onClick={() => { history("/notifications") }}  className='menu-item-text-2'>Notifications</div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className='menu-line'></div>
        </div>
        <div onClick={() => { history("/edituserdetails") }} className='menu-item-text-2'>Settings</div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className='menu-line'></div>
        </div>
        <div onClick={handleLogout} className='menu-item-text-2'>Logout</div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className='menu-line'></div>
        </div>
      </>
      <FloatingToolbar menuNav={menuNav} />
    </div>
  )
}

export default Menupage