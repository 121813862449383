import ReactGA from 'react-ga';

// Function to log events with 'did' only
const logEvent = (eventName) => {
  const did = localStorage.getItem('did'); // Get 'did' from localStorage

  if (did) {
    window.gtag('event', eventName, {
      event_category: 'User',
      event_label: did, // Include the 'did' (user ID) in the event log
    });

    console.log(`Event: ${eventName} logged with did: ${did}`);
  } else {
    console.error('User ID (did) not found in localStorage');
  }
};


// Export event tracking functions for each signup screen
export const logSignupPhonePage = () => {
  logEvent('signup_phone_page');
};

export const logSignupInterestsPage = () => {
  logEvent('signup_interests_page');
};

export const logSignupProfilePicsPage = () => {
  logEvent('signup_profile_pics_page');
};

export const logSignupNickNamePage = () => {
  logEvent('signup_nick_name_page');
};

export const logSignupGenderPage = () => {
  logEvent('signup_gender_page');
};

export const logSignupGenderPreferencesPage = () => {
  logEvent('signup_gender_preference_page');
};

export const logSignupPersonKundliAttributesPage = () => {
  logEvent('signup_person_kundli_attributes_page');
};

export const logSignupPartnerKundliAttributesPage = () => {
  logEvent('signup_partner_kundli_attributes_page');
};

export const logSignupZonePage = () => {
  logEvent('signup_zone_page');
};

export const logSignupKnowBetterPage = () => {
  logEvent('signup_knowbetter_page');
};

export const logSignupClaimPage = () => {
  logEvent('signup_claim_page');
};

export const logSignupClaimDone = () => {
  logEvent('signup_claim_done');
};