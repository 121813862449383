import React, { useState,useEffect } from "react"
import CustomButton from "../../../components/Button/button"
import { useTranslation } from "react-i18next";
import Genders from "../../../utils/gender";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import { UPDATE_PROFILE } from "../../../axios/POST_API";
import leftarrow from "src/assets/images/leftarrow.svg"
import logo from "src/assets/images/dropdsmall.svg"
import Userdetailsnav from "../../../components/UserDetailsNav/userdetailsnav";
import "./gender.css"

function Gender({ setSliderPage, setGender,profileData }) {
  const spinner = useSpinner()
  const [selected, setSelected] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const { t, i18n } = useTranslation();

  const handleGenderPreferences = async () => {
    // if (selected) {
    if(!selected){
      setErrorMessage("please select an option")
    }
    try {
      spinner.setLoadingState(true)
      await UPDATE_PROFILE("gender", selected)
      spinner.setLoadingState(false)
      // setSliderPage("genderpreferences")

    } catch (error) {
      spinner.setLoadingState(false)
      setErrorMessage(error.response.data.message)
      // }
      setGender(selected)
    }
    setErrorMessage("please select an option")
  }

  const handleBack = () => {
    setSliderPage("name")
  }

  return (
    <div className='gender-wrapper'>
      {/* <Userdetailsnav/> */}
      <div className='navbar-wrapper'>
        <div onClick={handleBack} style={{ position: "relative", top: "20px", left: "15px" }}><img src={leftarrow} alt="back" /></div>
        <div style={{ width: "auto" }}><img style={{ position: "relative", left: "30px", top: "12px" }} id='dropd-logo' src={logo} alt="dropd-logo" /></div>
      </div>

      <div className="main-wrap">
        <div className="entry-wrap">
          <div className='gender-text-container'>
            <div className='gender-text'>
              {t("your-gender.1")}
            </div>
          </div>
          <div className='gender-description'>{t("we-welcome-everyone.1")}</div>
        </div>
        <div className="content-wrap">
          <div className='gender-container'>
            {
              Genders.map((item, index) => {
                return (
                  <div onClick={() => {setSelected(item.gendertext)}} className={selected===item.gendertext ? "selectedgender" : "gender"}><span className='gender-text-style'>{item.gendertext}</span></div>
                )
              })
            }
          </div>
          <div style={{color:"red",fontSize:"10px",paddingTop:"10px"}}>{errorMessage}</div>
        </div>
        <div className="button-wrap">
          <CustomButton onClick={handleGenderPreferences}>{t("proceed.1")}</CustomButton>
        </div>
      </div>

    </div>
  )
}

export default Gender