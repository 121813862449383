import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types';
import { getFlicsRequest } from "src/axios/POST_API";

import moneyIcon from "src/assets/images/money-white.svg";

const FlicsRequestDisplay = ({flicsRequestDetails}) => {
  const [flicsRequests, setFlicsRequests] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const flicsRequestList = await getFlicsRequest();
        setFlicsRequests(flicsRequestList);
      } catch (error) {
        console.error('Error fetching flics request list:', error);
      }
    };

    fetchData(); 
  }, []);

  return (
    <div className="flics-tabs-container">
      <div className="flics-options">
      {flicsRequests.map((flics, index) => (
        <button onClick={() => flicsRequestDetails(flics)} style={{ backgroundColor: '#EC1C80', color: '#ffffff' }}>
          <span>Recived FLICS<span style={{ marginLeft: '10px', fontWeigt: 'bold'}}><img src={moneyIcon} /> {flics.flics_amount[0] / 1e18}</span></span>
          <span>From {flics.nickname}</span>
        </button>
      ))}
      </div>
    </div>
  );
};

FlicsRequestDisplay.prototype = {
    flicsRequestDetails: PropTypes.func,
}

export default FlicsRequestDisplay;
