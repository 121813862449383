import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/Button/button"
import attributes from "../../../utils/attributes";
import kundali from "src/assets/images/kundali.svg"
import { UPDATE_PROFILE } from "../../../axios/POST_API";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import leader from "src/assets/images/attributeIcons/leader.svg"
import BreadEarner from "src/assets/images/attributeIcons/BreadEarner.svg"
import caregiver from "src/assets/images/attributeIcons/caregiver.svg"
import funny from "src/assets/images/attributeIcons/funny.svg"
import GenderFluid from "src/assets/images/attributeIcons/GenderFluid.svg"
import GoodParent from "src/assets/images/attributeIcons/GoodParent.svg"
import HomeMaker from "src/assets/images/attributeIcons/HomeMaker.svg"
import Homosexual from "src/assets/images/attributeIcons/Homosexual.svg"
import nomad from "src/assets/images/attributeIcons/nomad.svg"
import passionatelover from "src/assets/images/attributeIcons/passionatelover.svg"
import Straight from "src/assets/images/attributeIcons/Straight.svg"
import Thinker from "src/assets/images/attributeIcons/Thinker.svg"
import leftarrow from "src/assets/images/leftarrow.svg"
import logo from "src/assets/images/dropdsmall.svg"
import deleteIcon from "src/assets/images/deleteattr.svg"
import Userdetailsnav from "../../../components/UserDetailsNav/userdetailsnav";
import "./attributes.css"
import { useNavigate } from "react-router-dom";
import TimelineNav from "../../../components/timelinenav/timelineNav"
import Money from "src/assets/images/money.svg";
import notification from "src/assets/images/notification.svg";
import search from "src/assets/images/search.svg";


const PersonAttributes = {
  Leader: leader,
  "Bread Earner": BreadEarner,
  "Care Giver": caregiver,
  Funny: funny,
  "Gender Fluid": GenderFluid,
  "Good Parent": GoodParent,
  "Homemaker": HomeMaker,
  Homosexual: Homosexual,
  Nomad: nomad,
  "Passionate Lover": passionatelover,
  Thinker: Thinker,
  Straight,
};

const exclusiveAttributes = new Set(["Homosexual", "Gender Fluid", "Straight"]);

function Attributes({ setSliderPage, profileData }) {
  const history = useNavigate()
  const spinner = useSpinner()
  const { t, i18n } = useTranslation();
  const [personkundliattributes, setPersonkundliattributes] = useState([]);
  const [attributePreview, setAttributePreview] = useState([]);
  const [attributeError,setAttributeError] = useState()


  let items = [];

  useEffect(() => {
    if (profileData?.person_kundli_attributes) {
      setPersonkundliattributes(profileData.person_kundli_attributes);
    }
  }, [profileData]);


  const handleChange = (e) => {
    items.push(e.target.value);
  };
  const handlePersonAttributes = async () => {
    try {
      spinner.setLoadingState(true)
      await UPDATE_PROFILE(
        "person_kundli_attributes",
        personkundliattributes
      );
      spinner.setLoadingState(false)
      setSliderPage("index");
    } catch (error) {
      spinner.setLoadingState(false)
      setAttributeError(error.response.data.message)
      console.log(
        error,
        "this is the error in person attributes updation updation"
      );
    }
  };

  const handleNotifications = () => {
    history("/notifications")
  }

  const handleWallet = () => {
    history("/connectwallet")
  }
  const handleClick = () => {
    setSliderPage("index")
  }

  const updatePreview = () => {
    const _preview = personkundliattributes.map((item) => {
      return (
        <div className="attribute-icons">
          <img src={PersonAttributes[item]} />
        </div>
      );
    });
    setAttributePreview(_preview);
  };

  const handleBack = () => {
    setSliderPage("genderpreferences")
  }

  const toTitleCase = (str) => {
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  };

  const scaleCoordinates = (coords, svgSize, imageSize) => {
    const scale = svgSize / imageSize;
    return coords
      .split(',')
      .map((value, index) => (index % 2 === 0 ? value * scale : value * scale))
      .join(',');
  };


  const handleAttributeClick = (item) => {
    const _attributesArray = [...personkundliattributes];

    // Check if the item is one of the exclusive attributes
    if (exclusiveAttributes.has(item.text)) {
      // Check if any exclusive attribute is already selected
      const hasExclusiveAttribute = _attributesArray.some(attr => exclusiveAttributes.has(attr));

      if (hasExclusiveAttribute) {
        alert("You can only select one attribute from Homosexual, Gender Fluid, and Straight.");
        return;
      }
    }

    if (_attributesArray.includes(item.text.toLowerCase())) return;

    if (_attributesArray.length < 5) {
      _attributesArray.push(item.text.toLowerCase());
      setPersonkundliattributes(_attributesArray);
      updatePreview();
    }
  };

  return (

    <div className='page-page-wrapper edit-user-details-page'>
      {/* <TimelineNav /> */}
      <div
        style={{ width: "100vw", height: "65px", backgroundColor: "white" }}
        className="navbar-wrapper"
      >
        <div style={{ display: "flex", width: "60vw" }}>
          <div onClick={handleClick} style={{ position: "relative", top: "20px", left: "15px" }}>
            <img src={leftarrow} alt="back" />
          </div>
          <div style={{ width: "auto" }}>
            <img
              style={{ position: "relative", left: "30px", top: "12px" }}
              id="dropd-logo"
              src={logo}
              alt="dropd-logo"
            />
          </div>
        </div>
        <div style={{ width: "40vw" }}>
          <div
            style={{
              display: "flex",
              float: "right",
              width: "100px",
              justifyContent: "space-evenly",
              position: "relative",
              top: "20px",
            }}
          >
            <div>
              <img onClick={handleNotifications} src={notification} alt="money" />
            </div>
            <div>
              <img onClick={handleWallet} src={Money} alt="money" />
            </div>
            <div>
              <img src={search} alt="money" />
            </div>
          </div>
        </div>
      </div>
        
        
      <div className="attributes-page-container">
        <div className="inner-pages-container">
          <div className="inner-pages-container-wrap">
              <div className="entry-wrap">
                <h1 className="page-title">Edit Attributes That Carve You</h1>
                <div className="attribute-description">
                    Select 5 attributes you are looking in your partners kundali
                </div>
              </div>
              <div className="content-wrap">
                <div className="attribute-container">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340" style={{ width: '100%', height: 'auto' }}>
                    <image href={kundali} alt="kundali" width="340" height="340" />
                    {attributes.map((item, index) => (
                      <polygon
                        key={index}
                        points={scaleCoordinates(item.coordinates, 340, 306)}
                        fill="transparent"
                        stroke="transparent"
                        strokeWidth="1"
                        data-value={item.text}
                        onClick={() => handleAttributeClick(item)}
                      />
                    ))}
                  </svg>

                  <div style={{color:"red",fontSize:"10px",paddingLeft:"20px"}}>{attributeError}</div>
                </div>



                    

                {/* todo map these according to the items clicked */}
                <div className="attributeicons-position">
                  {personkundliattributes.map((item) => {
                    return (
                      <div className="attribute-icons"
                        onClick={() => {
                          if (personkundliattributes.includes(item)) {
                            let updatedAttributes = personkundliattributes.filter((element) => {
                              if (element != item) return element
                            })
                            setPersonkundliattributes(updatedAttributes)
                          } 
                        }}
                      >
                        <img src={PersonAttributes[toTitleCase(item)]} />
                        <div className="delete-attr-icon"><img src={deleteIcon} /></div>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="action-button-wrap">
                <CustomButton onClick={()=>{setSliderPage("index")}}>Cancel</CustomButton>
                <CustomButton onClick={handlePersonAttributes}>Update</CustomButton>
              </div>
          </div>
        </div>
      </div>
    </div> 

  );


}

export default Attributes