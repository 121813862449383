import React from 'react';

const ProgressBar = ({ currentStep, totalSteps }) => {
    const progressPercentage = (currentStep / totalSteps) * 100;

    return (
        <div style={{ width: '100%', backgroundColor: '#D3D3D3', borderRadius: '5px' }}>
            <div
                style={{
                    width: `${progressPercentage}%`,
                    height: '20px',
                    backgroundColor: '#ec1d80',
                    borderRadius: '5px',
                    position: 'absolute',
                    top: '0px',
                    left: '0px'
                }}
            />
            <div style={{ textAlign: 'center', fontSize: '12px', lineHeight: '20px', color: 'transparent', position: 'relative' }}>
                {currentStep} / {totalSteps}
            </div>
        </div>
    );
};

export default ProgressBar;