import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { USER_VIEW_PROFILE } from "../../axios/GET_API";
import { FRIEND_REQUEST, GET_ACCOUNT, MANAGED_CANCEL_REQUEST, MANAGED_REJECT_REQUEST } from "../../axios/POST_API";
import {
  ACCEPT_FRIEND_REQUEST,
  MANAGED_SEND_GIFT,
  MANAGED_CLAIM_GIFT,
} from "../../axios/POST_API";
import { acceptAddFriend } from "src/web3/AcceptFriend";
import { rejectFriend } from "src/web3/RejectFriend";
import { REJECT_FRIEND_REQUEST } from "../../axios/POST_API";
import { useSpinner } from "../../context/loaderContext/globalSpinnerContext";
import { useWallet } from "../../context/wallet/WalletContext";
import { sendGift, claimGift } from "../../web3/interactions";
import CustomButton from "../../components/Button/button";
import rejectfriend from "src/assets/images/rejectfriend.svg"
import { sendTokensForaddFriend } from "src/web3/addFriend";
import { cancelFriend } from "src/web3/CancelFriend";
import "./FriendButton.css"

import { useAuth } from 'src/context/web3authProvider/AuthContext';
import useWeb3Auth from "src/web3/useWeb3Auth";

function Friendbutton({
  did,
  is_friend,
  is_friend_requested_to,
  is_friend_requested_from,
  showFriendRequestStatus,
  friendsProfileData,
  walletAddress,
}) {

  var { authProvider } = useAuth();
  const { initWallet } = useWeb3Auth();
  const [open, setOpen] = useState(false);
  const [friendToggle, setFriendToggle] = useState(false);
  const [cancelFriendButton, setCancelFriendButton] = useState(true);
  const [addFriendToggle, setAddFriendToggle] = useState(false);
  const { account, active, activate } = useWeb3React();
  const [senderAddress] = useState(localStorage.getItem("wallet_address"));
  const wallet = useWallet();
  const spinner = useSpinner();

  const handleAddFriendRequest = async () => {
    spinner.setLoadingState(true);
    try {

      setOpen(false)
      setFriendToggle(true);
      setCancelFriendButton(true);
      // spinner.setLoadingState(false);
      showFriendRequestStatus('inprogress');
      // spinner.setLoadingState(false);

      if (!authProvider) {
        const smartWallet = await initWallet();
        authProvider = smartWallet;
      }

      const txRecipt = await sendTokensForaddFriend({
        authProvider,
        senderAddress,
        receiverAddress: walletAddress || friendsProfileData?.wallet_address,
      }, () => {
        showFriendRequestStatus('success');
      });

      if(txRecipt){
        spinner.setLoadingState(false);
        showFriendRequestStatus('success');
      }else{
        spinner.setLoadingState(false);
        showFriendRequestStatus('error');
      }
    } catch (error) {
      alert(error);
      showFriendRequestStatus('error', error);
      setFriendToggle(false);
      console.log(error);
    }
  };

  // useEffect(() => {
  //   activate(window.ethereum);
  // }, []);

  useEffect(() => {
    wallet.setSelectedWallet("DROPD");
  }, []);

  const handleAcceptFriendRequest = async () => {
    try {
      spinner.setLoadingState(true);
      
      if (!authProvider) {
        const smartWallet = await initWallet();
        authProvider = smartWallet;
      }

      await acceptAddFriend({
        authProvider,
        senderAddress: walletAddress,
      });

      // ACCEPT_FRIEND_REQUEST(did);
      setAddFriendToggle(true);
      spinner.setLoadingState(false);
    } catch (error) {
      alert(error)
      console.log(error);
      alert(error)
      spinner.setLoadingState(false);
    }
    finally {
      setOpen(false)
    }
  };

  const handleRejectFriendRequest = async () => {
    try {
      spinner.setLoadingState(true);
      // const from = await GET_ACCOUNT(did);

      if (!authProvider) {
        const smartWallet = await initWallet();
        authProvider = smartWallet;
      }

      rejectFriend(
        authProvider,
        walletAddress || friendsProfileData?.wallet_address
      );

      // REJECT_FRIEND_REQUEST(did);
      spinner.setLoadingState(false);
    } catch (error) {
      alert(error)
      console.log(error);
      spinner.setLoadingState(false);
    }
  };

  const handleCancelFriendRequest = async () => {
    // spinner.setLoadingState(true);
    try {
      spinner.setLoadingState(true);

      if (!authProvider) {
        const smartWallet = await initWallet();
        authProvider = smartWallet;
      }
      const txRecipt = await cancelFriend({
        authProvider,
        receiverAddress: walletAddress || friendsProfileData?.wallet_address,
      });

      spinner.setLoadingState(false);

      if(txRecipt){
        setCancelFriendButton(false);
      }
      setOpen(false)
    } catch (error) {
      spinner.setLoadingState(false);
      setFriendToggle(true);
    }
  };

  const printFriendButton = () => {
    if (is_friend == true || addFriendToggle == true) {
      return <button className="add-friend-button">Friends</button>;
    } else if ((is_friend_requested_to == true || friendToggle == true) && cancelFriendButton == true ) {
      return (
        <button
          id="cursor"
          onClick={handleCancelFriendRequest}
          className="add-friend-button"
        >
          Cancel
        </button>
      );
    } else if (is_friend_requested_from == true) {
      return (
        <div style={{ display: "flex" }}>
          <div><button
            id="cursor"
            onClick={handleAcceptFriendRequest}
            className="add-friend-button"
          >
            Accept
          </button></div>
          <div>
            <img id="cursor" style={{ position: "relative", top: "15px", right: "5px" }}
              alt="img"
              src={rejectfriend}
              onClick={handleRejectFriendRequest}
            /></div>
        </div>
      );
    } else {
      return (
        <>
          {open ? (
            <div className="add-friend-popup-wrapper">
              <div className="add-friend-popup">
                <span>
                  10 DRPD will be <br /> sent as a gift to this person
                </span>
                <CustomButton style={{ width: "50%" }} onTouchStart={handleAddFriendRequest} onClick={handleAddFriendRequest}>Send Request</CustomButton>
                <div style={{ marginTop: "15px" }}><CustomButton style={{ width: "50%" }} onTouchStart={() => setOpen(false)} onClick={() => setOpen(false)}>Cancel</CustomButton></div>
              </div>
            </div>
          ) : (
            ""
          )}

          <button
            id="cursor"
            onTouchStart={(event) => {
              event.stopPropagation();
              setOpen(true);
            }}
            onClick={(event) => {
              event.stopPropagation();
              setOpen(true);
            }}
            className="add-friend-button"
            style={{ pointerEvents: "auto", zIndex: 1000 }}
          >
            Add Friend
          </button>

        </>
      );
    }
  };

  return <div>{printFriendButton()}</div>;
}

Friendbutton.defaultProps = {
  showFriendRequestStatus: () => { },
  friendsProfileData: null,
};
export default Friendbutton;