import React, { useState, useEffect } from "react";

import logo from "src/assets/images/dropdsmall.svg"
import dropd from "src/assets/images/dropdlogo.svg"
import line from "src/assets/images/line.svg"

import { UPDATE_PROFILE, LOGIN, registerPhone, verifyPhone } from "src/axios/POST_API";
import { useNavigate } from "react-router-dom"
import { GET_INTERESTS } from "src/axios/GET_API"

import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useSpinner } from "src/context/loaderContext/globalSpinnerContext";
import OtpInput from "react-otp-input";
import { launchNextPage } from "src/PageLauncher";

function Phone() {
  const spinner = useSpinner();
  const history = useNavigate()
  const { page } = useParams()
  const [interests, setInterests] = useState([])
  const [imageFiles, setImagefiles] = useState()
  const [age, setAge] = useState()
  const [gender, setGender] = useState()
  const [genderpreference, setGenderPreference] = useState()
  const [personkundliattributes, setPersonkundliAttributes] = useState([])
  const [partnerkundliattributes, setPartnerkundliattributes] = useState([])
  const [zone, setZone] = useState();
  const [phone, setPhone] = useState();
  const [renderOtp, setRenderOtp] = useState(false);
  const { t, i18n } = useTranslation();
  const [otp, setOtp] = useState()

  const getInterests = async () => {
    const response = await GET_INTERESTS()
    setInterests(response)
  }

  useEffect(() => {
    let item = localStorage.getItem("token")
    if (!item) {
      history("/login")
    }
  }, [])



  const handleProfileUpdate = () => {
    UPDATE_PROFILE({ age: "25", genderpreference: genderpreference, gender: gender, personkundliattributes: personkundliattributes, partnerkundliattributes: partnerkundliattributes, zone: zone, interests: interests })
  }

  const [sliderPage, setSliderPage] = useState()


  const handleSendOtpMobile = async () => {
    const response = await registerPhone({ phone });
    if (response.data.status == "success") {
      setRenderOtp(true);
    }
    // validate a phone number
    // if (phone != 0) {
    //     let response
    //     try {
    //         spinner.setLoadingState(true)
    //         response = await LOGIN({ phone })
    //         spinner.setLoadingState(false)
    //         if (response.status == "error") {
    //             // setLoginError(response.message)
    //         } else {
    //             // setCounter(59)
    //         }
    //         setRenderOtp(true);
    //     } catch (error) {
    //         spinner.setLoadingState(false)
    //         // setLoginError(error.response.data.message)
    //         // setFailure(true)
    //         console.log(error, "this is the error")
    //     }
    // } else {
    // }
  };

  const handleSubmitOtp = async (oneTimePassword) => {
    setOtp(oneTimePassword);
    try {
      if (oneTimePassword.length === 6) {
        spinner.setLoadingState(true)
        const response = await verifyPhone({ phone, otp: oneTimePassword });
        console.log(response.data);
  
        await launchNextPage(history);
      }
    } catch (error) {
      //     // setLoginError(error.response.data.message)
      //     // setFailure(true)
      //     console.log(error)
    } finally {
      spinner.setLoadingState(false);
    }
  }

  const renderSendOtpButton = () => {

    return (<div id="cursor" onClick={handleSendOtpMobile} style={{ position: "relative", left: "15px", top: "12px", color: "#EC1C80", fontSize: "12px", fontWeight: "400" }} >{t("send-otp.1")}</div>)
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", }}>
      {/* {renderSlider()} */}
      <div className='input-label'>{t("mobile-no.1")}</div>
      <div style={{ display: "flex" }}>
        <PhoneInput
          country={"in"}
          value={phone}
          onChange={(n) => setPhone(n)}
          // inputStyle={{ border: 'none', width: 'auto', boxShadow: 'none', height: '42px', borderRadius: '10px 0px 0px 10px' }}
          // buttonStyle={{ backgroundColor: 'white', borderRadius: '10px', border: 'none' }}
          // dropdownStyle={{ borderRadius: '10px' }}
        />
        <div style={{ display: "flex", backgroundColor: "white", width: "90px", borderRadius: "0px 10px 10px 0px" }}>
          <div> <img style={{ position: "relative", top: "9px", left: "2px" }} src={line} alt="line" /></div>
        </div>
        {renderSendOtpButton()}
      </div>
      {renderOtp ?
        <div style={{ display: "flex", justifyContent: "center" }}>
          <OtpInput
            className="otp-wrapper"
            containerStyle={{
              width: 300,
              justifyContent: "space-between",
            }}
            inputStyle={{
              width: 35,
              height: 40,
              background: "white",
              fontSize: "1rem",
              borderRadius: 8,
              border: "none",
            }}
            value={otp}
            onChange={handleSubmitOtp}
            isInputNum={true}
            numInputs={6}
          />
        </div> : <></>
      }
    </div>

  )
}

export default Phone;