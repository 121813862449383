import { get } from "lodash";

const RetrieveToken = () => {
  let item = localStorage.getItem("token");
  return item
}


export const getIdToken = () => {
  const store = JSON.parse(localStorage.getItem("openlogin_store"));
  const idToken = get(store, "idToken", "");
  const email = get(store, "email", "");
  return { idToken, email };
}

export default RetrieveToken;