import React, { useEffect, useRef, useState } from 'react';
import avatar from "src/assets/images/avatar.jpeg";
import "./timeline.css";
import Simpleinput from "../../components/simpleinput/simpleinput";
// import Timelinecard from "./timelinecard/timelinecard";
import PostCard from "../../components/PostCard/PostCard";
import TimelineNav from "../../components/timelinenav/timelineNav";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSpinner } from "../../context/loaderContext/globalSpinnerContext";
import { GET_TIMELINE } from "../../axios/GET_API";
import { LIKE_POST } from "../../axios/POST_API";
import SkeletonLoader from "../../components/SkeletonLoader/skeletonLoader";
import { useSkeleton } from "../../context/skeletoncontext/skeletoncontext";
import { useParams } from "react-router-dom";
import FloatingToolbar from "../../components/FloatingToolbar";
import RetrieveToken from "../../utils/HelperFunctions/RetrieveToken";
import CustomToast from "../../components/CustomToast/CustomToast";

// Firebase Notification Permissions
import { messaging, getToken, onMessage } from "../../firebase";
import NotificationPermissionPopup from "../../components/NotificationPermissionPopup/NotificationPermissionPopup";
import { USER_META_FCM_TOKEN } from "../../axios/POST_API";


const { REACT_APP_CDN_HOST } = process.env;
const PAGE_SIZE = 20;

function Timeline() {
  const { did } = useParams();
  const skeleton = useSkeleton();
  const spinner = useSpinner();
  const history = useNavigate();
  const [posts, setPosts] = useState([]);
  const [timelineData, setTimelineData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const loadingRef = useRef(null);
  const [timelineError, setTimelineError] = useState()
  const [timelineNav, setTimelineNav] = useState()
  const [friendRequestStatus, setFriendRequestStatus] = useState(null);
  const [showFriendRequestBar, setShowFriendRequestBar] = useState(false);
  const [friendRequestBarMessage, setFriendRequestBarMessage] = useState('');
 
  const [showPermissionPopup, setShowPermissionPopup] = useState(false);
  const [notificationBlocked, setNotificationBlocked] = useState(false);
  const [notificationErrorOccurred, setNotificationErrorOccurred] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  // Request permission to send notifications
  const requestNotificationPermission = async (grantPermission) => {
    if (grantPermission) {
      try {
        spinner.setLoadingState(true);

        const token = await getToken(messaging, {
          vapidKey: "BDqNdTe7Wj9RdOTdOBDiX9WC0gMDYTICs40ZyKU8by3aLXqPUukvKfaUB5VAN7aKQ3O45F_W3JZeS1XUM595HCA" 
        });

        await USER_META_FCM_TOKEN(token);

        // Update local storage to reflect permission granted
        localStorage.setItem("notificationPermissionGranted", "true");
        localStorage.removeItem("notificationPermissionDenied");

        setShowPermissionPopup(false);
        spinner.setLoadingState(false);

        // Show success toast
        if (!localStorage.getItem("successToastShown")) {
          setShowSuccessToast(true);
          localStorage.setItem("successToastShown", "true");
        }

      } catch (error) {
        console.error("Permission denied or error occurred", error);
        setNotificationErrorOccurred(true);
        setShowPermissionPopup(false);

        // Don't close the popup on error
        spinner.setLoadingState(false);
      }
    } else {
      localStorage.setItem("notificationPermissionDenied", "true");
      setNotificationBlocked(true);
      setShowPermissionPopup(false);
      spinner.setLoadingState(false);
    }
  };

  // useEffect to Trigger the Notification Permission Popup
  useEffect(() => {
    const checkNotificationPermission = async () => {
      const permission = Notification.permission;
      const isDenied = localStorage.getItem("notificationPermissionDenied");

      if (permission === "default" && !isDenied) {
        setShowPermissionPopup(true);
      } else if (permission === "granted") {
        localStorage.setItem("notificationPermissionGranted", "true");
        localStorage.removeItem("notificationPermissionDenied");

        await requestNotificationPermission(true);
      } else if (permission === "denied") {
        localStorage.setItem("notificationPermissionDenied", "true");
        localStorage.removeItem("notificationPermissionGranted");

        setNotificationBlocked(true);
      }
    };

    checkNotificationPermission();
  }, []);

  const handleCloseToast = () => {
    setNotificationBlocked(false);
    setNotificationErrorOccurred(false);
    setShowSuccessToast(false);
  };
  
  // Intersection Observer setup
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '100px',
      threshold: 0.5
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && hasMore && !isLoading) {
        setCurrentPage(prev => prev + 1);
      }
    }, options);

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    return () => {
      if (loadingRef.current) {
        observer.unobserve(loadingRef.current);
      }
    };
  }, [hasMore, isLoading]);

  // Fetch timeline data
  const fetchTimelineData = async (page) => {
    try {
      setIsLoading(true);
      const response = await GET_TIMELINE(page);
      
      if (page === 1) {
        // For first page, set both posts and timeline data
        setPosts(response?.data?.posts || []);
        setTimelineData(response);
      } else {
        // For subsequent pages, only append new posts
        setPosts(prevPosts => [...prevPosts, ...(response?.data?.posts || [])]);
        
        // Update other timeline data if needed
        setTimelineData(prev => ({
          ...prev,
          data: {
            ...prev.data,
            is_profile_complete: response.data.is_profile_complete,
            message: response.data.message,
            status: response.data.status,
            user_profile: response.data.user_profile
          }
        }));
      }

      setHasMore(response?.data?.posts?.length === PAGE_SIZE);
      localStorage.setItem("timeline.response", JSON.stringify(response));
      setTimelineError(null);
    } catch (error) {
      console.error("Timeline fetch error:", error);
      setTimelineError(error?.response?.data?.message || "Failed to load posts");
    } finally {
      setIsLoading(false);
      skeleton.setLoadingState(false);
    }
  };

  const likePost = (pid) => {
    try {
      LIKE_POST(pid);
    } catch (error) {
      console.log(error);
    }
  };
  const handleProfile = () => {
    history("/profile");
  };

  const handlePost = () => {
    history("/createpost");
  };

  useEffect(() => {
    const token = RetrieveToken();
    if (!token) {
      history("/login");
      return;
    }

    // Only load cached data on initial load
    if (currentPage === 1) {
      const cachedResponse = localStorage.getItem("timeline.response");
      if (cachedResponse) {
        const parsedResponse = JSON.parse(cachedResponse);
        setPosts(parsedResponse?.data?.posts || []);
        setTimelineData(parsedResponse);
      }
    }

    fetchTimelineData(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (timelineData) {
      if (timelineData?.data.is_profile_complete == false) {
        history("/userdetails/" + timelineData?.data.incomplete_profile_label)
      }
      skeleton.setLoadingState(false);
    }

  }, [timelineData])

  let userProfileImage = "";
  if (timelineData?.data?.user_profile?.profile_pics != null) {
    userProfileImage = timelineData?.data?.user_profile?.profile_pics[0];
    userProfileImage =
      `${REACT_APP_CDN_HOST}` + userProfileImage.replace("//", "/");
  }


  return (
    <div className="timeline-container" style={
      showFriendRequestBar ? { 'marginTop': '8%' } : {}
    }
    >
      {showFriendRequestBar &&
        (<div className="floatingToolbar" style={{ 'top': '2%' }}>
          <span className="profile-icons-container">
            {friendRequestBarMessage}
          </span>
          {
            (friendRequestStatus === 'success' || friendRequestStatus === 'error')
            &&
            (<span className="close-icon" onClick={() => setShowFriendRequestBar(false)}>x</span>)
          }
        </div>)
      }
      {skeleton.isLoading ? (
        <SkeletonLoader />
      ) : (
        <div>
          <TimelineNav />
          <div className="search-box-container">
            <div
              onClick={handlePost}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="input-container-box"><div className="input-text">Whats on your mind ?</div></div>
            </div>
            <div onClick={handleProfile} className="avatar-container">
              <img
                id="cursor"
                className="avatar-image"
                src={userProfileImage}
                alt="avatar"
              ></img>
            </div>
          </div>

          {/* Posts List */}
          <div style={{paddingBottom: "60px" }}>
            {posts.map((item) => {
              const postImg = item.images?.[0] ? `${REACT_APP_CDN_HOST}${item.images[0]}` : "";
              const profileImage = item.post_from.profile_pics?.[0] 
                ? `${REACT_APP_CDN_HOST}${item.post_from.profile_pics[0].replace("//", "/")}` 
                : "";
              
              return (
                <div key={`${item.pid}-${currentPage}`} className="mb-1">
                  <PostCard
                    postComments={item.comments.length}
                    post_id={item.pid}
                    did={item.post_from.did}
                    postProfilePic={profileImage}
                    userName={item.post_from.nick_name}
                    postText={item.text}
                    postLikes={item.likes}
                    profileLikeCount={item.post_from.like_count}
                    postImage={postImg}
                    postDate={moment(item.created_at).format("MMM D, h:mm a")}
                    memberSince={moment(item.post_from.joined_at).fromNow()}
                    kundliAttributes={item.post_from.kundli_attributes}
                    zone={item.post_from.zone}
                    is_friend={item.post_from.is_friend}
                    is_friend_requested_to={item.post_from.is_friend_requested_to}
                    is_friend_requested_from={item.post_from.is_friend_requested_from}
                    is_liked={item.post_from.is_like_to}
                    walletAddress={item.post_from.wallet_address}
                    showFriendRequestStatus={(status, error) => {
                      setShowFriendRequestBar(true);
                      setFriendRequestStatus(status);
                      setFriendRequestBarMessage(
                        status === 'inprogress' ? "Friend request in progress!" :
                        status === 'success' ? "Friend request sent successfully!" :
                        "Friend request failed!"
                      );
                    }}
                  />
                </div>
              );
            })}

            {/* Loading and Error States */}
            <div ref={loadingRef} className="text-center">
              {isLoading && (
                <div className="flex flex-col items-center justify-center py-4">
                  <div className="animate-spin h-8 w-8 border-4 border-blue-500 border-t-transparent rounded-full"></div>
                  <div className="mt-2 text-sm text-gray-500">Loading more posts...</div>
                </div>
              )}
              {!hasMore && !isLoading && (
                <div className="text-gray-500 py-4">No more posts to load</div>
              )}
              {timelineError && !isLoading && (
                <div className="text-red-500 py-4">{timelineError}</div>
              )}
            </div>
          </div>

          <FloatingToolbar timelineNav={timelineNav} setTimelineNav={setTimelineNav} />

        </div>
      )}
      <div className="timeline_error">{timelineError}</div>

      {/* Render the Notification Permission Popup if needed */}
      {showPermissionPopup && (
        <NotificationPermissionPopup onRequestPermission={requestNotificationPermission} />
      )}
      {notificationBlocked && (
        <CustomToast
          message="Notifications have been blocked. You can enable them in your browser settings."
          onClose={handleCloseToast}
        />
      )}
      {notificationErrorOccurred && (
        <CustomToast
          message="An error occurred while requesting notification permission. You can enable them in your browser settings."
          onClose={handleCloseToast}
        />
      )}
      {showSuccessToast && ( // Render success toast
        <CustomToast
          message="Notifications have been enabled successfully!"
          onClose={handleCloseToast}
        />
      )}

    </div>
  );
}

export default Timeline;