import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from "react-router-dom";

import './ChatHeader.css';

const { REACT_APP_CDN_HOST } = process.env;


const ChatHeader = ({ profileImage, userNickName, senderDid }) => {
  const history = useNavigate();

  const avatar = `${REACT_APP_CDN_HOST}${profileImage}`;

  const navigateToProfile = () => {
    history(`/profile/${senderDid}`);
  };

  return (
    <div className="chat-header">
      <ArrowBackIcon className="icon" onClick={navigateToProfile} />
      <div className="profile" onClick={navigateToProfile}>
        <img src={avatar} alt="Profile" className="profile-pic" />
        <div className="profile-info">
          <div className="profile-name">{userNickName}</div>
          <div className="profile-status">Active a few moments ago</div>
        </div>
      </div>

      <div className="more-dots-icon">
        {/*<MoreVertIcon />*/}
      </div>
    </div>
  );
};

export default ChatHeader;