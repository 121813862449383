import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#ff4400",
    },
    secondary: {
      main: "#0044ff",
    },
    tertiary: {
      main: "#0044ff",
    },
  },
});

export default theme;