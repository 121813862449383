import {
  createSmartAccountClient,
  BiconomySmartAccountV2,
  PaymasterMode,
} from "@biconomy/account";
import { ethers } from "ethers";
import { flicsContractABI } from "../contract/flicsContractABI";
// 

import { chains } from "../chain";
import { toast } from "react-toastify";

export const sendTokensToRejectFlics = async (authProvider, flicsContractAddress) => {
    toast.info("Sending Flics Reject Transaction. Please Wait.")
    const provider = new ethers.providers.JsonRpcProvider(
        chains.providerUrl
    );
    
    const flicsContractInstance = new ethers.Contract(
        flicsContractAddress,
        flicsContractABI,
        provider
    )

  try {
    const nonce = await authProvider.getNonce();
    const minTx = await flicsContractInstance.populateTransaction.signAndReject();
    const tx1 = {
      to: flicsContractAddress,
      data: minTx.data,
      nonce: Number(nonce)
    };

    //@ts-ignore
    const userOpResponse = await authProvider?.sendTransaction(tx1, {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
    });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();
  
    if (transactionHash) {
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
