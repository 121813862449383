const {
  REACT_APP_GRAPH_ENDPOINT
} = process.env;

const axios = require('axios');

export async function fetchHistory(userAddress) {
  const endpoint = REACT_APP_GRAPH_ENDPOINT;

  const query = `
    query MyQuery {
      claims(
        first: 1000
        orderDirection: desc
      ) {
        user
        amount
        timestamp
      }
      cancelFriendRequests(
        first: 1000
        orderDirection: desc
        where: {from: "${userAddress}"}
      ) {
        amount
        timestamp
      }
      acceptFriendRequests(
        first: 1000
        orderDirection: desc
        where: {to: "${userAddress}"}
      ) {
        amount
        timestamp
      }
      clubCreateds(
        first: 1000
        orderDirection: desc
        where: {_creator: "${userAddress}"}
      ) {
        _creationFee
        blockTimestamp
      }
      clubJoineds(
        first: 1000
        orderDirection: desc
        where: {
          or: [
            {_creator: "${userAddress}"},
            {_joiner: "${userAddress}"}
          ]
        }
      ) {
        _creator
        _joiner
        _joiningFee
        blockTimestamp
      }
      flicsactivateds(
        first: 1000
        orderDirection: desc
        where: {_initiator: "${userAddress}"}
      ) {
        _amount
        blockTimestamp
      }
      flicsrefundeds(
        first: 1000
        orderDirection: desc
        where: {_initiator: "${userAddress}"}
      ) {
        _amount
        blockTimestamp
      }
      paidSubscriptions(
        first: 1000
        orderDirection: desc
        where: {account: "${userAddress}"}
      ) {
        amount
        blockTimestamp
      }
      rejectFriendRequests(
        first: 1000
        orderDirection: desc
        where: {from: "${userAddress}"}
      ) {
        amount
        blockTimestamp
      }
      sentFriendRequests(
        first: 1000
        orderDirection: desc
        where: {from: "${userAddress}"}
      ) {
        amount
        blockTimestamp
      }
      viewMatchFees_collection(
        first: 1000
        orderDirection: desc
        where: {from: "${userAddress}"}
      ) {
        amount
        blockTimestamp
      }
      transfers(
        first: 1000
        orderDirection: desc
        where: {to: "${userAddress}"}
      ) {
        from
        value
        blockTimestamp
      }
    }
  `;

  try {
    const response = await axios.post(endpoint, {
      query: query,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}