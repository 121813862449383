import React, { useState, useEffect } from 'react';
// import './Popup.css'; // External CSS for styling

const RequestAlreadySentPopup = ({ isOpen }) => {
  const [showPopup, setShowPopup] = useState(isOpen);

  if (!showPopup) return null;

  return (
  	<div className="popup-overlay">
  	  <div className="popup-content">
  	    <h2 className="popup-heading">Request Already Sent</h2>
  	    <p className="popup-message">
  	      You have already sent a request to this user. Please wait for their response before sending another request.
  	    </p>
  	    <button 
  	      onClick={() => setShowPopup(false)}
  	      className="popup-button"
  	    >
  	      Close
  	    </button>
  	  </div>
  	</div>
  );
};

export default RequestAlreadySentPopup;