import {
    createSmartAccountClient,
    BiconomySmartAccountV2,
    PaymasterMode,
  } from "@biconomy/account";
  import { ethers } from "ethers";
  import { contractABI } from "../contract/contractABI";
  import { interactionABI } from "../contract/interactionABI";
  import { chains } from "../chain";  
  import { getWalletBalance } from "./getWalletBalance";
  import { toast } from "react-toastify";

  export const sendTokensJoinClub = async (authProvider, joiningFee, clubId, clubAdminAddress) => {
    debugger;
      toast.info("Sending Join Club Transaction. Please Wait.")
      const walletBalance = await getWalletBalance();
      if (walletBalance < joiningFee / 1e18) {
        toast.warn('Insufficient Wallet Balance');
        return;
      }
  
      const contractAddress = chains.dropdContractAdd;
      const interactionAddress = chains.interactionContractAdd;
      const provider = new ethers.providers.JsonRpcProvider(
          chains.providerUrl
      );
      const contractInstance = new ethers.Contract(
          contractAddress,
          contractABI,
          provider
      );
      const interactionInstance = new ethers.Contract(
          interactionAddress,
          interactionABI,
          provider
      )
      const walletAddress = localStorage.getItem("wallet_address");
      const approvedAmount = ethers.utils.parseEther(joiningFee.toString());
  
    try {
      const minTx1 = await contractInstance.populateTransaction.approve(interactionAddress, approvedAmount);
      
      const tx1 = {
        to: contractAddress,
        data: minTx1.data
      };
      const minTx2 = await interactionInstance.populateTransaction.joinCLub(walletAddress, clubAdminAddress, clubId, joiningFee.toString());
      
      const tx2 = {
        to: interactionAddress,
        data: minTx2.data
      };
  
      //@ts-ignore
      let i = 0;
      const userOpResponse = await authProvider?.sendTransaction([tx1,tx2], {
        paymasterServiceData: { mode: PaymasterMode.SPONSORED },
        nonceOptions: {nonceKey: i++ }
      });
      //@ts-ignore
      const { transactionHash } = await userOpResponse.waitForTxHash();
      
      if (transactionHash) {
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  