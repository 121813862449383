import React from 'react';
import { useNavigate } from 'react-router-dom';
import leftarrow from "src/assets/images/leftarrow.svg";
import logo from "src/assets/images/dropdsmall.svg";

const Header = () => {
  const history = useNavigate();

  const handleBack = () => {
    history(-1);
  };

  return (
    <div className="header-wrap">
      <div className="back-button" onClick={handleBack}>
        <img src={leftarrow} alt="back" />
      </div>

      <div className="logo-wrap">
        <img id="dropd-logo" src={logo} alt="dropd-logo" />
      </div>
    </div>
  );
};

export default Header;
