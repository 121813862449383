import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../header/header";
import CustomButton from "../../../components/Button/button";
import attributes from "../../../utils/attributes";
import kundali from "src/assets/images/kundali.svg";
import { UPDATE_PROFILE } from "../../../axios/POST_API";
import leader from "src/assets/images/attributeIcons/leader.svg";
import BreadEarner from "src/assets/images/attributeIcons/BreadEarner.svg";
import caregiver from "src/assets/images/attributeIcons/caregiver.svg";
import funny from "src/assets/images/attributeIcons/funny.svg";
import GenderFluid from "src/assets/images/attributeIcons/GenderFluid.svg";
import GoodParent from "src/assets/images/attributeIcons/GoodParent.svg";
import HomeMaker from "src/assets/images/attributeIcons/HomeMaker.svg";
import Homosexual from "src/assets/images/attributeIcons/Homosexual.svg";
import nomad from "src/assets/images/attributeIcons/nomad.svg";
import passionatelover from "src/assets/images/attributeIcons/passionatelover.svg";
import Straight from "src/assets/images/attributeIcons/Straight.svg";
import Thinker from "src/assets/images/attributeIcons/Thinker.svg";
import leftarrow from "src/assets/images/leftarrow.svg";
import logo from "src/assets/images/dropdsmall.svg";
import deleteIcon from "src/assets/images/deleteattr.svg";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import Userdetailsnav from "../../../components/UserDetailsNav/userdetailsnav";
import "./partnerattributes.css";

const PartnerAttributes = {
  Leader: leader,
  "Bread Earner": BreadEarner,
  "Care Giver": caregiver,
  Funny: funny,
  "Gender Fluid": GenderFluid,
  "Good Parent": GoodParent,
  Homemaker: HomeMaker,
  Homosexual: Homosexual,
  Nomad: nomad,
  "Passionate Lover": passionatelover,
  Thinker: Thinker,
  Straight,
};

const exclusiveAttributes = new Set(["Homosexual", "Gender Fluid", "Straight"]);

function Partnerattributes({ setSliderPage }) {
  const spinner = useSpinner();
  const [partnerkundliattributes, setPartnerkundliattributes] = useState([]);
  const [attributePreview, setAttributePreview] = useState([]);
  const [attrError, setAttrError] = useState(false);
  const { t, i18n } = useTranslation();

  const handlePartnerAttributes = async () => {
    try {
      spinner.setLoadingState(true);
      await UPDATE_PROFILE("partner_kundli_attributes", partnerkundliattributes);
      spinner.setLoadingState(false);
      setSliderPage("zone");
    } catch (error) {
      setAttrError(error.response.data.message);
      spinner.setLoadingState(false);
      console.log(error, "this is the error in partner attributes updation");
    }
  };

  const updatePreview = () => {
    console.log("Starting update", partnerkundliattributes);
    const _preview = partnerkundliattributes.map((item) => {
      return (
        <div className="attribute-icons">
          <img src={PartnerAttributes[item]} />
        </div>
      );
    });
    console.log("Apna preview", _preview);
    setAttributePreview(_preview);
  };

  const handleAttributeClick = (item) => {
    const _attributesArray = [...partnerkundliattributes];

    // Check if the item is one of the exclusive attributes
    if (exclusiveAttributes.has(item.text)) {
      // Check if any exclusive attribute is already selected
      const hasExclusiveAttribute = _attributesArray.some(attr => exclusiveAttributes.has(attr));

      if (hasExclusiveAttribute) {
        alert("You can only select one attribute from Homosexual, Gender Fluid, and Straight.");
        return;
      }
    }

    if (_attributesArray.includes(item.text)) return;


    if (_attributesArray.length < 5) {
      _attributesArray.push(item.text);
      setPartnerkundliattributes(_attributesArray);
      updatePreview();
    }
  };

  const handleBack = () => {
    setSliderPage("person_kundli_attributes");
  };

  const scaleCoordinates = (coords, svgSize, imageSize) => {
    const scale = svgSize / imageSize;
    return coords
      .split(',')
      .map((value, index) => (index % 2 === 0 ? value * scale : value * scale))
      .join(',');
  };

  return (
    <div className="signup-screen-wrapper">
      <Header />

      <div className="entry-wrap">
        <div className="attribute-text-container">
          <div className="attribute-text">Attributes Of Partner Kundali</div>
        </div>
        <div className="attribute-description">
          Select 5 attributes you are looking in your partners kundali
        </div>
      </div>

      <div className="content-wrap">
        <div className="attribute-container">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340" style={{ width: '100%', height: 'auto' }}>
            <image href={kundali} alt="kundali" width="340" height="340" />
            {attributes.map((item, index) => (
              <polygon
                key={index}
                points={scaleCoordinates(item.coordinates, 340, 306)}
                fill="transparent"
                stroke="transparent"
                strokeWidth="1"
                data-value={item.text}
                onClick={() => handleAttributeClick(item)}
              />
            ))}
          </svg>

          <div style={{color:"red",fontSize:"10px",paddingLeft:"20px"}}>{attrError}</div>
        </div>

        <div className="attributeicons-position">
          {partnerkundliattributes.map((item) => (
            <div
              key={item}
              className="attribute-icons"
              onClick={() => {
                if (partnerkundliattributes.includes(item)) {
                  const updatedAttributes = partnerkundliattributes.filter((element) => element !== item);
                  setPartnerkundliattributes(updatedAttributes);
                }
              }}
            >
              <img src={PartnerAttributes[item]} />
              <div className="delete-attr-icon"><img id="cursor" src={deleteIcon} /></div>
            </div>
          ))}
        </div>
      </div>

      <div style={{ marginTop: "40px" }} className="button-wrap">
        <CustomButton onClick={handlePartnerAttributes}>{t("proceed.1")}</CustomButton>
      </div>
    </div>
  );
}

export default Partnerattributes;
