import React, { useState } from "react";
import CustomButton from "../../../components/Button/button";
import TimelineNav from "../../../components/timelinenav/timelineNav";
import photoUploadIcon from "src/assets/images/photouploadplaceholder.svg";
import uploadIcon from "src/assets/images/uploadicon.svg";
import deleteIcon from "src/assets/images/deleteicon.svg";
import { CREATE_CLUB } from "../../../axios/POST_API";
import { USER_VIEW_PROFILE } from "../../../axios/GET_API";
import { useNavigate } from "react-router-dom";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import "./createclubphoto.css";

import { sendTokensCreateClub } from "src/web3/CreateClub";
import { useAuth } from 'src/context/web3authProvider/AuthContext';
import useWeb3Auth from "src/web3/useWeb3Auth";

// import FloatingToolbar from '../../components/FloatingToolbar'

function CreateClubPhoto({clubName,description,interests}) {
  var { authProvider } = useAuth();
  const { initWallet } = useWeb3Auth();
  const spinner = useSpinner()
  const history = useNavigate()
  const [profileImage, setProfileImage] = useState()
  const [coverImage, setCoverImage] = useState()
  const [uploadError, setUploadError] = useState()
  const [clubId,setClubId] = useState()
  const allowedImageType = ["image/jpg", "image/jpeg", "image/png"]
  const [open,setOpen] = useState(false)
  const checkImageFile = (f) => {
    if (f.size > 2000000) {
      //check size of the file max 2mb
      setUploadError("Image size should be less than 2mb");
      return false;
    }

    if (allowedImageType.indexOf(f.type) < 0) {
      setUploadError("Only JPEG and PNG files are allowed");
      return false;
    }
    setUploadError("");
    return true;
  };

  const selectProfileImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    if (checkImageFile(e.target.files[0]) === true) {
      //check size of the file max 2mb
      setProfileImage(e.target.files[0]);
    }
  };

  const selectCoverImage = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      setCoverImage(selectedFile);
      setUploadError(null);
    } else {
      setCoverImage(null);
      setUploadError('Please select a valid image file.');
    }
  };
   
  const createClub = async () => {
    console.log('Cover Image', coverImage);
    if (coverImage == null) {
      setUploadError("Please upload cover image");
    } else if (profileImage == null) {
      console.log("All details are not ok");

      setUploadError("Please upload profile image");
    } else {
      try {
        spinner.setLoadingState(true);
        let profileData = await USER_VIEW_PROFILE();
        let response = await CREATE_CLUB(
          profileData.data.did,
          clubName,
          description,
          profileImage,
          coverImage,
          interests,
          "20"
        );
        console.log(response, "this is the respone of club create");
        setClubId(response?.data?.club_info?.club_id);
        spinner.setLoadingState(false);
        history(`/club/${response?.data?.club_info?.club_id}`);

        let clubId = response?.data?.club_info?.club_id;
        let joiningFee = response?.data?.club_info?.joining_fees;

        handleCreateClubWeb3(authProvider, "50", clubId);
        
      } catch (error) {
        setUploadError(error?.response?.data.message);
        spinner.setLoadingState(false);
        console.log(error);
      }
    }
  };


  const handleCreateClubWeb3 = async (authProvider, creationFee, clubId) => {
    try {

      if (!authProvider) {
        const smartWallet = await initWallet();
        authProvider = smartWallet;
      }

      await sendTokensCreateClub(authProvider, creationFee, clubId);
    } catch (error) {
      console.log(error);
      alert(error)
    }
  };

  return (
    <div className="page-page-wrapper">
      <TimelineNav />
      {open ? (
        <div style={{position:"absolute",right:"5vw"}} className="claim-subscription-popup">
          <span>
                Pay 10 DRPD to create club
          </span>
          <CustomButton style={{width:"50%"}} onClick={createClub}>Create Club</CustomButton>
        </div>
      ) : (
        ""
      )}
      <div className="inner-pages-container">

        <div className="inner-pages-container-wrap">

          <h1 className="page-title">Create a Club</h1>

          <div className="create-club-progress-wrap">
            <div className="active"></div>
            <div className="active"></div>
          </div>

          <div className="create-club-container">

            <div className="upload-club-photo-wrap">

              <div className="upload-profile-image-container">
                <label htmlFor="upload-profile-image">
                  <div className="upload-profile-image img-btn boxed" style={{ backgroundImage: profileImage ? `url(${URL.createObjectURL(profileImage)})` : "white",}} >
                    <div className="photo-icons-wrap">
                      {
                        profileImage ? "" : <div style={{position:"relative",top:"15px"}} className="upload-img-icon"><img src={uploadIcon} /></div>
                      }
                    </div>

                    <input
                      style={{ display: "none" }}
                      id="upload-profile-image"
                      name="upload-profile-image"
                      type="file"
                      onChange={selectProfileImage}
                    />

                  </div>
                </label>

                <span id="club-pro-upload-lbl">Upload Profile Image</span>
              </div>

              <div className="upload-cover-image-container"> 
                <label htmlFor="upload-cover-image">
                  <div>
                    <div className="upload-cover-image img-btn boxed" style={{ backgroundImage: coverImage ? `url(${URL.createObjectURL(coverImage)})` : "white" }} >
                      <div className="photo-icons-wrap">
                        {coverImage ? null : <div className="placeholder-icon"><img src={photoUploadIcon} alt="Upload" /></div>}
                        {coverImage ? <div className="delete-img-icon icon-btm-right"><img src={deleteIcon} alt="Delete" /></div> : <div id="club-cover-upload-lbl"><img src={uploadIcon} alt="Upload" /><span>Upload cover image</span></div>}
                      </div>
                    </div>

                    <input
                      style={{ display: "none" }}
                      id="upload-cover-image"
                      name="upload-cover-image"
                      type="file"
                      onChange={selectCoverImage}
                    />

                    {uploadError && <p>{uploadError}</p>}
                  </div>
                </label>
              </div>
              
              <div className='error'>{uploadError}</div>
            </div>

          </div>

          <div className="button-wrap">
            {/*<CustomButton onClick={()=>{setOpen(true)}}>Create Club</CustomButton>*/}

            <CustomButton onClick={createClub}>Create Club</CustomButton>
          </div>
        </div>

      </div>
    </div> 
  );
}

export default CreateClubPhoto;
